import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../../../Components/shared/SmartTable'
import { validateFormInput } from '../../../utils'
import Analytics from '../../../utils/analytics'
import { getInstitutions } from '../../../reducers/InstitutionsReducer'
import FilesManager from '../../../Components/shared/FilesManager'
import { bindActionCreators } from 'redux'
import { fetchSectorsByInstitution, getSectors } from '../../../reducers/SectorsReducer/actions'
import { getInstitutionOptions } from '../../../reducers/InstitutionOptionsReducer/actions'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser),
    getInstitutions: getInstitutions(state),
    getInstitutionOptions: getInstitutionOptions(state.institutionOptions),
    sectors: getSectors(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSectorsByInstitution: bindActionCreators(fetchSectorsByInstitution, dispatch)
  }
}

const SectorsView = props => {
  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Name'),
      key: 'name',
      validate: data => validateFormInput('freeText', data, true)
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Comment for the students'),
      key: 'comment'
    },
    { type: DATA_TYPE_STRING, name: props.t('common.road'), key: 'emplacement' }
  ]

  const additionalActions = [
    {
      iconName: 'file',
      type: 'primary',
      title: props.t('Manage files'),
      onClick: s => setSelectedSectorFiles(s)
    }
  ]

  const [sectors, setSectors] = useState([])
  const [selectedSectorFiles, setSelectedSectorFiles] = useState(null)

  useEffect(() => {
    props.fetchSectorsByInstitution(props.getUser.institutions[0].id, props.getUser)
  }, [])

  useEffect(() => {
    Analytics.pageView('/institution-sectors')

    setSectors(props.sectors)
  }, [props.sectors])

  return (
    <>
      <SmartTable
        columns={columns}
        data={sectors}
        additionalActions={additionalActions}
      />

      <FilesManager
        entity={selectedSectorFiles}
        entityName='Sector'
        onClose={() => setSelectedSectorFiles(null)}
        userContextFiltered
        preventChanges
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorsView)
