import { faCog } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const Loading = ({ children, loading, size, style, t }) => {
  return (
    <>
      {loading && (
        <div className='loading-container' style={style ?? {}}>
          <FontAwesomeIcon icon={faCog} spin size={size ?? '1x'} />
          <b>
            {t('Loading ...')}
          </b>
        </div>
      )}
      {!loading && (
        <>
          {children}
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps)(Loading)
