import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Modal } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { onError, onSuccess, resolvePromises } from '../../utils/apiHelper'
import { getUser } from '../../reducers/UserReducer'
import { isFunction } from 'lodash'
import { addSectorActTypes, addSectorStudentPathways, createSector, getSector, removeSectorActTypes, removeSectorStudentPathways, updateSector } from '../../utils/api/sector'
import SectorForm from '../../Components/shared/Forms/SectorForm'
import { Sector } from '../../utils/entities/sector'
import SectorIdCard from '../../Components/shared/IdCards/SectorIdCard'
import { getItemModifications } from '../../utils'
import { getLuminanceFromColorCode } from '../../utils/colors'

import '../../assets/sectorsModal.scss'

export const SectorModalContext = createContext()

export const DISPLAY_MODE = 'display'
export const EDITION_MODE = 'edit'
export const EDITION_ONLY_MODE = 'edit_only'
export const DEFAULT_STATE = { data: null, callback: null, mode: EDITION_MODE }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SectorModal = ({ actTypes = [], children, readOnly, user, users = [], t }) => {
  const defaultState = useMemo(() => ({ ...DEFAULT_STATE, mode: DISPLAY_MODE }), [readOnly])

  const [selectedSector, setSelectedSector] = useState(defaultState)
  const [sector, setSector] = useState(null)

  const editOnly = useMemo(() => selectedSector.mode === EDITION_ONLY_MODE, [selectedSector.mode])
  const luminance = sector?.hospitalDepartment?.color ? getLuminanceFromColorCode(sector.hospitalDepartment.color) : 'black'
  const sectorStyle = useMemo(() => sector?.hospitalDepartment?.color ? {
    '--main-color': sector.hospitalDepartment.color,
    '--text-color': luminance
  } : {}, [sector])

  const fetchSectorData = useCallback(sector => {
    getSector(user, sector).then(json => {
      if (json?.data) {
        setSector(json.data)
      }
    })
  }, [user])

  useEffect(() => {
    const sectorId = selectedSector.data?.id ?? -1

    if (sectorId > 0) {
      fetchSectorData({ id: sectorId })
    } else if (selectedSector.data) {
      setSector(new Sector(selectedSector.data))
    } else {
      setSector(null)
    }
  }, [selectedSector.data, fetchSectorData, setSector])

  const handleSubmit = useCallback((updatedSector, files) => {
    const promise = sector.id < 0 ? { call: createSector, action: 'create' } : { call: updateSector, action: 'update' }
    updatedSector = new Sector(updatedSector)

    promise.call(user, updatedSector).then(json => {
      if (json?.data) {
        onSuccess(t(`sector.${promise.action}.success`))
        const newSector = updatedSector.toRequestBody()

        const pathwaysDifferences = getItemModifications(newSector.studentPathways, sector.studentPathways)
        const actTypesDifferences = getItemModifications(newSector.actTypes, sector.actTypes)

        const promiseStack = []

        if (pathwaysDifferences.added.length > 0) {
          promiseStack.push(addSectorStudentPathways(user, json.data, pathwaysDifferences.added))
        }

        if (pathwaysDifferences.deleted.length > 0) {
          promiseStack.push(removeSectorStudentPathways(user, json.data, pathwaysDifferences.deleted))
        }

        if (actTypesDifferences.added.length > 0) {
          promiseStack.push(addSectorActTypes(user, json.data, actTypesDifferences.added.map(a => a.id)))
        }

        if (actTypesDifferences.deleted.length > 0) {
          promiseStack.push(removeSectorActTypes(user, json.data, actTypesDifferences.deleted))
        }

        if (isFunction(selectedSector.callback)) {
          selectedSector.callback(json.data)
        }

        if (selectedSector.mode !== EDITION_ONLY_MODE) {
          setSelectedSector({ ...selectedSector, data: { ...selectedSector.data, ...json.data, institution: selectedSector.data.institution }, mode: DISPLAY_MODE })
          resolvePromises(promiseStack).then(() => fetchSectorData(json.data))
        } else {
          setSelectedSector({ ...defaultState })
        }
      }
    }).catch(e => {
      onError(t('sector.' + promise.action + '.error'))
      setSelectedSector({ ...defaultState, mode: selectedSector.mode })
    })
  }, [sector, defaultState, selectedSector, fetchSectorData, setSelectedSector, user, t])

  const handleCancel = useCallback(() => setSelectedSector({ ...selectedSector, mode: DISPLAY_MODE }), [selectedSector])

  return (
    <>
      <SectorModalContext.Provider value={{ setSelectedSector }}>
        {children}
      </SectorModalContext.Provider>
      <Modal
        wrapClassName='modal'
        title={
          <div>
            <div>{sector?.name ? sector.name : t('sector_modal.title')}</div>
          </div>
        }
        visible={!!sector}
        width={1000}
        onCancel={() => setSelectedSector({ ...defaultState, mode: selectedSector.mode })}
        footer={null}
        destroyOnClose
        style={sectorStyle}
      >
        <div style={{ position: 'relative' }}>
          {sector?.hospitalDepartment && (<div className='subtitle'> {sector.hospitalDepartment.name} </div>)}

          {(selectedSector.mode === EDITION_MODE || selectedSector.mode === EDITION_ONLY_MODE) && sector && (
            <SectorForm actTypes={actTypes} cancel={!editOnly} item={sector} users={users} onCancel={handleCancel} onSubmit={handleSubmit} />
          )}
          {selectedSector.mode === DISPLAY_MODE && sector && (
            <div className='flex-col w-100'>
              <SectorIdCard sector={sector} />
              {!readOnly && <Button onClick={() => setSelectedSector({ ...selectedSector, mode: EDITION_MODE })}> {t('Edit')} </Button>}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(SectorModal)
