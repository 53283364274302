import React, { useEffect } from 'react'
import AppBanner from '../AppBanner'
import appConfig from '../../../config'
import moment from 'moment'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { getEnvironment } from '../../../utils'
import LanguagesMenu from '../LanguagesMenu'
import { faGlobeEurope } from '@fortawesome/pro-solid-svg-icons'

import './style.scss'

const appBanner = <AppBanner />

const AnonymousFormWrapper = props => {
  const env = getEnvironment()
  let envText = 'unknown'
  if (env === 'TEST') envText = 'test'
  if (env === 'PROD') envText = 'production'
  if (env === 'DEV') envText = 'dev'

  const formStyle = {
    width: props.isWide ? '70%' : '550px',
    maxWidth: props.isWide ? '800px' : '90vw',
    height: props.noBgImg ? 'auto' : '551px'
  }

  useEffect(() => {
    const zendeskWidget = document.getElementById('launcher')
    if (zendeskWidget) {
      zendeskWidget.classList.add('on-login-page')
    }
    return () => {
      if (zendeskWidget) {
        zendeskWidget.removeAttribute('class')
      }
    }
  }, [])

  return (
    <div className='flex-column anonymous-form-wrapper'>
      <div className='form-bg' />

      <div className='flex-row language-selector-row'>
        <LanguagesMenu icon={faGlobeEurope} title={props.t('Change language')} />
      </div>
      {
        appBanner === null ? null : <div style={{ marginTop: '30px' }}>{appBanner}</div>
      }
      <div className='flex-fill' />

      <div className='form-grid'>
        <div style={formStyle} className={props.noBgImg ? 'flex-column form-wrapper border-radius' : 'flex-column form-wrapper border-radius-left'}>
          <div className='logo-wrapper'>
            <div className='title-wrapper'>
              <img src='/assets/interneo_logo.svg' alt='interneo-logo' />
              <div className='version'>V{appConfig.version}</div>
            </div>
          </div>
          <div style={{ marginTop: '15px' }} />
          {props.children}
        </div>

        {!props.noBgImg &&
          <div className='right-img border-radius-right'>
            <img alt='' src='/assets/login-bg.png' />
          </div>}
      </div>

      <div className='flex-fill' />
      <div className='bottom-information'>
        <div className='flex-column changelog-link'>
          <a href='http://opalsolutions.be' target='_blank' rel='noopener noreferrer'>
              Opal Solutions {moment().format('YYYY')}
          </a>
          <a href='#'>
              Version : interneo_{envText}_{appConfig.version}
          </a>
        </div>
        <a className='opal-logo' href='https://opalsolutions.be/interneo-internship-management/' target='blank'>
          <img src='/assets/opal-logo.png' nopin='nopin' />
        </a>
      </div>

    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousFormWrapper)
