import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import { COLORS_PALETTE } from '../../../../config/colors'

const QuestionBubble = ({ title, color = COLORS_PALETTE.FIRST }) => {
  return (
    <Tooltip title={title}>
      <FontAwesomeIcon icon={faQuestionCircle} color={color} />
    </Tooltip>
  )
}

export default QuestionBubble
