import React, { useEffect, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { BOOLEAN_FIELD, STRING_FIELD } from '../institution/InstitutionOptionTable'
import { Switch, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SchoolOptionTable = ({ columns, density, schoolOptions, user, switchSchoolOption, t }) => {
  const [localSchoolOptions, setLocalSchoolOptions] = useState([])
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (schoolOptions?.length > 0) {
      setLoading(true)

      const localSchoolOptions = []

      schoolOptions.forEach(so => {
        if (!so.optionType.isHidden) {
          localSchoolOptions.push({ ...so })
        }
      })

      setIsDataAvailable(true)
      setLocalSchoolOptions(localSchoolOptions)
      setLoading(false)
    }
  }, [schoolOptions, user])

  const renderName = (schoolOption, index) => (
    <td key={`col_name_${index}`} className='name_institution_option'>
      <Tooltip placement='top' title={t('school.option.' + schoolOption.optionType.type)}>
        {t('school.option.' + schoolOption.optionType.type)}

        {schoolOption.optionType.isPremium && (
          <Tooltip placement='top' title={t('Premium feature')}>
            <FontAwesomeIcon icon={faStar} id='star-icon' />
          </Tooltip>
        )}
      </Tooltip>
    </td>
  )

  const renderSwitch = (schoolOption, index) => (
    <td key={`col_switch_${index}`} className='switch-institution-option'>
      <Tooltip placement='top' title={schoolOption.optionEnabled ? t('Enable') : t('Disable')}>
        <Switch
          defaultChecked={schoolOption.optionEnabled}
          size='small'
          onChange={
            e => switchSchoolOption(
              schoolOption.school,
              {
                optionType: schoolOption.optionType.type,
                optionEnabled: !schoolOption.optionEnabled
              },
              e)
          }
          disabled={loading || !schoolOption.optionType.isEditable || (schoolOption.optionType.isPremium && !user.school.managed)}
        />
      </Tooltip>
    </td>
  )

  const render = () => {
    if (loading) {
      return <div className='center'><div className='loading-icon black' /></div>
    }

    if (isDataAvailable && localSchoolOptions.length > 0) {
      return (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={col.name} style={{ width: `${index === 0 ? '80%' : '20%'}` }}>
                  {t(col.name)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {localSchoolOptions.map((schoolOption, index) => (
              <tr key={'row_' + index} style={{ height: density ?? '32px' }}>
                {columns.map((column, index) => {
                  if (column.type === STRING_FIELD) return renderName(schoolOption, index)
                  if (column.type === BOOLEAN_FIELD) return renderSwitch(schoolOption, index)
                  return 'UNKNOWN'
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )
    }

    return <>{t('No data are actually available for this page.')}</>
  }

  return <div className='interactive-table'>{render()}</div>
}

export default connect(mapStateToProps)(SchoolOptionTable)
