import React from 'react'

import TooltipIconButton from '../../institution/Quotas/shared/TooltipIconButton'
import {
  faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight
} from '@fortawesome/pro-solid-svg-icons'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'

const MISSING_ON_PAGE_CHANGE_ERROR_TEXT = 'PaginationHeader needs an onPageChange property'

const PaginationHeader = props => {
  const { maxPageIndex, pageIndex, dataType, dataCount } = props

  const handlePageIndexChange = newPageIndex => {
    if (typeof props.onPageChange === 'function') {
      props.onPageChange(newPageIndex)
    } else {
      console.error(MISSING_ON_PAGE_CHANGE_ERROR_TEXT)
    }
  }

  const renderPaginationText = () => {
    return (
      <div className='page-index'>
        {`${getDataText()} - ${getPageText()}`}
      </div>
    )
  }

  const getDataText = () => {
    if (dataCount > 1) {
      return `${dataCount} ${props.t(dataType + 's')}`
    }

    return `${dataCount} ${props.t(dataType)}`
  }

  const getPageText = () => {
    return `${props.t('Page')} ${pageIndex} / ${maxPageIndex}`
  }

  return (
    <div className='flex-row'>
      <TooltipIconButton
        title='First page'
        size='small'
        icon={faAngleDoubleLeft}
        onClick={() => handlePageIndexChange(1)}
        disabled={pageIndex <= 1}
      />
      <div className='h-spacing' />
      <TooltipIconButton
        title='Previous page'
        size='small'
        icon={faAngleLeft}
        onClick={() => handlePageIndexChange(pageIndex - 1)}
        disabled={pageIndex <= 1}
      />
      <div className='h-spacing' />
      {renderPaginationText()}
      <div className='h-spacing' />
      <TooltipIconButton
        title='Next page'
        size='small'
        icon={faAngleRight}
        onClick={() => handlePageIndexChange(pageIndex + 1)}
        disabled={pageIndex >= maxPageIndex}
      />
      <div className='h-spacing' />
      <TooltipIconButton
        title='Last page'
        size='small'
        icon={faAngleDoubleRight}
        onClick={() => handlePageIndexChange(maxPageIndex)}
        disabled={pageIndex >= maxPageIndex}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationHeader)
