import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import InstitutionEvaluationSummaryView from './InstitutionEvaluationSummaryView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Analytics from '../../../utils/analytics'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import InstitutionScoreSummaryView from './InstitutionScoreSummaryView'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { faHouseMedical } from '@fortawesome/pro-duotone-svg-icons'

const { TabPane } = Tabs

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InstitutionEvaluationView = ({ t }) => {
  useEffect(() => Analytics.pageView('/students-evaluation'), [])

  return (
    <Tabs defaultActiveKey='1' animated={false}>
      <TabPane
        key='1'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon={faUser} style={{ marginTop: '3px' }} />
            <div className='h-spacing' />
            {t('Student feedback')}
          </div>
        }
      >
        <InstitutionEvaluationSummaryView />
      </TabPane>
      <TabPane
        key='2'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon
              icon={faHouseMedical}
              style={{ marginTop: '3px' }}
            />
            <div className='h-spacing' />
            {t('Institution score')}
          </div>
        }
      >
        <InstitutionScoreSummaryView />
      </TabPane>
    </Tabs>
  )
}

export default connect(
  mapStateToProps
)(InstitutionEvaluationView)
