import { GLOBAL_PLACES_KEY, FULL_PLACES_STATE, PARTIAL_PLACES_STATE } from './Constants'
import { computeQuotaPartialOverlappingWeight, isOverlapping, isPartialPlaces, setEndDate } from './DateTools'
import { store } from '../../../..'
import { SHORT_EUROPEAN_DATE } from '../../../../utils/constants'
import moment from 'moment'
import { getTranslate } from 'react-localize-redux'
import { computeTotalWithWeight } from './ComputationTools'
import { getStartDateByType } from '../../../../utils/timePeriods'

const YEAR_DATE = 'MMMM'
const MONTH_DATE = SHORT_EUROPEAN_DATE
const WEEK_DATE_AS_STRING = 'dddd'
const WEEK_DATE = 'DD'
const END_DAY_OFFSET = { set: { hour: 23, minute: 59, second: 59 } }
const END_WEEK_OFFSET = { add: { days: 6 }, set: { hour: 23, minute: 59, second: 59 } }

const buildAntdTreeFromArray = (array, key, makeTitle) => {
  return array.map((element, index) => {
    return {
      title: makeTitle ? makeTitle(element) : element[key],
      key: element[key],
      index: index,
      selectable: false,
      checkable: true
    }
  })
}

const buildAntdTreeFromMap = (objectsMap, key, makeTitle) => {
  return Object.keys(objectsMap).map((objectId, index) => {
    const object = objectsMap[objectId]

    return {
      title: makeTitle ? makeTitle(object) : object[key],
      key: object[key],
      index: index,
      selectable: false,
      checkable: true
    }
  })
}

const buildStandardColumnDate = (date, type, translator) => {
  const dateBuilder = {
    month: () => {
      return {
        title: date.format(YEAR_DATE),
        startDate: date.startOf('month'),
        endDate: date.endOf('month')
      }
    },
    week: () => {
      return {
        title: date.format(MONTH_DATE),
        startDate: date.clone(),
        endDate: setEndDate(date, END_WEEK_OFFSET).clone()
      }
    },
    day: () => {
      return {
        title: translator(date.format(WEEK_DATE_AS_STRING)) + ' ' + date.format(WEEK_DATE),
        startDate: date.clone(),
        endDate: setEndDate(date, END_DAY_OFFSET).clone()
      }
    }
  }

  const returnValue = dateBuilder[type]?.()

  date.add(1, 'seconds')

  return returnValue
}

const buildStandardPeriodDates = (date, period, translator) => {
  const datesBuilder = {
    week: () => {
      return {
        title: date.format(MONTH_DATE),
        startDate: moment(date),
        endDate: moment(setEndDate(date, END_WEEK_OFFSET))
      }
    },
    day: () => {
      return {
        title: typeof translator === 'function'
          ? translator(date.format(WEEK_DATE_AS_STRING)) + ' ' + date.format(WEEK_DATE)
          : date.format(WEEK_DATE_AS_STRING) + ' ' + date.format(WEEK_DATE),
        startDate: moment(date),
        endDate: moment(setEndDate(date, END_DAY_OFFSET))
      }
    }
  }

  const returnValue = datesBuilder[period]?.()
  date.add(1, 'seconds')

  return returnValue
}

const generateColumns = (currentDate, timePeriod, firstColumnTitle = 'Care unit') => {
  const translator = getTranslate(store.getState().locale)
  const { quantity, type } = timePeriod
  const newColumns = [generateColumn(firstColumnTitle)]
  const firstDayOfWeek = getStartDateByType(currentDate, type)

  for (let i = 0; i < quantity; i++) {
    newColumns.push(buildStandardColumnDate(firstDayOfWeek, type, translator))
  }

  return newColumns
}

const generateColumn = (title, startDate = null, endDate = null) => {
  return { title, startDate, endDate }
}

const buildTableData = (quotas, columns) => {
  const data = []

  columns.forEach(column => {
    data.push(buildColumnData(quotas, column))
  })

  return data
}

const buildColumnData = (quotas, column) => {
  let columnData = {}

  Object.keys(quotas).forEach(key => {
    const quota = quotas[key]
    const quotaStartDate = moment(quota.startDate)
    const quotaEndDate = moment(quota.endDate)

    if (isOverlapping(quotaStartDate, quotaEndDate, column.startDate, column.endDate)) {
      const dates = {
        startDate: quotaStartDate,
        endDate: quotaEndDate,
        periodStartDate: column.startDate,
        periodEndDate: column.endDate
      }
      columnData = insertQuotaIntoTreeIfNeeded(columnData, quota, dates)
    }
  })

  return columnData
}

const insertQuotaIntoTreeIfNeeded = (treeData, quota, dates) => {
  if (quota.school) {
    return treeData
  }

  treeData = initialiseTreeKeysIfNeeded(treeData, quota)
  const sectorKey = getTreeKeyByQuotaAndAccessor(quota, 'sector')
  const sectionKey = getTreeKeyByQuotaAndAccessor(quota, 'section')

  treeData[sectorKey].quotas.push(quota)

  if (sectorKey === GLOBAL_PLACES_KEY) {
    return treeData
  }

  treeData = insertQuotaPlacesIntoTree(treeData, sectorKey, sectionKey, quota, dates)

  return treeData
}

const initialiseTreeKeysIfNeeded = (treeData, quota) => {
  const sectorKey = getTreeKeyByQuotaAndAccessor(quota, 'sector')

  if (!treeData[sectorKey]) {
    treeData[sectorKey] = getInitialNode()
  }

  return treeData
}

const getTreeKeyByQuotaAndAccessor = (quota, accessor) => {
  return quota[accessor]?.id ?? GLOBAL_PLACES_KEY
}

const getInitialNode = () => {
  return {
    total: 0,
    details: {},
    quotas: [],
    state: FULL_PLACES_STATE
  }
}

const insertQuotaPlacesIntoTree = (treeData, sectorKey, sectionKey, quota, dates) => {
  const { total } = quota

  if (sectionKey === GLOBAL_PLACES_KEY) {
    return insertGlobalPlacesIntoTree(treeData, sectorKey, quota, dates)
  }

  if (!treeData[sectorKey].details[sectionKey]) {
    treeData[sectorKey].details[sectionKey] = 0
  }

  treeData[sectorKey].details[sectionKey] += total

  return treeData
}

const insertGlobalPlacesIntoTree = (treeData, sectorKey, quota, dates) => {
  const { startDate, endDate, periodStartDate, periodEndDate } = dates
  let total = quota.total

  if (isPartialPlaces(startDate, endDate, periodStartDate, periodEndDate)) {
    total = computeTotalWithWeight(total, computeQuotaPartialOverlappingWeight(startDate, endDate, periodStartDate, periodEndDate))
    treeData[sectorKey].state = PARTIAL_PLACES_STATE
  }

  treeData[sectorKey].total += total

  return treeData
}

const buildModalColumns = period => {
  const columnByPeriod = {
    month: {
      title: 'Week',
      key: 'label',
      dataIndex: 'label',
      period: 'week',
      rowNumbers: 4
    },
    week: {
      title: 'Day',
      key: 'label',
      dataIndex: 'label',
      period: 'day',
      rowNumbers: 7
    }
  }
  const placesColumn = {
    title: 'Places',
    key: 'total',
    dataIndex: 'total'
  }

  return [columnByPeriod[period], placesColumn]
}

export {
  buildAntdTreeFromArray,
  buildAntdTreeFromMap,
  buildStandardColumnDate,
  buildStandardPeriodDates,
  generateColumns,
  buildTableData,
  buildModalColumns
}
