import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'

const DEFAULT_BUTTON_STYLE = {}

const TrashButton = ({ disabled, title, onClick }) => {
  return (
    <Tooltip placement='top' title={title}>
      <Button type='danger' size='small' style={DEFAULT_BUTTON_STYLE} disabled={disabled} onClick={onClick}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </Button>
    </Tooltip>
  )
}

export default TrashButton
