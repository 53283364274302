import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const AddButtonFactory = ({ addText, addFunction, disabled, t }) => {
  return (
    <Button type='primary' onClick={addFunction} disabled={disabled}>
      <FontAwesomeIcon icon='plus' />
      &nbsp;
      {t(addText ?? 'common.add')}
    </Button>
  )
}

export default connect(mapStateToProps)(AddButtonFactory)
