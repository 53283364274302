import React from 'react'

import { INFO_BUTTON_COLOR } from './Tooltips/EventCodesTooltip'
import TablePagination from './TablePagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'

const FOOTER_LEGEND_NAME = 'The schedules can only be managed on accepted internship.'
const CLASSES_BY_MODE = {
  WIDGET: 'flex-row shift-manager-footer-in-widget',
  INTERNEO: 'flex-row shift-manager-footer'
}

const TableFooter = props => {
  return (
    <div className={CLASSES_BY_MODE[props.mode]}>
      {(!props.inWidgetMode && !props.loading) && (
        <div>
          <FontAwesomeIcon icon={faInfoCircle} color={INFO_BUTTON_COLOR} />
          &nbsp;
          {props.t(FOOTER_LEGEND_NAME)}
        </div>
      )}
      <div className='flex-fill' />
      <TablePagination
        entityName={props.entityName}
        entitiesTotal={props.entitiesTotal}
        entitiesPerPage={props.entitiesPerPage}
        pageIndex={props.pageIndex}
        maxPageIndex={props.maxPageIndex}
        onPageIndexChange={props.onPageIndexChange}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFooter)
