import React, { useEffect, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { Button, Modal, Table, Tag } from 'antd'
import { addInternshipScheduleComplements, deleteScheduleComplement, getInternshipInternshipYearActTypes, getScheduleComplements, updateInternshipScheduleComplement } from '../../utils/api/internship'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import ScheduleComplementForm from './Forms/ScheduleComplementForm'

import '../../assets/schedule-bonus-table.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ScheduleBonusTable = ({ internship, t, user }) => {
  const [scheduleComplements, setScheduleComplements] = useState([])
  const [displayCreationModal, setDisplayCreationModal] = useState(false)
  const [scheduleToUpdate, setScheduleToUpdate] = useState(null)
  const [actTypes, setActTypes] = useState([])

  useEffect(() => {
    fetchScheduleComplements(internship, user)
    getInternshipInternshipYearActTypes(internship, user).then(json => {
      if (json?.data) {
        setActTypes(json.data)
      }
    })
  }, [internship, user])

  const onCreate = scheduleComplement => {
    setDisplayCreationModal(false)
    addInternshipScheduleComplements(internship, scheduleComplement, user).then(() => {
      fetchScheduleComplements(internship, user)
    })
  }

  const onUpdate = scheduleComplement => {
    setScheduleToUpdate(null)
    updateInternshipScheduleComplement(internship, scheduleComplement, user).then(() => {
      fetchScheduleComplements(internship, user)
    })
  }

  const handleDelete = scheduleComplement => {
    return deleteScheduleComplement(internship, scheduleComplement, user).then(() => {
      fetchScheduleComplements(internship, user)
    })
  }

  const fetchScheduleComplements = (internship, user) => {
    getScheduleComplements(internship, user).then(json => {
      if (json && json.data) {
        setScheduleComplements(json.data.sort((a, b) => {
          if (a.actType === null) {
            return -1
          }

          if (b.actType === null) {
            return 1
          }

          if (a.actType === b.actType) {
            return 0
          }

          return a.actType.name.localeCompare(b.actType.name)
        }))
      }
    })
  }

  const columns = [
    {
      title: t('Act type'),
      key: 'actType',
      render: d => d.actType?.name ?? '/'
    },
    {
      title: t('Value'),
      key: 'value',
      render: d => {
        if (d.value !== null && d.value.includes(':')) {
          const valueParts = d.value.split(':')
          return valueParts[0] + ':' + valueParts[1]
        }

        return d.value
      }
    },
    {
      title: t('Positive'),
      key: 'positive',
      render: d => <Tag color={d.positive ? 'green' : 'red'}>{d.positive ? t('Added') : t('Removed')}</Tag>
    },
    {
      title: t('Comment'),
      dataIndex: 'commentary',
      key: 'commentary'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: d => {
        return (
          <div>
            <Button
              type='primary'
              style={{ marginRight: 10 }}
              onClick={() => setScheduleToUpdate(d)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              type='danger'
              onClick={() => handleDelete(d)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <div className='schedule-bonus-table'>
      <Button type='primary' style={{ position: 'absolute', right: 50, top: 12 }} onClick={() => setDisplayCreationModal(true)}>
        <FontAwesomeIcon icon={faPlus} color='white' />
        <span> {t('Add schedule complement')} </span>
      </Button>
      <Table
        columns={columns}
        dataSource={scheduleComplements}
        rowKey='id'
        size='medium'
      />
      <Modal
        title={t('Create schedule complement')}
        visible={displayCreationModal}
        onCancel={() => setDisplayCreationModal(false)}
        footer={null}
        destroyOnClose
      >
        <ScheduleComplementForm
          actTypes={actTypes}
          internship={internship}
          onSubmit={onCreate}
        />
      </Modal>
      <Modal
        title={t('Modify schedule complement')}
        visible={!!scheduleToUpdate}
        onCancel={() => setScheduleToUpdate(null)}
        footer={null}
        destroyOnClose
      >
        <ScheduleComplementForm
          data={scheduleToUpdate}
          actTypes={actTypes}
          internship={internship}
          onSubmit={onUpdate}
        />
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps)(ScheduleBonusTable)
