import UserReducer from '../../reducers/UserReducer'
import { DELETE, GET, PATCH, POST, makeQueryString, makeQueryStringFromObject } from '../apiHelper'
import { EXPORT_TYPE, LANGUAGE_TAG_EN } from '../constants'
import { requestWithPromise } from '../index'

export const getAllSectors = (user, paramaters = {}) => {
  return requestWithPromise(`/api/sectors${makeQueryString(paramaters)}`, GET, null, user)
}

export const getSectorDocuments = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/documents`,
    GET,
    null,
    user
  )
}

export const getPlaces = (sector, user, parameters = {}) => {
  return requestWithPromise(
    `/quota/sectors/${sector.id}${makeQueryStringFromObject(parameters)}`,
    GET,
    null,
    user
  )
}

export const fetchSectorScore = (user, sector, endDate, startDate, additionalData) => {
  return requestWithPromise(
    `/sector/${sector.id}/evaluation-internships/score?end-date=${endDate}&start-date=${startDate}&additional-data=${additionalData}`,
    GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getSectorPresets = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/shift-presets`,
    GET,
    null,
    user
  )
}

export const getInternshipsBySector = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/internships`,
    GET,
    null,
    user
  )
}

export const createSector = (user, sector) => {
  return requestWithPromise(
    '/api/sectors',
    POST,
    sector.toRequestBody(),
    user
  )
}

/** @deprecated 2024-06-06 */
export const createSectorOld = (user, sector) => {
  return requestWithPromise(
    '/api/Sector',
    POST,
    sector,
    user
  )
}

export const updateSector = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}`,
    PATCH,
    sector.toRequestBody(),
    user
  )
}

/** @deprecated 2024-06-06 */
export const updateSectorOld = (user, sector, body) => {
  return requestWithPromise(
    `/api/Sector/${sector.id}`,
    PATCH,
    body,
    user
  )
}

export const archiveSector = (user, sector, archive = true) => {
  return requestWithPromise(
    `/sector/archive/${sector.id}`,
    PATCH,
    { toArchive: archive },
    user
  )
}

export const deleteSector = (user, sector) => {
  return requestWithPromise(
    `/api/sector/${sector.id}`,
    DELETE,
    null,
    UserReducer
  )
}

export const switchSectorInstitution = (user, sector, institution) => {
  return requestWithPromise(
    `/sectors/${sector.id}/transfer`,
    PATCH,
    { targetInstitution: institution.id },
    user
  )
}

export const assignManagedBy = (user, sector, idsManagedBy) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/users`,
    PATCH,
    { users: idsManagedBy },
    user
  )
}

export const addSectorStudentPathways = (user, sector, pathways = []) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/student-pathways`,
    POST,
    pathways,
    user
  )
}

export const getSectorStudentPathways = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/student-pathways`,
    GET,
    null,
    user
  )
}

export const removeSectorStudentPathways = (user, sector, pathways) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/student-pathways${makeQueryString({ ids: pathways })}`,
    DELETE,
    null,
    user
  )
}

export const addSectorActTypes = (user, sector, actTypes = []) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/act-types`,
    POST,
    { ids: actTypes },
    user
  )
}

export const getSectorActTypes = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/act-types`,
    GET,
    null,
    user
  )
}

export const removeSectorActTypes = (user, sector, actTypes) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/act-types${makeQueryString({ ids: actTypes })}`,
    DELETE,
    null,
    user
  )
}

export const exportSectors = (user, parameters = { language: LANGUAGE_TAG_EN, type: EXPORT_TYPE.CSV }) => {
  return requestWithPromise(
    '/export/sectors',
    POST,
    parameters,
    user
  )
}

export const getSector = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}`,
    GET,
    null,
    user
  )
}
