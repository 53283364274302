import React, { useContext, useEffect, useState } from 'react'

import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import moment from 'moment'
import { Row, Col, InputNumber, Button } from 'antd'
import { getMaxPlacesByPeriod } from '../utils/DataDerivers'
import { getStartDateByType, getEndDateByType } from '../../../../utils/timePeriods'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import StandardSelect, { STRING, WILD_CART_VALUE } from '../../../antd/Selects/StandardSelect'
import { isObject } from '../../../../utils'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { createQuota } from '../../../../utils/api/quota'
import { getUser } from '../../../../reducers/UserReducer'
import { Quota } from '../../../../utils/entities/quota'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { onSuccess } from '../../../../utils/apiHelper'

import '../assets/quotas.scss'

export const BODY_STYLE = { display: 'flex', flexDirection: 'column', height: '93%', width: '100%' }
export const SELECT_STYLE = { width: '200px' }

const PLACES_TITLE = 'Allocated places'
const SECTION_TITLE = 'common.section'
const SECTION_KEY = 'abbreviation'
const SCHOOLS_TITLE = 'common.school'
const SCHOOL_KEY = 'name'
const SCHOOL_YEARS_TITLE = 'School year'
const SCHOOL_YEAR_KEY = 'schoolYearName'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const AllocationForm = ({ cell, date, period, places, schools, sector, visible, afterSubmit, onCancel, t, user }) => {
  const { sections } = useContext(GlobalContext)
  const { getSchoolYears } = useContext(QuotasContext)

  const [schoolYears, setSchoolYears] = useState([])
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [section, setSection] = useState(null)
  const [school, setSchool] = useState(WILD_CART_VALUE)
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(WILD_CART_VALUE)
  const [requirePlaces, setRequirePlaces] = useState(0)
  const [placesPool, setPlacesPool] = useState(0)

  useEffect(() => {
    if (isObject(section)) {
      getSchoolYears(section).then(setSchoolYears)
    } else {
      setSchoolYears([])
    }

    setSelectedSchoolYear(WILD_CART_VALUE)
  }, [section])

  useEffect(() => {
    if ((cell || date) && period) {
      setStartDate(getStartDateByType(cell ? cell.date : date, period.type))
      setEndDate(getEndDateByType(cell ? cell.date : date, period.type))

      if (cell && cell.section) {
        setSection(cell.section)
      }
    }
  }, [cell, date, period])

  useEffect(() => {
    if (places && places.length > 0) {
      setPlacesPool(getMaxPlacesByPeriod(places, startDate, endDate, period, section))
    }
  }, [places, startDate, endDate, period, section])

  const handleSubmit = () => {
    const quota = new Quota(
      school,
      sector,
      section,
      selectedSchoolYear,
      requirePlaces,
      startDate,
      endDate
    )

    createQuota(quota, user).then(json => {
      if (json && json.data) {
        afterSubmit(json.data)
        onSuccess(t('The quota has been successfully created'))
      } else {
        onSuccess(t('An error was encountered when creating the quota'))
      }
    })
  }

  const handleDatesChange = (dates, dateStrings) => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  return (
    <div className='allocate-quota-form'>
      <QuickDualDatePickers
        startDate={startDate}
        endDate={endDate}
        period={period}
        onDatesChange={handleDatesChange}
      />
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {t(PLACES_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <InputNumber value={requirePlaces} onChange={setRequirePlaces} />
          <b>{` / ${placesPool ?? 0}`}</b>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {t(SECTION_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <StandardSelect
            data={sections}
            dataKey={SECTION_KEY}
            dataType={STRING}
            style={SELECT_STYLE}
            value={isObject(section) ? section[SECTION_KEY] : section}
            onSelect={setSection}
            emptyOption
            showSearch
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {t(SCHOOLS_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <StandardSelect
            data={schools}
            dataKey={SCHOOL_KEY}
            dataType={STRING}
            style={SELECT_STYLE}
            value={isObject(school) ? school[SCHOOL_KEY] : school}
            onSelect={setSchool}
            allOption
            showSearch
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {t(SCHOOL_YEARS_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <StandardSelect
            data={schoolYears}
            dataKey={SCHOOL_YEAR_KEY}
            dataType={STRING}
            disabled={school === WILD_CART_VALUE || !isObject(section)}
            style={SELECT_STYLE}
            value={isObject(selectedSchoolYear) ? selectedSchoolYear[SCHOOL_YEAR_KEY] : selectedSchoolYear}
            onSelect={setSelectedSchoolYear}
            allOption
            showSearch
          />
        </Col>
      </Row>
      <div className='allocation-form-footer'>
        <Button
          disabled={school === WILD_CART_VALUE}
          type='primary'
          onClick={handleSubmit}
        >
          {t('Create')}
        </Button>
        <Button onClick={onCancel}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(AllocationForm)
