import React from 'react'

import StudentQuickMenu from '../../../Components/shared/InternshipsManager/StudentQuickMenu'
import PresetsTooltip from './ShiftTableParts/Tooltips/PresetsTooltip'
import { Checkbox } from 'antd'
import { INSTITUTION_CONTEXT, SCHOOL_CONTEXT, internshipStates } from '../../../utils/constants'
import InternshipValidationButton from '../../../Components/shared/ShiftsManager/InternshipValidationButton'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faSchool, faUserGraduate } from '@fortawesome/pro-solid-svg-icons'
import { SCHEDULE_VALIDATED } from '../../../utils/entities/internship'

import '../../../assets/shifts-manager.scss'

const NO_SECTION_NAME = 'NONE'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const InternshipCard = ({
  canValidateDesiderata, canValidateInternship, checked, context, displayInfoButton, internship, inWidgetMode, inDraftMode, isReadOnly, useCheckbox,
  onClickedDraft, onDeleteSchedule, onInternshipChecked, onSelectInternship, onSelectStudent, onSelectValidation,
  onValidateDesideratas, t
}) => {
  const getValidateButtonTooltip = internship => {
    if (internship.state === internshipStates.SCHEDULE_TO_VALIDATE) {
      return t('Internship to validate, student uploaded signed document')
    }

    if (internship.state === internshipStates.SCHEDULE_VALIDATED) {
      return t('Internship validated')
    }

    return t('Validate internship')
  }

  const getSectionName = internship => {
    let sectionName = 'NONE'

    if (internship.school === null) {
      sectionName = NO_SECTION_NAME
    } else if (internship.internshipYear?.sectionName !== 'NONE') {
      sectionName = internship.internshipYear.sectionAbbreviation
    } else if (internship.schoolSection?.comment) {
      sectionName = internship.schoolSection?.comment
    } else if (internship.schoolSection?.name) {
      sectionName = internship.schoolSection?.name
    }

    if (internship.internshipYear) {
      sectionName += ` (${internship.internshipYear.schoolYearName})`
    } else {
      sectionName = t('Without study year')
    }

    return sectionName
  }

  return (
    <div className='internship-card-container'>
      <div className='internship-card-title'>
        {!inWidgetMode && (
          <StudentQuickMenu
            canValidateDesiderata={canValidateDesiderata}
            internship={internship}
            isReadOnly={isReadOnly}
            onClickedDraft={onClickedDraft}
            onDeleteSchedule={onDeleteSchedule}
            onSelectedStudentInfo={onSelectStudent}
            onSelectedInternshipInfo={onSelectInternship}
            onValidateDesideratas={onValidateDesideratas}
            inShiftsManager
          />
        )}
        <div className='internship-information'>
          <div>
            <div className='regular-image'> <FontAwesomeIcon icon={faUserGraduate} /> </div>
            <span><b> {internship.student && `${internship.student.firstname} ${internship.student.lastname}`} - {getSectionName(internship)}</b></span>
          </div>
          <div>
            <div className='regular-image'> <FontAwesomeIcon icon={faSchool} /> </div>
            {context === SCHOOL_CONTEXT && internship.institution && (
              <span> {internship.institution.name} </span>
            )}
            {context === INSTITUTION_CONTEXT && internship.school && (
              <span> {internship.school.name} </span>
            )}
          </div>
          <div>
            <div className='regular-image'> <FontAwesomeIcon icon={faGraduationCap} /> </div>
            <div className='last-text'>
              {internship.sector && internship.sector.parent === null ? t('Without care unit') : internship.sector.name}
            </div>
          </div>
        </div>
      </div>
      <div className={'internship-card-buttons' + (useCheckbox ? ' in-draft-mode' : '')}>
        {(displayInfoButton && !inWidgetMode) && (
          <PresetsTooltip
            presets={internship.presets}
            sectorNote={internship.sectorNote}
          />
        )}
        {canValidateInternship && !inDraftMode && (
          <InternshipValidationButton
            validateButtonTooltip={getValidateButtonTooltip(internship)}
            internship={internship}
            onDisplayValidationDrawer={onSelectValidation}
          />
        )}
      </div>
      {useCheckbox && internship.state !== SCHEDULE_VALIDATED && !isReadOnly && (
        <div className='internship-checkbox'>
          <Checkbox checked={checked} onChange={() => onInternshipChecked(internship)} />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(InternshipCard)
