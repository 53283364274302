import React from 'react'

import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import { Upload } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import IconButton from '../Buttons/IconButton'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const FilesUpload = ({ files, text = 'files_upload.click_to_upload', onUpload, onRemove, t }) => {
  return (
    <Upload
      fileList={files}
      beforeUpload={onUpload}
      onRemove={onRemove}
    >
      <IconButton icon={faUpload} text={t(text)} />
    </Upload>
  )
}

export default connect(mapStateToProps)(FilesUpload)
