import React, { useEffect, useState } from 'react'

import IconLabel from '../icons/IconLabel'
import { faFile } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { conditionalPromise, resolvePromises } from '../../utils/apiHelper'
import Loading from '../../HOC/Loading'
import { getInstitutionDocuments } from '../../utils/api/institution'
import { getSectorDocuments } from '../../utils/api/sector'
import { downloadSectorDocument } from '../../utils/api/sectorDocument'
import { downloadInstitutionDocument } from '../../utils/api/institutionDocument'

const DEFAULT_STATE = { institution: [], sector: [], section: [] }

const mapStateToProps = (state) => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipFiles = ({ internship: { institution = null, sector = null }, user, t }) => {
  const [files, setFiles] = useState(DEFAULT_STATE)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const stack = []

    stack.push(conditionalPromise(
      () => getInstitutionDocuments(user, institution),
      institution !== null,
      []
    ).then((json) => json?.data ?? []))
    stack.push(conditionalPromise(
      () => getSectorDocuments(user, sector),
      institution !== null,
      []
    ).then((json) => json?.data ?? []))

    resolvePromises(stack).then(([institutionFiles, sectorAndSectionFiles]) => {
      const reduceFiles = sectorAndSectionFiles.reduce((acc, f) => {
        if (f.isSectionFile) {
          acc.section.push(f)
        } else {
          acc.sector.push(f)
        }

        return acc
      }, { sector: [], section: [] })

      setLoading(false)
      setFiles({ institution: institutionFiles, sector: reduceFiles.sector, section: reduceFiles.section })
    })
  }, [user, institution, sector])

  return (
    <div>
      <Loading loading={loading}>
        <div>
          <b><IconLabel icon={faFile} text={t('common.institution')} padding='pdl-0' /></b>
          <div className='flex-column pdl-12'>
            {files.institution.length === 0 && <span> {t('common.none')}</span>}
            {files.institution.map(f => (<a style={{ marginBottom: '10px' }} key={f.id} onClick={() => downloadInstitutionDocument(user, f)}>{f.originalName}</a>))}
          </div>
          <b><IconLabel icon={faFile} text={t('common.sector')} padding='pdl-0' /></b>
          <div className='flex-column pdl-12'>
            {files.sector.length === 0 && <span> {t('common.none')}</span>}
            {files.sector.map(f => (<a style={{ marginBottom: '10px' }} key={f.id} onClick={() => downloadSectorDocument(user, f)}>{f.originalName}</a>))}
          </div>
          <b><IconLabel icon={faFile} text={t('common.section')} padding='pdl-0' /></b>
          <div className='flex-column pdl-12'>
            {files.section.length === 0 && <span> {t('common.none')}</span>}
            {files.section.map(f => (<a style={{ marginBottom: '10px' }} key={f.id} onClick={() => downloadSectorDocument(user, f)}>{f.originalName}</a>))}
          </div>
        </div>
      </Loading>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipFiles)
