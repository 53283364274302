import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Tooltip } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'

import '../../assets/student-sheet.scss'

export const ELEMENT_TYPES = {
  DIVIDER: 'divider',
  COLLAPSE: 'collapse',
  SPACE: 'space'
}

const InformationDisplay = (props) => {
  return (
    <div className='information-displayer-container'>
      {
        props.information.map((i, index) => {
          let content = null

          if (i.type === ELEMENT_TYPES.COLLAPSE) {
            content = React.cloneElement(i.caption, { key: 'collapse' + index })
          } else if (i.type === ELEMENT_TYPES.DIVIDER) {
            content = <Divider key={'divider' + index} style={{ margin: '12px 0px 0px 0px' }} />
          } else if (i.type === ELEMENT_TYPES.SPACE) {
            content = <div key={'space' + index} style={{ height: '10px' }} />
          } else {
            content = (
              <Tooltip placement='left' title={typeof props.hideTitle === 'undefined' ? '' : i.title} key={`${i.id}_${index}`}>
                <div className='information-line flex-row' style={{ marginBottom: '5px' }}>
                  <div style={{ width: '20px' }}>
                    <FontAwesomeIcon icon={i.icon} />
                  </div>
                  <div style={i.style ?? {}} className={i.color ? 'flex-column text-color-' + i.color : 'flex-column'}>
                    {typeof props.hideTitle === 'undefined' ? <b>{i.title}</b> : null}
                    {i.caption}
                  </div>
                </div>
              </Tooltip>
            )
          }

          return content
        })
      }
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationDisplay)
