import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { Button, DatePicker, Form, Select, Tooltip } from 'antd'
import LazySelect from '../antd/Selects/LazySelect'
import { getAllStudents } from '../../utils/api/student'
import FormItem from 'antd/es/form/FormItem'
import { sendEmailMyself } from '../../utils/api/institutionEmail'
import { API_DATE_FORMAT, onError, onSuccess } from '../../utils/apiHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { faInfoCircle, faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { getAllInternships } from '../../utils/api/internship'
import moment from 'moment'
import { EUROPEAN_DATE } from '../../utils/constants'

import '../../assets/send-email-form.scss'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { isObject } from '../../utils'

const Option = Select.Option

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SendEmailMyselfForm = ({ emailData, t, user, onClose }) => {
  const { languages } = useContext(GlobalContext)

  const [dataToSend, setDataToSend] = useState(null)
  const [parameters, setParameters] = useState({ getter: null, requestParameters: {} })
  const [terminated, setTerminated] = useState(false)

  const timer = useRef(null)

  const languageLocales = useMemo(() => {
    return !emailData?.data?.languageLocale
      ? []
      : languages.find(l => l.languageTag === emailData.data.languageLocale.language.languageTag).languageLocales
  }, [emailData, languages])
  const options = useMemo(() => languageLocales.map(ll => <Option key={ll.languageTag} value={ll.languageTag}> {t('languages.' + ll.languageTag)} </Option>), [t, languageLocales])

  const [languageLocale, setLanguageLocale] = useState(languageLocales.length > 0 ? languageLocales[0] : null)

  const sendEmail = useCallback(() => {
    sendEmailMyself(user, emailData.data, languageLocale, dataToSend).then(json => {
      if (json?.data) {
        onSuccess(t('The email has been successfully sended.'))

        if (typeof onClose === 'function') {
          timer.current = setTimeout(() => onClose(), 5000)
        }

        setTerminated(true)
      }
    }).catch(() => {
      onError(t('An error has occured during the email sending'))
    })
  }, [emailData, user, languageLocale, dataToSend, t, onClose])

  useEffect(() => {
    if (emailData?.data?.entityType) {
      if (emailData.data.entityType === 'Student') {
        setParameters({ getter: getAllStudents, requestParameters: { deleted: false, completed: true }, key: d => `${d.firstname} ${d.lastname}` })
      } else if (emailData.data.entityType === 'Internship') {
        setParameters({
          getter: getAllInternships,
          requestParameters: { state: 'accepted', startDate: moment().format(API_DATE_FORMAT), endDate: moment().add(1, 'year').format(API_DATE_FORMAT) },
          key: d => {
            const student = d.student ? `${d.student.firstname} ${d.student.lastname}` : t('Without student')

            return `${student} (${moment(d.startDate).format(EUROPEAN_DATE)} - ${moment(d.endDate).format(EUROPEAN_DATE)})`
          }
        })
      } else {
        setParameters({ getter: null, requestParameters: {} })
      }
    } else {
      setParameters({ getter: null, requestParameters: {} })
    }
  }, [emailData])

  return (
    <div className='send-email-form-container'>
      {!terminated && (
        <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            {emailData?.data?.entityType === 'Internship' && (
              <Form.Item
                label={t('Period')}
                name='period'
              >
                <DatePicker.RangePicker
                  value={[moment(parameters.requestParameters.startDate), moment(parameters.requestParameters.endDate)]}
                  onChange={dates => {
                    setParameters({
                      ...parameters,
                      requestParameters: {
                        ...parameters.requestParameters,
                        startDate: dates[0].format(API_DATE_FORMAT),
                        endDate: dates[1].format(API_DATE_FORMAT)
                      }
                    })
                  }}
                />
              </Form.Item>
            )}
            {languageLocales.length > 0 && (
              <Form.Item
                name='language'
                rules={[
                  {
                    required: true,
                    message: t('Please select a language locale')
                  }
                ]}
              >
                <Select
                  value={isObject(languageLocale) ? languageLocale.languageTag : null}
                  onChange={value => setLanguageLocale(languageLocales.find(ll => ll.languageTag === value))}
                  disabled={options.length < 2}
                >
                  {options}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={(
                <div>
                  {t('Select ' + (emailData?.data?.entityType ?? '').toLowerCase())}
                  <Tooltip title={t('In order to try an email, data are requested to build the email correctly. Please select one of these.')}>
                    <FontAwesomeIcon icon={faInfoCircle} color='blue' />
                  </Tooltip>
                </div>
              )}
              name='data'
              rules={[
                {
                  required: true,
                  message: t('Please select student')
                }
              ]}
            >
              {typeof parameters.getter === 'function' && (
                <LazySelect
                  dataKey={parameters.key}
                  getData={parameters.getter}
                  parameters={parameters.requestParameters}
                  placement='top'
                  showSearch
                  value={dataToSend}
                  onSelect={setDataToSend}
                />
              )}
            </Form.Item>
          </div>
          <FormItem>
            <Button htmlType='submit' type='primary' disabled={!dataToSend || (languageLocales > 0 && languageLocale === null)} onClick={sendEmail}>
              <FontAwesomeIcon icon={faPaperPlane} />
              {t('Send the email')}
            </Button>
          </FormItem>
        </Form>
      )}
      {terminated && (
        <div className='flex-row'>
          <FontAwesomeIcon icon={faCheckCircle} color='green' size='3x' style={{ marginRight: '20px' }} />
          {t('The email has been send to your account email address. You will received it in a few moment.')}
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(SendEmailMyselfForm)
