import React, { useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tabs } from 'antd'
import Analytics from '../../../utils/analytics'

import '../../../assets/institution-settings.scss'
import UsersView from '../../shared/UsersView/UsersView'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { faClinicMedical, faUser } from '@fortawesome/pro-solid-svg-icons'
import InstitutionGroupView from './InstitutionGroupView'
import InstitutionGroupFormModal from '../../../HOC/Forms/InstitutionGroupFormModal'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'

const { TabPane } = Tabs

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const GroupsSettingsView = ({ t }) => {
  const { setInstitutionGroup } = useContext(InstitutionGroupContext)

  useEffect(() => {
    Analytics.pageView('/institution-settings')
  }, [])

  return (
    <Tabs defaultActiveKey='1' animated={false}>
      <TabPane
        key='1'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon={faUser} style={{ marginTop: '3px' }} />
            <div className='h-spacing' />
            {t('Manage users')}
          </div>
        }
      >
        <UsersView />
      </TabPane>
      <TabPane
        key='2'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon={faClinicMedical} style={{ marginTop: '3px' }} />
            <div className='h-spacing' />
            {t('settings_view.institution_group_tab.title')}
          </div>
        }
      >
        <InstitutionGroupFormModal onSave={setInstitutionGroup}>
          <InstitutionGroupView />
        </InstitutionGroupFormModal>
      </TabPane>
    </Tabs>
  )
}

export default connect(mapStateToProps)(GroupsSettingsView)
