import React, { useEffect, useMemo, useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Button, Modal, notification, Switch, Tooltip } from 'antd'
import { fetchInstitutionOptions, updateInstitutionOptions } from '../../reducers/InstitutionOptionsReducer/actions'
import { getInstitutions } from '../../reducers/InstitutionsReducer'
import { getUser } from '../../reducers/UserReducer'
import { bindActionCreators } from 'redux'
import { faEdit, faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AUTO_ACCEPTATION_DAYS, DRAFT_INTERNSHIPS, STUDENT_DESIDERATA_LIMIT } from '../../utils/constants'
import OptionValueModal from './OptionValueModal'

const MODAL_TITLE = "Update option's value"
const P_STYLE = { margin: '0px', display: 'flex', flexDirection: 'row', alignItems: 'center' }
const OPTIONS_WITH_DAY_VALUES = [AUTO_ACCEPTATION_DAYS, STUDENT_DESIDERATA_LIMIT]

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  institutions: getInstitutions(state),
  user: getUser(state.getUser),
  institutionOptions: state.institutionOptions.options
})

const mapDispatchToProps = dispatch => ({
  fetchInstitutionOptions: bindActionCreators(fetchInstitutionOptions, dispatch),
  updateInstitutionOptions: bindActionCreators(updateInstitutionOptions, dispatch)
})

export const STRING_FIELD = 0
export const BOOLEAN_FIELD = 1

const InstitutionOptionTable = ({ columns, density, institutions, institutionOptions, user, fetchInstitutionOptions, updateInstitutionOptions, t }) => {
  const [loading, setLoading] = useState(true)
  const [optionToUpdate, setOptionToUpdate] = useState(null)
  const [warningModal, setWarningModal] = useState({ visible: false, text: '' })

  const optionsTree = useMemo(() => {
    const optionsTree = {}

    institutionOptions.forEach(o => {
      const body = {
        ...o,
        type: o.optionType.type,
        isEditable: o.optionType.isEditable,
        isHidden: o.optionType.isHidden,
        isPremium: o.optionType.isPremium,
        children: [],
        parent: o.optionType.parent?.id ?? null
      }

      o.optionType.children.forEach(c => {
        body.children.push(c.id)
      })

      optionsTree[o.optionType.id] = body
    })

    return optionsTree
  }, [institutionOptions])

  const options = useMemo(() => {
    const options = { parents: {}, data: [] }

    Object.keys(optionsTree).forEach(key => {
      const o = optionsTree[key]

      if (o.parent !== null) {
        options.parents[key] = false

        if (optionsTree[o.parent] !== undefined) {
          options.parents[key] = optionsTree[o.parent].optionEnabled
        }
      }

      if (!o.isHidden && (options.parents[key] || options.parents[key] === undefined)) {
        if (o.isPremium && !o.isEditable) {
          if (o.optionEnabled) {
            options.data.push(o)
          }
        } else {
          options.data.push(o)
        }
      }
    })

    return options
  })

  useEffect(() => {
    if (institutions && institutions.length > 0 && user) {
      setLoading(true)

      fetchInstitutionOptions(institutions[0].id, user, () => { setLoading(false) })
    }
  }, [user, institutions])

  const renderHeadDetails = () => {
    return columns.map((column, index) => {
      if (index === 0) {
        return (
          <th key={index} className='name-header-institution-option'>
            {t(column.name)}
          </th>
        )
      } else {
        return (
          <th key={index} className='switch-header-institution-option'>
            {t(column.name)}
          </th>
        )
      }
    })
  }

  const renderName = data => {
    return (
      <td key={`col_name_${data.id}`} className='name_institution_option'>
        <div className='flex-row' style={{ margin: '5px' }}>
          {OPTIONS_WITH_DAY_VALUES.includes(data.type) && data.optionEnabled && (
            <Button onClick={() => setOptionToUpdate(data)} style={{ marginRight: '5px' }}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )}
          <Tooltip placement='top' title={t('institution.option.' + data.type)}>
            <p style={P_STYLE}>
              {t('institution.option.' + data.type)}
              {data.isPremium
                ? (
                  <Tooltip placement='top' title={t('Premium feature')}>
                    <FontAwesomeIcon icon={faStar} id='star-icon' style={{ marginLeft: '5px' }} />
                  </Tooltip>
                )
                : ('')}
            </p>
          </Tooltip>
        </div>
      </td>
    )
  }

  const renderSwitch = data => {
    return (
      <td key={`col_institution_option${data.id}`} className='switch-institution-option'>
        <Tooltip
          placement='top'
          title={data.optionEnabled ? t('Enabled') : t('Disabled')}
        >
          <Switch
            checked={data.optionEnabled}
            size='small'
            onChange={e => updateDataValue(data, e)}
            disabled={
              loading || !data.isEditable
            }
          />
        </Tooltip>
      </td>
    )
  }

  const updateDataValue = (data, e) => {
    if (data.type === DRAFT_INTERNSHIPS && !e) {
      setWarningModal({
        visible: true,
        text: 'If you deactivate the draft internship requests functionnality, all the pre-accepted internships will be revert back to created internships.',
        callback: () => {
          updateInstitutionOptions(institutions[0].id, data.id, { optionEnabled: e }, user)
          setWarningModal({ visible: false, text: '' })
        }
      })
    } else {
      const body = {
        optionEnabled: e
      }
      updateInstitutionOptions(institutions[0].id, data.id, body, user)
    }
  }

  const handleOptionValueUpdate = value => {
    setOptionToUpdate(null)

    updateInstitutionOptions(
      institutions[0].id,
      optionToUpdate.id,
      { optionValue: value },
      user,
      () => notification.success({
        message: t("The option's value has been successfully updated"),
        placement: 'bottomLeft'
      })
    )
  }

  return (
    <div className='interactive-table'>
      {loading && (
        <div className='center'>
          <div className='loading-icon black' />
        </div>
      )}
      {!loading && options.data.length > 0 && (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              {columns && renderHeadDetails()}
            </tr>
          </thead>
          <tbody>
            {columns && options.data.map((data, index) => {
              return (
                <tr key={'row_' + index} style={{ height: density ?? '32px' }}>
                  {columns.map(c => {
                    let cell = null

                    if (c.type === STRING_FIELD) {
                      cell = renderName(data, index)
                    } else if (c.type === BOOLEAN_FIELD) {
                      cell = renderSwitch(data, index)
                    } else {
                      cell = (
                        <td key={`col_${data.id}`} className='name_institution_option'>
                          {data.optionValue} {OPTIONS_WITH_DAY_VALUES.includes(data.type) && (' ' + t('days'))}
                        </td>
                      )
                    }

                    return cell
                  })}
                </tr>
              )
            })}
            {!columns && (
              <tr> {t('No columns have been found')} </tr>
            )}
          </tbody>
        </table>
      )}
      {!(loading || options.data.length > 0) && (
        <span> {t('No data is currently available for this page.')} </span>
      )}
      <OptionValueModal
        option={optionToUpdate}
        title={t(MODAL_TITLE)}
        onCancel={() => setOptionToUpdate(null)}
        onOk={handleOptionValueUpdate}
      />
      <Modal
        visible={warningModal.visible}
        title={t('Module activation/deactivation warning')}
        onOk={() => warningModal.callback()}
        onCancel={() => setWarningModal({ visible: false, text: '' })}
      >
        {t(warningModal.text)}
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionOptionTable)
