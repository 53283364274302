import React, { useContext, useEffect, useState } from 'react'

import { faCalendar, faPlus } from '@fortawesome/pro-solid-svg-icons'

import DateTitle from './DateTitle'
import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import { DAY_AND_MONTH_FORMAT } from '../utils/constants'

const InstitutionDateTitle = ({ startDate, endDate, displayAction, index, isCurrent }) => {
  const { highlightedIndexes } = useContext(InternshipsManagerContext)
  const [isHighlighted, setIsHighlighted] = useState(false)

  useEffect(() => {
    if (typeof index !== 'undefined' && highlightedIndexes) {
      setIsHighlighted(index >= highlightedIndexes.startIndex && index <= highlightedIndexes.lastIndex)
    } else {
      setIsHighlighted(false)
    }
  }, [index, highlightedIndexes])

  return (
    <DateTitle
      startDate={startDate}
      endDate={endDate}
      isCurrent={isCurrent}
      dateFormat={DAY_AND_MONTH_FORMAT}
      actionShown={displayAction}
      icon={displayAction ? faPlus : faCalendar}
      iconTitle={displayAction ? 'Add an internship' : 'Edit schedules'}
      index={index}
      highlighted={isHighlighted}
      onAction={() => {}}
    />
  )
}

export default InstitutionDateTitle
