import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'

const PresetSelect = (props) => {
  const [selectedRow, setSelectedRow] = useState(-1)

  if (props.presets.length === 0) {
    return (
      <div>{props.t('No presets available')}</div>
    )
  }

  const presets = Object.values(props.presets)
  const presetsRender = presets.map(p => {
    return (
      <Row
        key={p.id}
        onClick={() => {
          setSelectedRow(p.id)
          props.handlePresetSelection(p)
        }}
      >
        <Col
          span={5}
          style={{
            backgroundColor: p.color,
            textAlign: 'center',
            padding: '20px'
          }}
        >
          <Row align='middle'>
            {p.periodCode}
          </Row>
        </Col>
        <Col span={14} style={{ padding: '10px' }}>
          <Row style={{ paddingLeft: '5px' }}>
            {props.t('common.schedule')} : {p.startTime.substring(0, 5)} - {p.endTime.substring(0, 5)}
          </Row>
          <Row style={{ paddingLeft: '5px' }}>
            {props.t('Pause')} : {p.pause} min
          </Row>
        </Col>
        <Col
          span={5}
          style={{
            display: (selectedRow === p.id && props.isPresetSelected) ? 'block' : 'none',
            textAlign: 'center',
            padding: '20px'
          }}
        >
          <Row align='middle'>
            <FontAwesomeIcon icon='check' color='#7EB73E' />
          </Row>
        </Col>
      </Row>
    )
  })

  return (
    <div className='desiderata-modal-presets' style={{ overflow: 'auto', height: '260px' }}>
      {presetsRender}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PresetSelect)
