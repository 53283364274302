import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Checkbox, Divider, Input, List, Popconfirm } from 'antd'
import { faEdit, faSave, faSync, faUndo } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import ButtonGroup from 'antd/es/button/button-group'
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons'
import { getUser } from '../../../../reducers/UserReducer'
import { getInstitutionUsers } from '../../../../utils/api/institution'
import { linkHospitalDepartmentUsers, syncHospitalDepartmentUsers, unlinkHospitalDepartmentUsers } from '../../../../utils/api/hospitalDepartment'
import { isUndefined } from 'lodash'
import { onInfo, onSuccess, resolvePromises } from '../../../../utils/apiHelper'
import IconButton from '../../../../Components/antd/Buttons/IconButton'

const mapsStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const HospitalDepartmentUsersList = ({ users, t, user, institutionDepartment, onSave }) => {
  const [editView, setEditView] = useState(false)
  const [institutionUsers, setInstitutionUsers] = useState([])
  const [filters, setFilters] = useState({ checked: false, unchecked: false })
  const [searchValue, setSearchValue] = useState('')
  const [changedList, setChangedList] = useState({})

  const fecthUsers = useCallback(() => {
    if (institutionDepartment.data.institution) {
      getInstitutionUsers(user, institutionDepartment.data.institution, { 'order-by': 'email' }).then(({ data }) => {
        const updatedInstitutionUsers = data.map(instUser => {
          const isChecked = users.some(user => user.email === instUser.email)

          return { ...instUser, checked: isChecked }
        })

        setInstitutionUsers(updatedInstitutionUsers)
      })
    }
  }, [institutionDepartment, user, users])

  useEffect(() => {
    fecthUsers()
  }, [fecthUsers])

  const linkedUsers = useMemo(() => users.filter(user => user.email.includes(searchValue)), [users, searchValue])

  const displayedUsers = useMemo(() => institutionUsers.filter(user => user.email.includes(searchValue)).reduce((acc, user) => {
    if (isUndefined(changedList[user.id]) ? user.checked : !user.checked) {
      acc.checked.push(user)
    } else {
      acc.unchecked.push(user)
    }

    return acc
  }, { checked: [], unchecked: [] }), [institutionUsers, searchValue, changedList])

  const isAllChecked = useMemo(() => {
    let isUnchecked = !filters.unchecked && displayedUsers.checked.some(user => user.checked && !isUndefined(changedList[user.id]))

    if (!isUnchecked && !filters.checked) {
      isUnchecked = displayedUsers.unchecked.some(user => ((!user.checked && isUndefined(changedList[user.id])) || user.checked))
    }

    return !isUnchecked
  }, [displayedUsers, changedList, filters])

  const onCheckedSelected = () => {
    setFilters({ checked: !filters.checked, unchecked: false })
  }

  const onUncheckedSelected = () => {
    setFilters({ checked: false, unchecked: !filters.unchecked })
  }

  const handleCheckboxChange = useCallback(user => {
    if (isUndefined(changedList[user.id])) {
      setChangedList({ ...changedList, [user.id]: !user.checked })
    } else {
      const newChangedList = { ...changedList }

      delete newChangedList[user.id]

      setChangedList(newChangedList)
    }
  }, [changedList])

  const onSaveList = () => {
    onQuitEditView()
    const users = Object.keys(changedList).reduce((acc, key) => {
      if (changedList[key]) {
        acc.checkedUsers.push(key)
      } else {
        acc.uncheckedUsers.push(key)
      }

      return acc
    }, { checkedUsers: [], uncheckedUsers: [] })

    const promiseStack = [
      linkHospitalDepartmentUsers(user, institutionDepartment.data, users.checkedUsers),
      unlinkHospitalDepartmentUsers(user, institutionDepartment.data, users.uncheckedUsers)
    ]

    resolvePromises(promiseStack).then(() => {
      fecthUsers()
      onSave()
      onSuccess(t('hospital_department_link_users_list.changes_saved.success'))
    })
  }

  const onSyncUsers = () => {
    syncHospitalDepartmentUsers(user, institutionDepartment.data).then((json) => {
      if (json.data.length === 0) {
        onInfo(t('hospital_department_link_users_list.return_no_user_updated'))
      } else {
        onSuccess(t('hospital_department_link_users_list.return_users_updated'))
      }

      fecthUsers()
      onSave()
    })
  }

  const onQuitEditView = () => {
    fecthUsers()
    setChangedList(false)
    setEditView(!editView)
    setFilters({ checked: false, unchecked: false })
  }

  const handleSelectAllChange = () => {
    let users
    const newChangedList = searchValue ? { ...changedList } : {}

    if (!searchValue) {
      users = institutionUsers
    } else if (isAllChecked) {
      users = displayedUsers.checked
    } else {
      users = displayedUsers.unchecked
    }

    if (isAllChecked) {
      setChangedList(users.reduce((acc, user) => {
        if (user.checked) {
          acc[user.id] = false
        } else {
          delete acc[user.id]
        }

        return acc
      }, newChangedList))
    } else {
      setChangedList(users.reduce((acc, user) => {
        if (!user.checked) {
          acc[user.id] = true
        } else {
          delete acc[user.id]
        }

        return acc
      }, newChangedList))
    }
  }

  const renderListItems = useCallback((users, checked) => users.map(user => (
    <List.Item key={user.id} style={{ padding: 3, marginBottom: 3 }}>
      {editView && <Checkbox checked={checked} style={{ marginRight: 10 }} onChange={() => handleCheckboxChange(user)} />}
      <List.Item.Meta
        title={user.email}
        description={(
          <span style={{ color: '#a5a7af' }}>
            {user.firstname && user.lastname ? `${user.firstname} ${user.lastname} - ` : ''} {t(user.roles[0])}
          </span>
        )}
      />
    </List.Item>
  )), [editView, handleCheckboxChange])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Input.Search style={{ width: '300px' }} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
          {editView && (
            <ButtonGroup style={{ marginLeft: '5px' }}>
              <IconButton icon={faCheckSquare} type={filters.checked ? 'primary' : 'default'} onClick={onCheckedSelected} />
              <IconButton icon={faSquare} type={filters.checked ? 'primary' : 'default'} onClick={onUncheckedSelected} />
            </ButtonGroup>
          )}
        </div>
        <div>
          {!editView && (
            <>
              <IconButton icon={faEdit} style={{ marginRight: '5px' }} type='primary' onClick={() => setEditView(!editView)} />
              <Popconfirm
                placement='bottomRight'
                title={t('hospital_department_link_users_list.sync_confirmation')}
                onConfirm={onSyncUsers}
              >
                <IconButton icon={faSync} type='primary' />
              </Popconfirm>
            </>
          )}

          {editView && (
            <>
              <IconButton
                disabled={Object.keys(changedList).length === 0}
                icon={faSave}
                style={{ marginRight: '5px' }}
                type='primary'
                onClick={onSaveList}
              />
              <Popconfirm placement='bottomRight' title={t('hospital_department_link_users_list.quit_confirmation')} onConfirm={onQuitEditView}>
                <IconButton icon={faUndo} type='primary' />
              </Popconfirm>
            </>
          )}
        </div>
      </div>
      {editView && (
        <div style={{ marginTop: '15px' }}>
          <Checkbox
            checked={isAllChecked}
            onChange={handleSelectAllChange}
          />
          <span style={{ marginLeft: 10 }}>{t('Select all')}</span>
        </div>
      )}
      <Divider />
      <div style={{ maxHeight: 'calc(80vh - 80px)', overflowY: 'scroll' }}>
        <List size='small'>
          {!editView && renderListItems(linkedUsers)}
          {editView && !filters.unchecked && renderListItems(displayedUsers.checked, true)}
          {editView && !filters.checked && renderListItems(displayedUsers.unchecked, false)}
        </List>
      </div>
    </div>
  )
}

export default connect(mapsStateToProps)(HospitalDepartmentUsersList)
