import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import PersonnalFiles from './PersonnalFiles'
import ChooseProfilePictureModal from './ChooseProfilePictureModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { notification, Avatar } from 'antd'
import { requestMultipart } from '../../../utils'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import 'react-image-crop/dist/ReactCrop.css'
import { StudentContext } from '../../../Providers/StudentProvider'

const ACCEPTED_DOCUMENT_TYPES = ['image/jpeg']

const PersonnalHeader = (props) => {
  const { student } = useContext(StudentContext)

  const [profileImage, setProfileImage] = useState(props.getStudentProfileImage ? props.getStudentProfileImage : null)
  const [newPicture, setNewPicture] = useState(null)
  const [modalVisibility, setModalVisibility] = useState(false)

  const { fullName, sections } = useMemo(() => {
    const data = { fullName: '', sections: '' }

    if (student) {
      data.fullName = `${student.firstname} ${student.lastname}`
      data.sections = student.sections.reduce((acc, section) => {
        if (acc !== '') {
          acc += ' - '
        }

        if (section.sectionToYear) {
          acc += `${section.sectionToYear.sectionName} (${section.sectionToYear.schoolYearName})`
        } else if (section.name) {
          acc += section.name
        }

        return acc
      }, '')
    }

    return data
  }, [student])

  const uploadingImage = useRef(null)

  useEffect(() => {
    if (props.getStudentProfileImage) {
      setProfileImage(props.getStudentProfileImage)
    }
  }, [props.getStudentProfileImage])

  const getCroppedPicture = croppedImage => {
    const oldProdilePictureUrl = { ...profileImage }

    setProfileImage(URL.createObjectURL(croppedImage))
    URL.revokeObjectURL(oldProdilePictureUrl)

    const body = {
      file: croppedImage,
      id: props.getUser.student.id,
      firstname: student.firstname,
      lastname: student.lastname
    }

    requestMultipart('/profil/image', 'POST', body, props.getUser)
      .then(json => {
        const user = { ...props.getUser }

        user.student.profileImageName = json.data

        props.fetchStudentProfileImage(json.data, user)
        props.setUser(user)
      })
  }

  const uploadProfilePicture = event => {
    const file = event.target.files[0]

    if (file && ACCEPTED_DOCUMENT_TYPES.includes(file.type)) {
      const reader = new window.FileReader()

      reader.addEventListener('load', () => {
        setNewPicture(reader.result)
        setModalVisibility(true)
      })
      reader.readAsDataURL(file)
    } else {
      notification.error({ message: props.t('Only JPEG and JPG image types are supported.'), placement: 'bottomRight' })
    }
  }

  const handleModalClossing = () => {
    setModalVisibility(false)
  }

  return (
    <div className='flex-row profile-page-header'>
      <div className='profile-page-image'>
        <Avatar
          src={profileImage || props.getUser.student.profileImageName ? profileImage : '/assets/default_profile_img.png'}
          size={125}
          onClick={() => uploadingImage.current.click()}
        />
        <div
          className='profile-picture-overlay'
          onClick={() => uploadingImage.current.click()}
        >
          <FontAwesomeIcon key={1} icon={faEdit} />
        </div>
        <input ref={uploadingImage} style={{ display: 'none' }} type='file' onChange={uploadProfilePicture} />
      </div>
      <div className='profile-page-title'>
        <h1> {fullName} </h1>
        <h2> {sections} </h2>
        <PersonnalFiles
          entity={props.getUser.student}
        />
      </div>

      <ChooseProfilePictureModal
        image={newPicture}
        visible={modalVisibility}
        onOk={handleModalClossing}
        onCancel={handleModalClossing}
        getCroppedPicture={getCroppedPicture}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalHeader)
