import React, { useEffect, useState } from 'react'

export const WindowWidthAware = ({ children, limit }) => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setWidth])

  return React.cloneElement(children, { width: !limit || width < limit ? width : limit })
}
