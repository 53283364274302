import React, { useCallback, useMemo, useState, useRef } from 'react'

import { Modal, Upload, Progress, Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { faDownload, faFileImport } from '@fortawesome/pro-solid-svg-icons'
import { getUser } from '../../../reducers/UserReducer'
import { getLanguageByLocale } from '../../../utils'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { getInternshipsImportTemplate, importInternships } from '../../../utils/api/internship'
import TooltipIconButton from '../../antd/Buttons/TooltipIconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../../assets/students-import-modal.scss'

const { Dragger } = Upload

const ACCEPTED_DOCUMENT_TYPES = [
  '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'
]

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  activeLanguage: getActiveLanguage(state.locale).code,
  user: getUser(state.getUser)
})

const ImportInternshipsButton = ({ activeLanguage, school, user, t, afterUpload }) => {
  const [visible, setVisible] = useState(false)
  const [importing, setImporting] = useState(false)
  const [importPercentage, setImportPercentage] = useState(0)
  const [fileList, setFilesList] = useState([])

  const acceptedTypes = useMemo(() => ACCEPTED_DOCUMENT_TYPES.join(','), [])
  const progressTimer = useRef(null)

  const resetStates = useCallback((percentage = 0) => {
    setFilesList([])
    setImporting(false)
    setImportPercentage(percentage)
  }, [setFilesList, setImporting, setImportPercentage])

  const updateProgress = useCallback(() => {
    let percentage = 0

    progressTimer.current = setInterval(() => {
      if (percentage >= 99) {
        return clearInterval(progressTimer.current)
      }

      percentage += percentage >= 90 ? 1 : 10
      setImportPercentage(percentage)
    }, 600)
  }, [setImportPercentage, progressTimer.current])

  const handleFileUpload = async () => {
    if (fileList.length > 0) {
      setImporting(true)

      if (ACCEPTED_DOCUMENT_TYPES.includes(fileList[0].type)) {
        updateProgress()

        importInternships(
          user,
          fileList[0],
          { school, language: getLanguageByLocale(activeLanguage) },
          () => {
            afterUpload()
            handleClose()
            onSuccess(t('import_internships_button.import.success'))
          },
          () => {
            afterUpload()
            handleClose()
            onError(t('import_internships_button.import.error'))
          }
        )
      }
    }
  }

  const downloadTemplate = () => {
    getInternshipsImportTemplate(user, { language: getLanguageByLocale(activeLanguage) }).then(() => {
      onSuccess(t('import_internships_button.download_template.success'))
    }).catch(() => {
      onError(t('import_internships_button.download_template.error'))
    })
  }

  const handleClose = useCallback(() => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current)
    }

    resetStates()
    setVisible(false)
  }, [progressTimer.current, resetStates, setVisible])

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setVisible(true)}>
        <FontAwesomeIcon icon={faFileImport} />
        {t('import_internships_button.tooltip_title')}
      </Menu.Item>
      <Menu.Item onClick={downloadTemplate}>
        <FontAwesomeIcon icon={faDownload} />
        {t('import_internships_button.download_template')}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <TooltipIconButton
          title={t('import_internships_button.tooltip_title')}
          icon={faFileImport}
        />
      </Dropdown>
      <Modal
        title={t('import_internships_button.modal_title')}
        visible={visible}
        onCancel={handleClose}
        onOk={handleFileUpload}
        okText={t('Import')}
        cancelText={t('Cancel')}
        okButtonProps={{ disabled: fileList.length === 0 }}
        destroyOnClose
      >
        <div className='students-import-modal-body aln-itm-ctr'>
          {!importing && (
            <Dragger
              fileList={fileList}
              name='file'
              accept={acceptedTypes}
              beforeUpload={(file) => {
                setFilesList([file])

                return false
              }}
              onRemove={() => setFilesList([])}
            >
              <p className='ant-upload-text'>{t('import_internships_button.upload_text')} </p>
              <p className='ant-upload-hint'>{t('import_internships_button.upload_hint')} </p>
            </Dragger>
          )}
          {importing && (
            <div className='importing-animation'>
              <Progress type='circle' percent={importPercentage} />
            </div>
          )}
        </div>

      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(ImportInternshipsButton)
