import { notification } from 'antd'
import { isArray, unset } from 'lodash'
import moment from 'moment'
import { isObject } from '.'
import { camelCasetoKebabCase } from './string'
import { store } from '..'
import { getTranslate } from 'react-localize-redux'

export const GET = 'GET'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const POST = 'POST'
export const API_DATE_FORMAT = 'YYYY-MM-DD'

/** USER API ERRORS */
export const DELETED_USER = 'DELETED_USER'
export const DUPLICATED_USER = 'DUPLICATED_USER'

export const getUrlParametersFromObject = urlObject => {
  let parametersAsString = ''

  if (urlObject) {
    const urlParameters = Object.keys(urlObject)

    urlParameters.forEach((propertyName, index) => {
      parametersAsString += getUrlPartWithLength(
        propertyName,
        urlObject[propertyName],
        index,
        urlParameters.length
      )
    })
  }

  return parametersAsString
}

export const makeQueryStringFromObject = queryParameters => {
  let query = ''

  if (typeof queryParameters === 'object') {
    const parametersName = Object.keys(queryParameters)
    const maxIndex = parametersName.length - 1

    let index = 0

    parametersName.forEach(name => {
      if (
        typeof queryParameters[name] !== 'undefined' &&
        queryParameters[name] !== null &&
        queryParameters[name] !== ''
      ) {
        const firstSymbol = getFirstSymbolByIndex(index, maxIndex)

        query += buildParameter(name, queryParameters[name], firstSymbol)
        index++
      }
    })
  }

  return query
}

const getFirstSymbolByIndex = (index, maxIndex) => {
  if (index === 0) {
    return '?'
  }

  if (index <= maxIndex) {
    return '&'
  }

  return ''
}

const buildParameter = (name, value, firstSymbol) => {
  if (Array.isArray(value)) {
    let arrayValues = firstSymbol

    value.forEach((v, index) => {
      if (index === 0) {
        arrayValues += `${name}[]=${v}`
      } else {
        arrayValues += `&${name}[]=${v}`
      }
    })

    return arrayValues
  }

  return `${firstSymbol}${name}=${value}`
}

export const arrayToUrlParameters = (array, parameterName) => {
  let urlParameters = ''

  array.forEach((element, index) => {
    if (index !== 0) {
      urlParameters += '&'
    }

    urlParameters += `${parameterName}[]=${element}`
  })

  return urlParameters
}

export const objectToUrlParameters = (object, parameterName) => {
  let urlParameters = ''
  let index = 0

  for (const key in object) {
    if (object[key] === 0 || object[key] || object[key] === false) {
      if (index !== 0) {
        urlParameters += '&'
      }

      if (isArray(object[key])) {
        urlParameters += arrayToUrlParameters(object[key], `${parameterName}[${key}]`)
      } else {
        urlParameters += `${parameterName}[${key}]=${object[key]}`
      }

      index++
    }
  }

  return urlParameters
}

const valueToUrlParameters = (name, value) => {
  return name + '=' + value
}

const getUrlPartWithLength = (propertyName, propertyValue, currentIndex, propertiesLength) => {
  const parameterAsString = typeof propertyValue === 'object'
    ? arrayToUrlParameters(propertyValue, propertyName)
    : valueToUrlParameters(propertyName, propertyValue)

  return currentIndex === (propertiesLength - 1)
    ? parameterAsString
    : parameterAsString + '&'
}

export const dispatchIfData = (dispatch, type, json) => {
  if (json && json.data) {
    dispatch({ type, payload: json.data })
  }
}

export const makeQueryString = parameters => {
  let queryString = ''

  if (isObject(parameters)) {
    let index = 0

    Object.keys(parameters).forEach(key => {
      const value = parameters[key]

      if (typeof value !== 'undefined' && value !== null && value !== '') {
        let encodedParameter = index === 0 ? '?' : '&'

        if (isObject(value)) {
          encodedParameter += objectToUrlParameters(value, camelCasetoKebabCase(key))
        } else if (isArray(value)) {
          encodedParameter += arrayToUrlParameters(value, camelCasetoKebabCase(key))
        } else {
          encodedParameter += camelCasetoKebabCase(key) + '=' + value
        }

        queryString += encodedParameter
        index++
      }
    })
  }

  return queryString
}

export const purgeMetadata = metadata => {
  if (typeof metadata.per_page !== 'undefined') {
    metadata.perPage = metadata.per_page

    unset(metadata.per_page)
  }

  if (typeof metadata.total_pages !== 'undefined') {
    metadata.totalPages = metadata.total_pages

    unset(metadata.total_pages)
  }

  return metadata
}

export const onError = (message, placement = 'bottomRight') => {
  notification.error({ message, placement })
}

export const onInfo = (message, placement = 'bottomRight') => {
  notification.info({ message, placement })
}

export const onSuccess = (message, placement = 'bottomRight') => {
  notification.success({ message, placement })
}

export const onWarning = (message, placement = 'bottomRight') => {
  notification.warning({ message, placement })
}

export const URIEncode = uri => {
  if (typeof uri === 'string') {
    uri = uri.replace('+', '%2B')
  }

  return uri
}

export const URIDecode = uri => {
  if (typeof uri === 'string') {
    uri = uri.replace('%2B', '+')
  }

  return uri
}

export const getFrontDate = backendDateString => {
  return moment(backendDateString.split('+')[0])
}

export const getErrorBody = error => {
  return error.body.getReader().read().then(({ value }) => {
    const decoded = new TextDecoder('utf-8').decode(value)

    return JSON.parse(decoded)
  })
}

export const displayError = (error, prefix) => {
  const t = getTranslate(store.getState().locale)

  return error.body.getReader().read().then(({ value }) => {
    const decoded = new TextDecoder('utf-8').decode(value)

    onError(t(`${prefix}.${JSON.parse(decoded).message.toLowerCase()}`))
  })
}

export const resolvePromises = promises => {
  return Promise.all(promises)
}

export const conditionalPromise = (functionToCall, condition, defaultValue) => {
  return new Promise(resolve => resolve(condition ? functionToCall() : defaultValue))
}
