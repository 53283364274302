import React from 'react'

import Mailing from '../shared/Mailing'
import IconLabel from '../icons/IconLabel'

const ContactPerson = ({ contactPerson, icon }) => {
  const name = contactPerson.lastname && contactPerson.firstname ? `${contactPerson.lastname} ${contactPerson.firstname}` : (contactPerson.name ?? '')

  return (
    <div className='flex-column jtf-ctn'>
      <div className={name ? 'flex-column' : 'flex-row'}>
        <IconLabel fontSize='small' icon={icon} text={name} color={null} />
        {contactPerson.email && <div className={name ? 'pdl-16' : ''}> <Mailing email={contactPerson.email} /> </div>}
      </div>
      {contactPerson.phone && <> &nbsp; {'-'} &nbsp; {contactPerson.phone}</>}
    </div>
  )
}

export default ContactPerson
