import { ROLE_NURSE, ROLE_OBSERVER, ROLE_STUDENT, ROLE_SUPERVISOR, ROLE_HOSPITAL_ADMIN, ROLE_ADMIN, ROLE_ENCODER, SCHOOL_CONTEXT } from './constants'
import { isArray, isObject } from 'lodash'

export const isNurse = user => isValidUser(user) && user.roles.includes(ROLE_NURSE)

export const isObserver = user => isValidUser(user) && user.roles.includes(ROLE_OBSERVER)

export const isSupervisor = user => isValidUser(user) && user.roles.includes(ROLE_SUPERVISOR)

export const isEncoder = user => isValidUser(user) && user.roles.includes(ROLE_ENCODER)

export const isHospitalAdmin = user => isValidUser(user) && user.roles.includes(ROLE_HOSPITAL_ADMIN)

export const isAdmin = user => isValidUser(user) && user.roles.includes(ROLE_ADMIN)

export const isStudent = user => isValidUser(user) && user.roles.includes(ROLE_STUDENT)

export const isSchoolUser = user => isValidUser(user) && user.context.includes(SCHOOL_CONTEXT)

const isValidUser = user => {
  return user && isObject(user) && isArray(user.roles)
}

export default { isNurse, isObserver, isEncoder, isSupervisor }
