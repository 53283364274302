import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { faCalendar, faHospital } from '@fortawesome/pro-regular-svg-icons'
import { faBusinessTime, faCalendarAlt, faSchool, faChartPie, faFileExport, faFilter, faPlus, faSort } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Dropdown, Menu, notification, Select, Tooltip } from 'antd'
import Search from 'antd/lib/input/Search'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { INSTITUTION_CONTEXT, ROLE_ENCODER, SCHOOL_CONTEXT } from '../../../utils/constants'
import NewMessagesNotification from '../NewMessagesNotification'
import InternshipsManagerActionsHeaderExportModal from '../InternshipsManagerActionsHeaderExportModal'
import { checkIfJsonIsCorrect, downloadFile, generalErrorHandler, getLanguageByLocale, requestWithPromise } from '../../../utils'
import { HTTP_INTERNAL_ERROR } from '../../../utils/http'
import InternshipStatesOrderBy from '../InternshipStatesOrderBy'
import PaginationHeader from './PaginationHeader'
import WeekNavigator from './WeekNavigator'
import CurrentWeekButton from './CurrentWeekButton'
import { DEFAULT_USER_STATUS, getUserStatusByRole } from './utils/utils'
import { DATE_FORMAT_SHOWN } from './utils/constants'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { withRouter } from 'react-router-dom'
import routes from '../../../routes'
import PeriodSelector from './PeriodSelector'
import StatesSelector from './StatesSelector'
import ImportInternshipsButton from './ImportInternshipsButton'
import SchoolsSelector from './SchoolsSelector'
import { isEncoder, isHospitalAdmin } from '../../../utils/roles'
import IconLabel from '../../icons/IconLabel'
import InstitutionsSelector from './InstitutionsSelector'
import SectionsSelector from './SectionsSelector'
import DateExcludor from '../DateExcludor'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'

import './assets/internships-manager.scss'

const IN_ADD_MODE_TEXT = 'Hide the add internship buttons'
const NOT_ADD_MODE_TEXT = 'Show the internships adding buttons'
const CURRENT_WEEK_BUTTON_TEXT = 'Go back to current week'
const EXPORT_BUTTON_TEXT = 'Export timeline'

export const PRECISION_YEAR = 'year'
const PRECISION_SCHOOL = 'school'
const PRECISION_SECTION = 'section'
const PRECISION_SECTOR = 'sector'
const PRECISION_OPTIONS = [
  { value: PRECISION_SECTOR, label: 'Quotas per sector' },
  { value: PRECISION_SECTION, label: 'Quotas per section' },
  { value: PRECISION_SCHOOL, label: 'Quotas per school' },
  { value: PRECISION_YEAR, label: 'Default quotas' }
]

const SEARCH_FIELD_STYLE = { width: 175 }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipsHeader = ({
  addMode, orderBy, sortDirection, states, schools, institutions, sections, areStartedExcluded, weeksShownCount, page, history, precision, metadata,
  loading, loadShiftsManager, user, onSearchChange, onOrderByChange, onPageChange,
  onPrecisionChange, onSortDirectionChange, onStatesChange, onSchoolSelection, onInstitutionSelection, onSectionSelection, onStartedExclusion, onWeeksShowChange, t, toggleAddMode,
  onResetFilters
}) => {
  const { setCurrentDate } = useContext(GlobalContext)
  const { internshipDate, internshipSearch, setInternshipDate, setInternshipSearch } = useContext(GlobalFiltersContext)

  const [userStatus, setUserStatus] = useState(DEFAULT_USER_STATUS)
  const [displayAddingButton, setDisplayAddingButton] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [displayExportModal, setDisplayExportModal] = useState(false)
  const [precisionOptions, setPrecisionOptions] = useState([])

  const dropDownMenu = useMemo(() => {
    return (
      <Menu>
        <div className='h-spacing' />
        <Menu.Item key='state' disabled style={{ cursor: 'default' }}>
          <IconLabel padding={0} icon={faBusinessTime} text={t('Internship')} />
          <StatesSelector onCheck={onStatesChange} values={states} />
        </Menu.Item>

        {isEncoder(user) && (<div className='h-spacing' />)}
        {isEncoder(user) && (
          <Menu.Item key='institutions' disabled style={{ cursor: 'default' }}>
            <IconLabel padding={0} icon={faHospital} text={t('Institutions')} />
            <InstitutionsSelector key='institutionsSelector' preSelected={institutions} onChange={onInstitutionSelection} style={{ width: '210px' }} />
          </Menu.Item>
        )}

        {isEncoder(user) && (<div className='h-spacing' />)}
        {isEncoder(user) && (
          <Menu.Item key='sections' disabled style={{ cursor: 'default' }}>
            <IconLabel padding={0} icon={faSchool} text={t('Sections')} />
            <SectionsSelector key='sectionsSelector' preSelected={sections} onChange={onSectionSelection} style={{ width: '210px' }} />
          </Menu.Item>
        )}

        {isHospitalAdmin(user) && (<div className='h-spacing' />)}
        {isHospitalAdmin(user) && (
          <Menu.Item key='schools' disabled style={{ cursor: 'default' }}>
            <IconLabel padding={0} icon={faSchool} text={t('Schools')} />
            <SchoolsSelector key='shcoolsSelector' preSelected={schools} onChange={onSchoolSelection} style={{ width: '210px' }} />
          </Menu.Item>
        )}

        <Menu.Item key='filter-on-start-date' disabled style={{ cursor: 'default' }}>
          <IconLabel padding={0} icon={faCalendar} text={t('internships_header.filters.date_excludor')} />
          <DateExcludor toggled={areStartedExcluded} onToggle={onStartedExclusion} />
        </Menu.Item>

        <Menu.Item key='order' disabled style={{ cursor: 'default' }}>
          <IconLabel padding={0} icon={faSort} text={t('Order by')} />
          <InternshipStatesOrderBy
            direction={sortDirection}
            value={orderBy}
            onChange={onOrderByChange}
            onDirectionChange={onSortDirectionChange}
          />
        </Menu.Item>

        {user.context.includes(INSTITUTION_CONTEXT) && (
          <Menu.Item key='precision' disabled style={{ cursor: 'default' }}>
            <IconLabel padding={0} icon={faChartPie} text={t('Quotas')} />
            <Select value={precision} onChange={onPrecisionChange} style={{ width: '210px' }}>
              {precisionOptions.map((o, index) => {
                return (
                  <Select.Option key={'opt_' + index} value={o.value}>
                    {o.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Menu.Item>
        )}

        <div className='h-spacing' />

        <Menu.Item key='config' disabled style={{ cursor: 'default', paddingBottom: 0 }}>
          <IconLabel padding={0} icon={faCalendarAlt} text={t('Period')} />
          <PeriodSelector
            currentWeeksQuantity={weeksShownCount}
            onWeeksQuantityChange={onWeeksShowChange}
          />
        </Menu.Item>

        <Divider style={{ marginTop: '9px', marginBottom: '9px' }} />

        <div className='center'>
          <Button
            type='primary'
            onClick={onResetFilters}
            disabled={loading}
            ghost
            style={{ marginBottom: '5px' }}
          >
            {t('Reset filters')}
          </Button>
        </div>
      </Menu>
    )
  }, [
    t, user.context, loading,
    states, schools, institutions, sections, areStartedExcluded, orderBy, sortDirection, precision, weeksShownCount, precisionOptions,
    onStatesChange, onSchoolSelection, onInstitutionSelection, onSectionSelection, onStartedExclusion, onOrderByChange, onSortDirectionChange, onPrecisionChange, onWeeksShowChange, onResetFilters
  ])

  const exportTimer = useRef()
  const clearingTimeout = useRef()

  useEffect(() => {
    if (user) {
      setUserStatus(getUserStatusByRole(user))
    }
  }, [user])

  useEffect(() => {
    setDisplayAddingButton(
      userStatus.context === SCHOOL_CONTEXT &&
      !(userStatus.isSupervisor || userStatus.isValidator)
    )
  }, [userStatus])

  useEffect(() => {
    setPrecisionOptions(PRECISION_OPTIONS.map(o => {
      return { ...o, label: t(o.label) }
    }))
  }, [t])

  const handleExportTimeline = (startDate, endDate, checked, filter) => {
    setDisplayExportModal(false)
    setExportLoading(true)

    const body = {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      language: getLanguageByLocale(user.language)
    }

    if (checked) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          if (typeof body.filter === 'undefined') {
            body.filter = {}
          }

          body.filter[key] = filter[key]
        }
      })
    }

    requestWithPromise(
      '/internship/export-timeline',
      'POST',
      body,
      user
    ).then(json => {
      exportTimer.current = setInterval(() => getJobResult(json), 1500)
      clearingTimeout.current = setTimeout(() => {
        clearInterval(exportTimer.current)
        setExportLoading(false)
        notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })
      }, 20000)
    })
  }

  const getJobResult = json => {
    // Security in case of export failed to prevent crash loop
    if (!json) {
      clearInterval(exportTimer.current)
      clearTimeout(clearingTimeout.current)
      setExportLoading(false)

      notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })

      return
    }

    requestWithPromise(`/job-result/${json.job}`, 'GET', null, user, { catchError: false }
    ).then(json => {
      if (checkIfJsonIsCorrect(json)) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)

        const fileName = json.data.result

        try {
          downloadFile(
            '/spreadsheet/download/' + fileName,
            fileName,
            user,
            'GET'
          )
        } catch (err) {
          generalErrorHandler(err)

          notification.error({ message: t('Spreadsheet download failed'), placement: 'bottomRight' })
        }
      }
    }).catch(response => {
      if (response.status === HTTP_INTERNAL_ERROR) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)
      }
    })
  }

  return (
    <div className='internships-manager-header'>
      <div className='actions-and-filters'>
        <Search
          value={internshipSearch}
          placeholder={t('Filter internships')}
          onChange={e => { setInternshipSearch(e.target.value) }}
          style={SEARCH_FIELD_STYLE}
          className='app-tour-2'
        />

        <div className='h-spacing' />

        <Dropdown overlay={dropDownMenu} trigger={['click']}>
          <Button>
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </Dropdown>
        <NewMessagesNotification />

        <div className='flex-fill' />

        <Button
          type='primary'
          onClick={() => {
            setCurrentDate(internshipDate)
            history.push(routes.SHIFTS_MANAGER)
          }}
          disabled={loading}
          ghost
        >
          <FontAwesomeIcon icon={faCalendar} />&nbsp;
          {t(userStatus.isValidator ? 'Validate schedules' : 'Edit schedules')}
        </Button>
        {displayAddingButton && <div className='h-spacing' />}
        {displayAddingButton && (
          <Tooltip
            key='internships'
            placement='top'
            title={t(addMode ? IN_ADD_MODE_TEXT : NOT_ADD_MODE_TEXT)}
          >
            <Button
              type='primary'
              className='app-tour-1'
              disabled={loading}
              onClick={toggleAddMode}
              ghost
            >
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;
              {t('Add an internship')}
            </Button>
          </Tooltip>
        )}
      </div>
      <div className='table-modifier-buttons'>
        <PaginationHeader
          pageIndex={page}
          maxPageIndex={metadata.totalPages}
          dataType='Internship'
          dataCount={metadata.total}
          onPageChange={onPageChange}
        />
        <div className='flex-fill' />
        <WeekNavigator
          loading={loading}
          date={internshipDate}
          dateFormat={DATE_FORMAT_SHOWN}
          onDateChange={setInternshipDate}
        />
        <div className='h-spacing' />
        <CurrentWeekButton
          date={internshipDate}
          loading={loading}
          title={t(CURRENT_WEEK_BUTTON_TEXT)}
          onClick={setInternshipDate}
        />
        {user.roles.includes(ROLE_ENCODER) && (
          <>
            <div className='h-spacing' />
            <ImportInternshipsButton
              afterUpload={() => setInternshipDate(internshipDate.clone())}
              school={user.school?.id ?? -1}
            />
          </>
        )}
        <div className='h-spacing' />
        <Tooltip placement='top' title={t(EXPORT_BUTTON_TEXT)}>
          <Button
            onClick={() => setDisplayExportModal(true)}
            loading={exportLoading}
          >
            <FontAwesomeIcon icon={faFileExport} />
          </Button>
        </Tooltip>
      </div>
      {displayExportModal && (
        <InternshipsManagerActionsHeaderExportModal
          startDate={internshipDate}
          weeksShownCount={weeksShownCount}
          filterSearch={internshipSearch}
          filterStates={states}
          filterSchools={schools}
          filterSections={sections}
          filterInstitutions={institutions}
          orderBy={orderBy}
          orderDirection={sortDirection}
          onCancel={() => setDisplayExportModal(false)}
          onExport={handleExportTimeline}
          visible
        />
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(InternshipsHeader))
