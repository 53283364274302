import React from 'react'

import { faBox, faDownload, faEdit, faFile, faStethoscope, faTrash, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popconfirm, Tooltip } from 'antd'
import { isFunction } from 'lodash'
import ArchivedSectorsModal from '../../../institution/ArchivedSectorsModal'

const DEFAULT_BUTTON_STYLE = { width: '34px', marginLeft: '2px', textAlign: 'center' }
const DEFAULT_GLOBAL_STYLE = { width: '24px', textAlign: 'center' }

export const ACTIONS = {
  ARCHIVE_SECTOR: 'archive_sector',
  CREATE: 'create',
  CREATE_ACT_TYPE: 'create_act_type',
  CREATE_ACT_TYPE_CATEGORY: 'create_act_type_category',
  CREATE_EC: 'create_external_contact',
  CREATE_SECTOR: 'create_sector',
  DELETE: 'delete',
  DELETE_ACT_TYPE_CATEGORY: 'delete_act_type_category',
  DELETE_EC: 'delete_external_contact',
  DISPLAY_ACT_CATEGORIES: 'display_act_categories',
  DISPLAY_ACT_TYPE_SECTIONS: 'display_act_type_sections',
  DISPLAY_INSTITUTION_SECTORS: 'display_institution_sectors',
  EDIT: 'edit',
  EDIT_ACT_TYPE: 'edit_act_type',
  EDIT_ACT_TYPE_CATEGORY: 'edit_act_type_category',
  EDIT_EC: 'edit_external_contact',
  EDIT_SECTOR: 'edit_sector',
  LINK_INSTITUTION_GROUP_SCHOOL: 'link_institution_group_school',
  LINK_INSTITUTION_SCHOOL: 'link_institution_school',
  SELECT: 'select',
  SWITCH_SECTOR: 'switch_sector',
  UNARCHIVE_SECTOR: 'unarchive_sector',
  UNLINK_INSTITUTION_GROUP_SCHOOL: 'unlink_institution_group_school',
  UNLINK_INSTITUTION_SCHOOL: 'unlink_institution_school'
}

export class ArchiveSector {
  constructor ({ onDelete, disabled = null }) {
    this.onDelete = onDelete
    this.title = 'Archive'
    this.icon = faBox
    this.popconfirmTitle = 'delete_action.pop_confirm.archive_sector'
    this.disabled = disabled
  }

  build () {
    return new DeleteAction(this)
  }
}

export class ConsultUsers {
  constructor ({ onClick }) {
    this.title = 'data_table.actions.consult_users'
    this.icon = faUser
    this.onClick = onClick
  }

  build () {
    return new Action(this)
  }
}

export class DownloadFile {
  constructor ({ onClick }) {
    this.title = 'data_table.actions.download_file'
    this.icon = faDownload
    this.onClick = onClick
  }

  build () {
    return new Action(this)
  }
}

export class DisplayDepartmentSectors {
  constructor ({ onClick, className }) {
    this.title = 'data_table.actions.display_department_sectors'
    this.icon = faStethoscope
    this.onClick = onClick
    this.className = className
  }

  build () {
    return new Action(this)
  }
}

export class EditHospitalDepartment {
  constructor ({ onClick, className }) {
    this.title = 'data_table.actions.edit_hospital_department'
    this.icon = faEdit
    this.onClick = onClick
    this.className = className
  }

  build () {
    return new Action(this)
  }
}

export class EditSector {
  constructor ({ onClick, disabled = false }) {
    this.title = 'data_table.actions.edit_sector'
    this.icon = faEdit
    this.onClick = onClick
    this.disabled = disabled
  }

  build () {
    return new Action(this)
  }
}

export class ManageFiles {
  constructor ({ onClick, className, disabled = false }) {
    this.title = 'data_table.actions.manage_files'
    this.icon = faFile
    this.onClick = onClick
    this.className = className
    this.disabled = disabled
  }

  build () {
    return new Action(this)
  }
}

export class ArchiveSectorModal {
  constructor ({ institution, onUnarchive, onDelete, t }) {
    this.Component = ArchivedSectorsModal
    this.key = 'archive'
    this.props = { institution, unarchive: onUnarchive, onDelete }
    this.title = t('sectors_table.external_actions.display_archive_sectors')
  }

  build () {
    return new ExternalAction(this)
  }
}

export class Action {
  constructor ({ className = null, color = null, disabled = null, icon, placement = 'top', title, toRender = null, onClick }) {
    this.className = className
    this.color = color
    this.disabled = disabled
    this.icon = icon
    this.placement = placement
    this.title = title
    this.toRender = toRender
    this.onClick = onClick
  }

  render (data, index, translator) {
    return (
      <Tooltip key={`actions-${index}`} placement='top' title={translator(this.title)}>
        <div className={isFunction(this.className) ? this.className(data) : ''}>
          <Button
            disabled={typeof this.disabled === 'function' ? this.disabled(data) : !!this.disabled}
            type='primary'
            size='small'
            style={DEFAULT_BUTTON_STYLE}
            onClick={() => this.onClick(data)}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}>
              <FontAwesomeIcon icon={this.icon ?? faEdit} color={this.color ?? ''} />
            </div>
          </Button>
        </div>
      </Tooltip>
    )
  }
}

export class DeleteAction {
  constructor ({ disabled = null, icon = faTrash, placement = 'top', popconfirmTitle = 'Delete this data ?', title = 'Delete', toRender = null, onDelete }) {
    this.disabled = disabled
    this.icon = icon
    this.placement = placement
    this.popconfirmTitle = popconfirmTitle
    this.title = title
    this.toRender = toRender
    this.onDelete = onDelete
  }

  render (data, index, translator) {
    return (
      <Tooltip key={`delete-action-${index}`} placement='top' title={translator(this.title)}>
        <Popconfirm
          placement='top'
          okType='danger'
          title={typeof this.popconfirmTitle === 'string' ? translator(this.popconfirmTitle) : this.popconfirmTitle}
          okText={translator('Yes')}
          cancelText={translator('Cancel')}
          onConfirm={() => this.onDelete(data)}
          zIndex={5000}
        >
          <Button type='danger' size='small' style={DEFAULT_BUTTON_STYLE} disabled={isFunction(this.disabled) ? this.disabled(data) : false}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={this.icon} />
            </div>
          </Button>
        </Popconfirm>
      </Tooltip>
    )
  }
}

export class GlobalDeleteAction {
  constructor ({ disabled = false, icon = faTrash, placement = 'top', title = 'Delete', onDelete }) {
    this.icon = icon
    this.placement = placement
    this.title = title
    this.handleDelete = onDelete
  }

  render (index) {
    return (
      <Tooltip key={`delete-action-${index}`} placement='top' title={this.title}>
        <Button type='danger' size='small' style={DEFAULT_GLOBAL_STYLE} disabled={this.disabled} onClick={this.handleDelete}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon icon={this.icon} />
          </div>
        </Button>
      </Tooltip>
    )
  }
}

export class ExternalAction {
  constructor ({ Component, key, props = {}, title, onClick }) {
    this.Component = Component
    this.key = key
    this.props = props
    this.title = title
    this.onClick = onClick
  }
}

export class ComponentAction {
  constructor ({ Component, props = {}, dataKey = null, containerClassName = null }) {
    this.Component = Component
    this.props = props
    this.dataKey = dataKey
    this.containerClassName = containerClassName
  }

  getProps (data) {
    const props = typeof this.props === 'function' ? this.props(data) : { ...this.props }

    if (this.dataKey) {
      props[this.dataKey] = data
    }

    return props
  }

  render (data, index, translator) {
    return (<this.Component {...this.getProps(data)} />)
  }
}
