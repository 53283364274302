import React, { useState, useEffect, useRef } from 'react'

import { Button, Drawer, List, Modal } from 'antd'
import Search from 'antd/lib/input/Search'
import { getUserSupervisedSectors, setUserSupervisedSectors } from '../../utils/api/user'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'

import '../../assets/supervised-sectors-drawer.scss'
import { onError, onSuccess } from '../../utils/apiHelper'
import Loading from '../shared/Loading'
import MultiSectorSelect from './MultiSectorSelect'

const mapStateToProps = state => ({
  currentUser: getUser(state.getUser),
  t: getTranslate(state.locale)
})

const UserSupervisedSectors = ({ currentUser, user, onClose, t }) => {
  const [supervisedSectors, setSupervisedSectors] = useState([])
  const [displayModal, setDisplayModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  const timer = useRef(null)

  useEffect(() => {
    if (currentUser && user) {
      fetchUserSectors(user, currentUser, { search })
    }
  }, [currentUser, user, search])

  const handleSectorUnlink = sector => {
    const newSectors = supervisedSectors.filter(s => s.id !== sector.id)

    setUserSupervisedSectors(newSectors, user, currentUser).then(json => {
      if (json?.data) {
        setSupervisedSectors(newSectors)
        onSuccess(t('The care units supervised by this user has been updated successfully'))
      } else {
        onError(t("An error has occured during the user's supervised care units update"))
      }
    })
  }

  const handleSectorsLink = sectors => {
    setDisplayModal(false)

    const newSectors = [...supervisedSectors]

    sectors.forEach(s => {
      if (!newSectors.find(ns => ns.id === s.id)) {
        newSectors.push(s)
      }
    })

    setUserSupervisedSectors(newSectors, user, currentUser).then(json => {
      if (json?.data) {
        onSuccess(t('The care units supervised by this user has been updated successfully'))
      } else {
        onError(t("An error has occured during the user's supervised care units update"))
      }
    }).then(() => {
      fetchUserSectors(user, currentUser)
    })
  }

  const fetchUserSectors = (user, currentUser, parameters = {}) => {
    setLoading(true)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      getUserSupervisedSectors(user, currentUser, parameters).then(json => {
        if (json?.data) {
          setSupervisedSectors(json.data.sort((a, b) => {
            const firstComparaison = a.institution.name.localeCompare(b.institution.name)

            return firstComparaison === 0
              ? a.name.localeCompare(b.name)
              : firstComparaison
          }))
        }

        setLoading(false)
      })
    }, parameters.search ? 500 : 0)
  }

  return (
    <Drawer
      title={user !== null ? t('Care units supervised by') + ' ' + user.email : '?'}
      width='640px'
      onClose={() => {
        onClose()
        setSupervisedSectors([])
        setSearch('')
      }}
      visible={user !== null}
    >
      <div className='supervised-sectors-container'>
        <div className='actions-list'>
          <Search onChange={e => setSearch(e.target.value)} />
          <Button type='primary' onClick={() => setDisplayModal(true)}>
            <FontAwesomeIcon icon={faPlus} /> &nbsp;
            {t('Link care unit(s)')}
          </Button>
        </div>
        {!loading && (
          <div className='supervised-sectors-list'>
            <List>
              {supervisedSectors.map((ss, index) => {
                return (
                  <List.Item key={'list-item_' + index}>
                    <div className='list-item-container'>
                      <span className='list-item-value'>
                        <b> {ss.institution.name} </b> - {ss.name}
                      </span>
                      <Button type='danger' onClick={() => handleSectorUnlink(ss)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </List.Item>
                )
              })}
            </List>
          </div>
        )}
        {loading && (<Loading size='4x' style={{ marginTop: '30px' }} />)}
      </div>
      <Modal
        title={t('Add supervised care units')}
        visible={displayModal}
        onCancel={() => setDisplayModal(false)}
        footer={null}
        wrapClassName='supervised-sectors-modal'
        destroyOnClose
      >
        <MultiSectorSelect
          onCancel={() => setDisplayModal(false)}
          onOk={handleSectorsLink}
        />
      </Modal>
    </Drawer>
  )
}

export default connect(mapStateToProps)(UserSupervisedSectors)
