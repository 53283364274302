import React, { useEffect, useState } from 'react'

import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import SectorIdCard from './SectorIdCard'
import { getSector } from '../../../utils/api/sector'
import Loading from '../../../HOC/Loading'
import { WindowWidthAware } from '../../../HOC/WindowWidthAware'

const mapStateToProps = (state) => ({ user: getUser(state.getUser) })

const SectorIdCardContainer = ({ columnMode = false, restrictMode, sectorId, user }) => {
  const [sector, setSector] = useState(null)

  useEffect(() => {
    if (user && sectorId) {
      getSector(user, { id: sectorId }).then(json => {
        if (json?.data) {
          setSector(json.data)
        }
      })
    } else {
      setSector(null)
    }
  }, [user.id, sectorId])

  return (
    <Loading loading={sector === null}>
      {sector && (
        <div className='pdl-4'>
          <WindowWidthAware>
            <SectorIdCard sector={sector} restrictMode={restrictMode} />
          </WindowWidthAware>
        </div>
      )}
    </Loading>
  )
}

export default connect(mapStateToProps)(SectorIdCardContainer)
