import React, { useCallback, useContext, useState } from 'react'

import { faFileExport } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DatePicker, Modal, Tooltip } from 'antd'
import moment from 'moment'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { downloadSpreadsheet, exportInstitutionQuotas } from '../../../../utils/api/export'
import { getUser } from '../../../../reducers/UserReducer'
import { onError } from '../../../../utils/apiHelper'
import { DATA_PROCESSING_FORMAT } from '../../../../utils/momentjs'
import useLocalStorage from '../../../../hooks/UseLocalStorage'
import SearchCheckbox from '../../../antd/Selects/SearchCheckbox'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { getLanguageByLocale } from '../../../../utils'

const START_OF_ACADEMYC_YEAR = moment().set('month', 7).set('date', 15).add(-1, 'year').day('Monday')
const END_OF_ACADEMYC_YEAR = moment().set('month', 6).set('date', 22).day('Sunday')
const LOCAL_STORAGE_START = 'quotas_export_button.start'
const LOCAL_STORAGE_END = 'quotas_export_button.end'

const WeekPicker = DatePicker.WeekPicker

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    activeLanguage: getActiveLanguage(state.locale).code,
    user: getUser(state.getUser)
  }
}

const QuotasExportButton = ({ activeLanguage, institution, user, t }) => {
  const { sectors } = useContext(QuotasContext)

  const [displayModal, setDisplayModal] = useState(false)
  const [startDate, setStartDate] = useLocalStorage(LOCAL_STORAGE_START, START_OF_ACADEMYC_YEAR.clone(), true)
  const [endDate, setEndDate] = useLocalStorage(LOCAL_STORAGE_END, END_OF_ACADEMYC_YEAR.clone(), true)
  const [selectedSectors, setSelectedSectors] = useState([])

  const exportQuotas = useCallback(() => {
    const params = { format: 'xlsx', sectorIds: selectedSectors }

    params.startDate = startDate.format(DATA_PROCESSING_FORMAT)
    params.endDate = endDate.format(DATA_PROCESSING_FORMAT)
    params.language = getLanguageByLocale(activeLanguage)

    exportInstitutionQuotas(user, institution, params).then(
      ({ data }) => downloadSpreadsheet(data, user)
    ).catch(error => {
      if (error.status) {
        onError(t('An error has occured during the export with status: ') + error.status)
      }
    })
  }, [activeLanguage, endDate, institution, startDate, user])

  const handleSectorCheck = useCallback((sector, add) => {
    const newSectors = add ? [...selectedSectors, sector] : selectedSectors.filter(ss => ss.id !== sector.id)

    setSelectedSectors(newSectors)
  }, [selectedSectors, setSelectedSectors])

  return (
    <>
      <Tooltip title={t('quotas_export_button.button_label')}>
        <Button onClick={() => setDisplayModal(true)}>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </Tooltip>
      <Modal
        visible={displayModal}
        onCancel={() => setDisplayModal(false)}
        onOk={exportQuotas}
        title={t('quotas_export_button.modal_title')}
        okText={t('quotas_export_button.modal_ok_button_label')}
        cancelText={t('quotas_export_button.modal_cancel_button_label')}
      >
        <div className='flex-column'>
          {t('Start date')}
          <WeekPicker
            value={startDate}
            format='DD/MM/YYYY'
            onChange={setStartDate}
          />
          <div className='v-spacing' />
          {t('End date')}
          <WeekPicker
            value={endDate}
            format='DD/MM/YYYY'
            onChange={setEndDate}
          />
          <div className='v-spacing' />
          {t('quotas_export_button.selected_sectors')}
          <SearchCheckbox
            checkAllOption
            initCheckAll
            items={sectors}
            labelKey='name'
            style={{ height: '200px', margin: '0px' }}
            valueKey='id'
            onCheck={handleSectorCheck}
            onCheckAll={setSelectedSectors}
          />
        </div>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(QuotasExportButton)
