import React, { useState, useEffect, useContext } from 'react'

import PopoverIconButton from '../shared/PopoverIconButton'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import TooltipIconButton from '../shared/TooltipIconButton'
import { BACKEND_DATE_FORMAT } from '../../../../utils/constants'
import moment from 'moment'
import { Card } from 'antd'
import { faBan, faEdit, faSave, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import PauseAllocationForm from './forms/PauseAllocationForm'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

export const POPOVER_CONTENT = 'Are you sure you want to delete these places by pause?'

const PauseCard = props => {
  const { pauses } = useContext(GlobalContext)
  const [disabled, setDisabled] = useState(true)
  const [editionMode, setEditionMode] = useState(false)
  const [pauseQuota, setpauseQuota] = useState(null)
  const [oldPauseQuota, setOldPauseQuota] = useState(null)

  useEffect(() => {
    if (props.data) {
      setpauseQuota(props.data)
    }
  }, [props.data])

  const handleEdition = () => {
    setDisabled(false)
    setEditionMode(true)
    setOldPauseQuota({ ...pauseQuota })
  }

  const handleSuppression = () => {
    if (typeof props.onDelete === 'function') {
      props.onDelete(props.data)
    }
  }

  const handleEditionCanceled = () => {
    if (typeof props.onPlacesChanged === 'function') {
      props.onPlacesChanged(pauseQuota.total - oldPauseQuota.total)
    }

    setDisabled(true)
    setEditionMode(false)
    setpauseQuota({ ...oldPauseQuota })
    setOldPauseQuota(null)
  }

  const handleSaving = () => {
    setDisabled(true)
    setEditionMode(false)
    setOldPauseQuota(null)

    if (typeof props.onSave === 'function') {
      props.onSave(pauseQuota)
    }
  }

  const updateQuotaPlaces = newPlaces => {
    if (typeof props.onPlacesChanged === 'function') {
      props.onPlacesChanged(pauseQuota.total - newPlaces)
    }

    setpauseQuota({ ...pauseQuota, total: newPlaces })
  }

  const updateQuotaDates = dates => {
    const newQuota = { ...pauseQuota }

    newQuota.startDate = dates[0].format(BACKEND_DATE_FORMAT)
    newQuota.endDate = dates[1].format(BACKEND_DATE_FORMAT)

    setpauseQuota(newQuota)
  }

  const updateQuotaPause = pause => {
    setpauseQuota({ ...pauseQuota, pause: { id: pause.id } })
  }

  const renderCardActions = () => {
    if (editionMode) {
      return (
        [
          <TooltipIconButton
            key='card-save-button'
            title='Save'
            onClick={handleSaving}
            disabled={!pauseQuota.pause}
            icon={faSave}
          />,
          <TooltipIconButton
            key='card-cancel-button'
            title='Cancel'
            onClick={handleEditionCanceled}
            icon={faBan}
          />
        ]
      )
    }

    return ([
      <TooltipIconButton
        key='card-edit-button'
        title='Edit'
        onClick={handleEdition}
        icon={faEdit}
      />,
      <PopoverIconButton
        key='card-trash-button'
        title='Delete'
        tooltipTitle='Delete'
        content={POPOVER_CONTENT}
        onConfirm={handleSuppression}
        icon={faTrash}
      />
    ])
  }

  return (
    <Card
      style={{ width: '100%', marginBottom: '10px' }}
      actions={renderCardActions()}
    >
      <QuickDualDatePickers
        startDate={pauseQuota ? moment(pauseQuota.startDate) : moment()}
        endDate={pauseQuota ? moment(pauseQuota.endDate) : moment()}
        onDatesChange={updateQuotaDates}
        disabled={disabled}
      />
      <PauseAllocationForm
        places={pauseQuota ? pauseQuota.total : 0}
        disabled={disabled}
        pause={pauseQuota && pauseQuota.pause ? pauses.find(pause => pause.id === pauseQuota.pause.id) : null}
        pauses={pauses}
        onPlacesChanged={updateQuotaPlaces}
        onPauseSelected={updateQuotaPause}
      />
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PauseCard)
