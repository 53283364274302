import React, { useState, useEffect } from 'react'

import { faKey, faFileImport, faDownload } from '@fortawesome/pro-solid-svg-icons'
import { Button, Modal, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_SELECT,
  DATA_TYPE_DATE
} from '../../shared/SmartTable'
import StudentInfo from '../../shared/StudentInfo'
import moment from 'moment'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { validateFormInput, request, objectDeepCopy, generalErrorHandler, downloadFile, dateStringOrNull } from '../../../utils'
import AdminResetPassword from '../../shared/AdminResetPassword'
import StudentsImportModal from '../StudentsImportModal'
import '../../../assets/school-students-view.scss'
import { getFullSectionName } from '../Sections/utils/DataDiverter'
import { formatStudentBirthdateInBackendForm } from '../../../utils/entitiesFormaters/Student'
import { arraySortingByKeyAndType } from '../../../utils/sorting'
import { GENDER_OPTIONS, COUNTRIES, EUROPEAN_DATE, ROLE_ENCODER, ROLE_ADMIN, ROLE_COORDINATOR, ROLE_SUPERVISOR } from '../../../utils/constants'
import { onInfo, onSuccess, onWarning } from '../../../utils/apiHelper'
import { isSupervisor } from '../../../utils/roles'

const EDIT_NOT_ALLOWED_MESSAGE = 'student.edit.not.allowed'
const EDIT_ONLY_SECTION_MESSAGE = 'student.edit.only.section'

const Students = props => {
  const [selectedStudentInfo, setSelectedStudentInfo] = useState(null)
  const [selectedStudentForPasswordReset, setSelectedStudentForPasswordReset] = useState(null)
  const [userIsSupervisor, setUserIsSupervisor] = useState(false)
  const [userIsCoordinator, setUserIsCoordinator] = useState(false)
  const [languages, setLanguages] = useState([])
  const [refreshingStudents, setRefreshingStudents] = useState(false)
  const [uploadModalDisplayed, setUploadModalDisplayed] = useState(false)
  const [existingStudentData, setExistingStudentData] = useState(null)
  const [downloadingTemplate, setDownloadingTemplate] = useState(false)
  const [showSchoolYearInSection, setShowSchoolYearInSection] = useState(false)
  const [showNiss, setShowNiss] = useState(false)
  const [canResetPassword, setCanResetPassword] = useState(false)

  useEffect(() => {
    if (props.getUser) {
      setShowSchoolYearInSection([ROLE_ENCODER, ROLE_COORDINATOR, ROLE_SUPERVISOR].includes(props.getUser.roles[0]))
      setShowNiss([ROLE_ENCODER, ROLE_COORDINATOR].includes(props.getUser.roles[0]))
      setUserIsSupervisor(isSupervisor(props.getUser))
      setUserIsCoordinator([ROLE_COORDINATOR, ROLE_ENCODER].includes(props.getUser.roles[0]))
      setCanResetPassword([ROLE_ENCODER, ROLE_ADMIN].includes(props.getUser.roles[0]))
    }
  }, [props.getUser])

  useEffect(() => {
    if (props.getLanguages) {
      setLanguages(props.getLanguages.map(({ code, name }) => ({
        id: code,
        name: props.t(name)
      })))
    }
  }, [props.getLanguages])

  const addStudent = async (student, stopLoadingCallback) => {
    student.birthdate = student.birthdate === null || student.birthdate.trim().length === 0
      ? undefined
      : `${student.birthdate.split('/')[2]}-${student.birthdate.split('/')[1]
        }-${student.birthdate.split('/')[0]}`

    student.stillInSchool = true
    delete student.isActive

    try {
      const { data } = await request('/api/Student', 'POST', student, props.getUser)

      if (!data) {
        stopLoadingCallback()

        return
      }

      handleStudentSaving(data)
      showNotificationIfStudentExist(data)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const showNotificationIfStudentExist = data => {
    if (data.studentAlreadyExists) {
      setExistingStudentData(data)
    }
  }

  const editStudent = async (student, stopLoadingCallback) => {
    student.birthdate = formatStudentBirthdateInBackendForm(student)

    if (student.user && student.user.id) {
      student.user = student.user.id
    }

    delete student.isActive

    const originalStudentValues = props.getStudents.find(item => item.id === student.id)
    student.originalSection = originalStudentValues.sections && originalStudentValues.sections.length ? originalStudentValues.sections[0].id : null

    try {
      const { data, message } = await request('/api/students/' + student.id, 'PATCH', student, props.getUser)

      if (!data) {
        stopLoadingCallback()

        return
      }

      if (message === EDIT_ONLY_SECTION_MESSAGE) {
        onInfo(props.t('students_view.edit_only_section'))
      } else if (message === EDIT_NOT_ALLOWED_MESSAGE) {
        onWarning(props.t('students_view.edit_not_allowed'))
      } else {
        onSuccess(props.t('students_view.edit_success'))
      }

      handleStudentSaving(data, student)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const handleStudentSaving = (student, oldStudent = null) => {
    if (student.birthdate) {
      const birthdate = student.birthdate.date ?? student.birthdate

      student.birthdate = { date: birthdate.split('T')[0] }
    }

    oldStudent ? updateStoredStudentData(student, oldStudent) : addNewStudentToStore(student)
  }

  const updateStoredStudentData = (student, oldStudent) => {
    student.user = oldStudent.user

    props.saveStudent(student, true, true)
  }

  const addNewStudentToStore = student => {
    props.saveStudent(student, false, true)
  }

  const deleteStudent = student => {
    props.removeStudent({
      studentId: student.id,
      currentUser: props.getUser
    })
    notification.success({ message: props.t('Recorded'), placement: 'bottomRight' })
  }

  const reloadStudentsAfterImport = async () => {
    setRefreshingStudents(true)
    await props.fetchStudents(props.getUser)
    setRefreshingStudents(false)
  }

  const handleCloseModal = () => {
    setUploadModalDisplayed(false)
  }

  const handleCloseStudentExistsNotification = () => {
    setExistingStudentData(null)
  }

  const handleDownloadTemplate = async () => {
    setDownloadingTemplate(true)
    await downloadFile('/students/download', 'students_template.xlsx', props.getUser)
    setDownloadingTemplate(false)
  }

  const formatSchoolSectionForSmartable = schoolSection => {
    return {
      id: schoolSection.id,
      name: showSchoolYearInSection
        ? getFullSectionName(schoolSection)
        : schoolSection.name,
      nameTitle: getFullSectionName(schoolSection, false, false)
    }
  }

  const render = () => {
    let students = []
    if (props.getStudents.length) {
      students = props.getStudents.map(s => {
        return {
          id: s.id,
          section: s.sections && s.sections.length > 0 ? s.sections[0].id : null,
          lastname: s.lastname,
          firstname: s.firstname,
          language: s.language,
          email: s.email,
          matricule: s.matricule,
          phoneNumber: s.phoneNumber,
          user: s.user,
          lastLogin: dateStringOrNull(s.lastLogin, EUROPEAN_DATE, 'date'),
          birthdate: dateStringOrNull(s.birthdate, EUROPEAN_DATE, 'date'),
          niss: s.niss ?? null,
          gender: s.gender ?? null,
          nationality: s.nationality ?? null,
          isActive: s.isActive ? props.t('Yes') : props.t('No')
        }
      })
    }

    let sections = []

    if (props.getSchools.length > 0) {
      const school = props.getSchools[0]

      sections = props.getSchoolSections[school.id]
        ? objectDeepCopy(props.getSchoolSections[school.id])
        : []

      if (props.getUser.roles.indexOf('ROLE_ENCODER') > -1) {
        sections.unshift(school.rootSection)
      }

      sections = sections.map(s => formatSchoolSectionForSmartable(s))
      sections = arraySortingByKeyAndType(sections, 'name', 'string')
    }

    const columns = [
      { type: DATA_TYPE_ID, key: 'id' },
      {
        type: DATA_TYPE_SELECT,
        name: props.t('common.section'),
        key: 'section',
        options: sections,
        width: showSchoolYearInSection ? 300 : undefined
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Lastname'),
        key: 'lastname',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Firstname'),
        key: 'firstname',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: DATA_TYPE_SELECT,
        name: props.t('Language'),
        key: 'language',
        options: languages,
        preventAutoSelectDefaultValue: true
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Email'),
        key: 'email',
        validate: data => validateFormInput('email', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Registration number'),
        key: 'matricule',
        isNullable: true,
        validate: data => validateFormInput('freeText', data, false)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Phone'),
        key: 'phoneNumber',
        validate: data => validateFormInput('freeText', data, false)
      },
      {
        type: DATA_TYPE_DATE,
        name: props.t('Birthdate'),
        key: 'birthdate',
        format: 'DD/MM/YYYY',
        isNullable: true,
        validate: data => validateFormInput('freeText', data, false)
      },
      {
        type: DATA_TYPE_SELECT,
        name: props.t('Gender'),
        key: 'gender',
        options: GENDER_OPTIONS(props)
      },
      {
        type: DATA_TYPE_SELECT,
        name: props.t('Nationality'),
        key: 'nationality',
        options: COUNTRIES(props)
      }
    ]

    if (showNiss) {
      columns.push(
        {
          type: DATA_TYPE_STRING,
          name: props.t('NISS'),
          key: 'niss',
          validate: data => validateFormInput('freeText', data, false),
          password: true
        }
      )
    }

    if (userIsCoordinator) {
      columns.push(
        {
          type: DATA_TYPE_DATE,
          name: props.t('Last login'),
          key: 'lastLogin',
          format: 'DD/MM/YYYY',
          disabled: true
        }
      )
      columns.push(
        {
          type: DATA_TYPE_STRING,
          name: props.t('Active'),
          key: 'isActive'
        }
      )
    }

    const additionalActions = [
      {
        iconName: 'user-graduate',
        type: 'primary',
        title: props.t('Student sheet'),
        onClick: s => setSelectedStudentInfo(s)
      }
    ]
    if (canResetPassword) {
      // add the button to reset the password
      additionalActions.push({
        iconName: faKey,
        type: 'primary',
        title: props.t('Reset password'),
        onClick: s => setSelectedStudentForPasswordReset(s),
        disabledCallback: s => s.user?.isExternal,
        titleCallback: s => s.user?.isExternal ? props.t('This user is external and cannot have its password reset') : ''
      })
    }

    const userIsEncoder = ['ROLE_ENCODER'].includes(props.getUser.roles[0])

    return (
      <div>
        <SmartTable
          columns={columns}
          data={students}
          onDataAdd={!userIsSupervisor ? addStudent : undefined}
          onDataEdit={!userIsSupervisor ? editStudent : undefined}
          onDataDelete={!userIsSupervisor ? deleteStudent : undefined}
          loading={!props.getDataReady.students || refreshingStudents}
          noDataText={props.t('You do not have any student.')}
          addDataText={props.t('Add a student')}
          secondaryButtons={userIsEncoder
            ? [
              <Button
                style={{ marginRight: '10px' }}
                key='download-students-button'
                loading={downloadingTemplate}
                onClick={handleDownloadTemplate}
              >
                <FontAwesomeIcon icon={faDownload} />&nbsp;
                {props.t('Download template')}
              </Button>,
              <Button
                key='import-students-button'
                onClick={() => {
                  setUploadModalDisplayed(true)
                }}
              >
                <FontAwesomeIcon icon={faFileImport} />&nbsp;
                {props.t('Import students')}
              </Button>
            ]
            : null}
          showSecondaryButtonsInFront
          additionalActions={additionalActions}
        />
        <StudentInfo
          student={selectedStudentInfo}
          onClose={() => setSelectedStudentInfo(null)}
        />
        {canResetPassword &&
          <AdminResetPassword
            userToResetPassword={selectedStudentForPasswordReset}
            onClose={() => setSelectedStudentForPasswordReset(null)}
          />}
        <StudentsImportModal
          displayed={uploadModalDisplayed}
          onClose={handleCloseModal}
          reloadStudentsAfterImport={reloadStudentsAfterImport}
        />
        <Modal
          title={props.t('Student already exists')}
          visible={existingStudentData !== null}
          onCancel={handleCloseStudentExistsNotification}
          footer={[
            <Button
              onClick={handleCloseStudentExistsNotification}
              key='close'
              type='primary'
            >
              <FontAwesomeIcon icon='check' />&nbsp;
              {props.t('OK')}
            </Button>
          ]}
        >
          {existingStudentData && (
            <>
              {/* eslint-disable-next-line */}
              <h3>{props.t('We found an existing student for email ${email}', { email: existingStudentData.email })}</h3>
              <div>{props.t('Below you can see the details of the existing student:')}</div>
              <ul className='existing-student-details'>
                <li>
                  <label>{props.t('Lastname')}</label>
                  <b>{existingStudentData.lastname || props.t('None')}</b>
                </li>
                <li>
                  <label>{props.t('Firstname')}</label>
                  <b>{existingStudentData.firstname || props.t('None')}</b>
                </li>
                <li>
                  <label>{props.t('Registration number')}</label>
                  <b>{existingStudentData.phoneNumber || props.t('None')}</b>
                </li>
                <li>
                  <label>{props.t('Phone')}</label>
                  <b>{existingStudentData.matricule || props.t('None')}</b>
                </li>
                <li>
                  <label>{props.t('Birthdate')}</label>
                  <b>{existingStudentData.birthdate ? moment(existingStudentData.birthdate.date).format('DD/MM/YYYY') : props.t('None')}</b>
                </li>
              </ul>
              <h3><b>{props.t('This existing student was linked to the section you selected.')}</b></h3>
            </>
          )}

        </Modal>
      </div>
    )
  }

  return (
    render()
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Students)
