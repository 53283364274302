import React from 'react'

const TextTitle = ({ title, text }) => {
  return (
    <div className='flex-row'>
      <b> {title} </b> &nbsp;
      <span> {text} </span>
    </div>
  )
}

export default TextTitle
