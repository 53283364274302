export const TYPES = {
  CONVENTION: 'convention',
  DOCUMENT: 'document'
}

export const ACCEPTED_DOCUMENT_META = [
  'text/csv', '.csv', '.xlsx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'image/jpeg'
]
