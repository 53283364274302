import React, { useCallback, useEffect, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getInternshipSupervisors } from '../../utils/api/internship'
import Loading from '../../HOC/Loading'
import UserContactInfo from '../shared/UserContactInfo'
import { List } from 'antd'

const mapStateToProps = (state) => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipSupervisors = ({ internship: { id = null }, user, t }) => {
  const [supervisors, setSupervisors] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    getInternshipSupervisors(user, { id }).then(json => {
      if (json?.data) {
        setSupervisors(json.data)
      }

      setLoading(false)
    })
  }, [user.id, id])

  const renderSupervisors = useCallback(u => <List.Item><UserContactInfo user={u} /></List.Item>)

  return (
    <div>
      <Loading loading={loading}>
        <div>
          <b> {t('common.supervisors')}  :</b>
          <div className='pdl-12'>
            {supervisors.length === 0 && <span>{t('common.none')}</span>}
            {supervisors.length > 0 && <List size='small' dataSource={supervisors} renderItem={renderSupervisors} />}
          </div>
        </div>
      </Loading>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipSupervisors)
