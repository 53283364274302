import React, { useCallback, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import QuestionCard from './QuestionCard'
import { Popconfirm } from 'antd'
import IconButton from '../../../antd/Buttons/IconButton'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const EvaluationPanel = ({ comment = '', questions = [], name, readOnlyMode, submitted, t, onSubmit }) => {
  const [selectedQuestions, setSelectedQuestions] = useState(questions.map(q => ({ id: q.id, answer: q.answer ?? null, label: q.label })))
  const [localComment, setLocalComment] = useState(comment)

  const isFill = useMemo(() => !selectedQuestions.some(q => q.answer === null), [selectedQuestions])

  const handleAnswerSelected = useCallback((questionId, answer) => {
    const selectedQuestion = selectedQuestions.find(q => q.id === questionId)

    if (selectedQuestion) {
      selectedQuestion.answer = answer
    }

    setSelectedQuestions(selectedQuestions.map(q => q.id === questionId ? selectedQuestion : q))
  }, [setSelectedQuestions])

  return (
    <div className='recruitment-module-wrapper'>
      <div className='info-text'>{t('How was your internship experience with')} {name} {t('within your unit?')}</div>
      <label>{t('Questions')}</label>
      <div className='questions-list'>
        {selectedQuestions.map(q => (<QuestionCard key={q.id} question={q} readOnlyMode={readOnlyMode} onAnswerSelected={handleAnswerSelected} />))}
      </div>
      <div className='comments-wrapper'>
        <label>{t('common.comments')}</label>
        <textarea
          disabled={readOnlyMode}
          value={localComment}
          onChange={e => setLocalComment(e.target.value)}
          placeholder={t("Comments about student's internship")}
        />
      </div>
      <div className='submit-feedback-wrapper'>
        {readOnlyMode && (
          <span className='previously-evaluated'>
            {submitted ? t('Your feedback was recorded, thank you!') : t('This internship has already received feedback')}
          </span>
        )}
        {!readOnlyMode && (
          <Popconfirm
            placement='top'
            okType='danger'
            title={t("You won't be able to add a comment after submitting the evaluation. Do you want to continue?")}
            okText={t('Yes')}
            cancelText={t('Cancel')}
            onConfirm={() => onSubmit({ studentEvaluationAnswers: selectedQuestions, studentEvaluationComment: localComment })}
            overlayClassName='evaluation-popconfirm'
          >
            <IconButton
              disabled={!isFill}
              icon={faCheck}
              text={t('Confirm')}
              type='primary'
            />
          </Popconfirm>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EvaluationPanel)
