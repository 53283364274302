import { faMoon, faStar, faSun, faExclamationTriangle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons'

export const ICONS = {
  moon: faMoon,
  star: faStar,
  sun: faSun,
  plusCircle: faPlusCircle,
  warning: faExclamationTriangle
}

export const getIconByName = iconName => ICONS[iconName]
