import React, { useState } from 'react'

import { getTranslate, getActiveLanguage } from 'react-localize-redux'
import { Button, Card, DatePicker, Form } from 'antd'
import { connect } from 'react-redux'
import { faEdit, faBirthdayCake } from '@fortawesome/pro-solid-svg-icons'
import IconButton from '../../antd/Buttons/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DATE_WITHOUT_TIME, EUROPEAN_DATE, LANGUAGE_TAG_EN } from '../../../utils/constants'
import { API_DATE_FORMAT } from '../../../utils/apiHelper'
import { isMoment } from 'moment'
import { getTimezoneLessMoment } from '../../../utils/momentjs'

const TITLE = 'misc.birthdate'
const EMPTY_MESSAGE = 'student_profile.birthdate.empty_birthdate'

const mapStateToProps = state => ({ t: getTranslate(state.locale), lang: getActiveLanguage(state.locale) })

const BirthdateForm = ({ t, lang, birthdate, form, onSubmit }) => {
  const [isInEditionMode, setIsInEditionMode] = useState(false)

  const { getFieldDecorator } = form

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err && isMoment(values.birthdate)) {
        onSubmit(values.birthdate.format(API_DATE_FORMAT))
      }
    })

    setIsInEditionMode(!isInEditionMode)
  }

  return (
    <div className='inner-margin'>
      <Card
        type='inner'
        title={[<FontAwesomeIcon key='title-icon' icon={faBirthdayCake} />, ' ', t(TITLE)]}
        extra={(<IconButton icon={faEdit} onClick={() => setIsInEditionMode(!isInEditionMode)} />)}
      >
        {!isInEditionMode && (birthdate ? <span> {getTimezoneLessMoment(birthdate).format(EUROPEAN_DATE)} </span> : t(EMPTY_MESSAGE))}
        {isInEditionMode && (
          <Form
            name='birthdateForm'
            onSubmit={handleSubmit}
          >
            <Form.Item>
              {getFieldDecorator('birthdate')(<DatePicker format={lang.code === LANGUAGE_TAG_EN ? DATE_WITHOUT_TIME : EUROPEAN_DATE} />)}
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>{t('Save')}</Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  )
}

export default connect(mapStateToProps)(Form.create()(BirthdateForm))
