import React, { useCallback, useContext, useState } from 'react'

import { Input, Modal, Select } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faEnvelope, faGlobe, faIdBadge, faIdCard, faLocationArrow } from '@fortawesome/pro-solid-svg-icons'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { onError, onSuccess } from '../../utils/apiHelper'
import ErrorMessage from '../../Components/shared/Messages/ErrorMessage'
import { getUser } from '../../reducers/UserReducer'
import { updateInstitutionGroup } from '../../utils/api/institutionGroup'
import { INSTITUTION_GROUP_VALIDATORS, InstitutionGroup } from '../../utils/entities/institutionGroup'

import '../../assets/form.scss'
import { VALIDATION_FIELDS, isValid } from '../../utils/validators'

const DEFAULT_ERROR = { details: {}, title: '', visible: false, style: { borderColor: 'red' }, missingFields: [] }
const DEFAULT_STYLE = { borderColor: 'lightgray' }

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const InstitutionGroupFormModal = ({ children, user, onSave, t }) => {
  const { countries } = useContext(GlobalContext)

  const [selectedInstitutionGroup, setSelectedInstitutionGroup] = useState(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(DEFAULT_ERROR)

  const handleSubmit = useCallback(() => {
    const errors = isValid(selectedInstitutionGroup, INSTITUTION_GROUP_VALIDATORS)

    if (errors.keys.length === 0) {
      updateInstitutionGroup(user, new InstitutionGroup(selectedInstitutionGroup)).then(json => {
        if (json?.data) {
          onSuccess(t('institution_group_form_modal.update.success'))
          setVisible(false)

          if (typeof onSave === 'function') {
            onSave(json.data)
          }
        }
      }).catch(e => {
        onError(t('institution_group_form_modal.update.error'))
      })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [t, user, selectedInstitutionGroup, onSave, onError, onSuccess, setVisible, updateInstitutionGroup])

  const handleSelectedInstitutionGroup = useCallback(ig => {
    setSelectedInstitutionGroup(ig)
    setVisible(true)
  }, [setVisible, setSelectedInstitutionGroup])

  return (
    <>
      {React.cloneElement(children, { onSelectInstitutionGroup: handleSelectedInstitutionGroup })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
        title={t('institution_group_form_modal.title')}
        cancelText={t('modal.cancel_button')}
        okText={t(selectedInstitutionGroup && selectedInstitutionGroup.id === '-1' ? 'modal.ok_button.create' : 'modal.ok_button.save')}
        okButtonProps={{ disabled: selectedInstitutionGroup === null }}
        afterClose={() => {
          setError(DEFAULT_ERROR)
          setSelectedInstitutionGroup(null)
        }}
      >
        {selectedInstitutionGroup !== null && (
          <div className='user-form'>
            {error.visible && (
              <ErrorMessage title={error.title} details={error.details} />
            )}
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdCard} /> {t('institution_group_form_modal.label.name')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.NAME) ? error.style : DEFAULT_STYLE}
                value={selectedInstitutionGroup.name}
                onChange={e => setSelectedInstitutionGroup({ ...selectedInstitutionGroup, name: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdBadge} /> {t('institution_group_form_modal.label.acronym')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.ACRONYM) ? error.style : DEFAULT_STYLE}
                value={selectedInstitutionGroup.acronym}
                onChange={e => setSelectedInstitutionGroup({ ...selectedInstitutionGroup, acronym: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faLocationArrow} /> {t('institution_group_form_modal.label.address')} </label>
              </div>
              <Input
                value={selectedInstitutionGroup.address}
                onChange={e => setSelectedInstitutionGroup({ ...selectedInstitutionGroup, address: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faCity} /> {t('institution_group_form_modal.label.city')}</label>
              </div>
              <Input
                value={selectedInstitutionGroup.city}
                onChange={e => setSelectedInstitutionGroup({ ...selectedInstitutionGroup, city: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faEnvelope} /> {t('institution_group_form_modal.label.zipcode')}</label>
              </div>
              <Input
                value={selectedInstitutionGroup.zipcode}
                onChange={e => setSelectedInstitutionGroup({ ...selectedInstitutionGroup, zipcode: e.target.value })}
              />
            </div>
            <div className='form-item form-select'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faGlobe} /> {t('institution_group_form_modal.label.country')}</label>
                <span> {t('form.label.required')} </span>
              </div>
              <Select
                className={error.missingFields.includes(VALIDATION_FIELDS.COUNTRY) ? 'error-input-border' : ''}
                value={selectedInstitutionGroup.country?.code ?? null}
                onChange={value => setSelectedInstitutionGroup({ ...selectedInstitutionGroup, country: { code: value } })}
              >
                {countries.map(c => {
                  return (
                    <Select.Option key={c.id} value={c.code}>
                      {c.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(InstitutionGroupFormModal)
