import React, { useCallback, useContext, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import FormItem from './FormItem'
import {
  faAt, faBirthdayCake, faBox, faCalendar, faClinicMedical, faEnvelopeOpenText, faFile, faGenderless, faGraduationCap, faIdBadge, faLanguage, faPhone, faSave, faSchool,
  faStethoscope, faUser
} from '@fortawesome/pro-solid-svg-icons'
import { Input, Radio, Select } from 'antd'
import { VALIDATION_FIELDS, isValid } from '../../../utils/validators'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { isObject } from '../../../utils'
import NullableSelect from '../../antd/Selects/NullableSelect'
import { isArray } from 'lodash'
import { EUROPEAN_DATE, ROLE_HOSPITAL_ADMIN, SUPPORTED_LANGUAGE_LOCALES } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'
import MultiPeriodsPicker from '../../antd/Pickers/MultiPeriodsPicker'
import { GENDERS } from '../../../utils/api/student'
import FilesUpload from '../../antd/Files/FilesUpload'
import { INTERNSHIP_REQUEST_VALIDATORS, InternshipRequest } from '../../../utils/entities/internshipRequest'
import moment from 'moment'
import BirthdatePicker from '../../antd/Selects/BirthdatePicker'
import IconButton from '../../antd/Buttons/IconButton'
import { INTERNSHIP_FILE_TYPES } from '../../../utils/entities/internshipRequestFile'

const { COVER_LETTER, RESUME, OTHER } = INTERNSHIP_FILE_TYPES

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const IndividualInternshipRequestForm = ({ item, institutions = [], schools = [], sectors = [], user, onSubmit, t }) => {
  const { sections, sectionToYearsBySection } = useContext(GlobalContext)

  const [data, setData] = useState({ ...item, section: item.sectionToYear ? { id: sections.find(s => s.abbreviation === item.sectionToYear.sectionAbbreviation).id } : null })
  const [files, setFiles] = useState({ [COVER_LETTER]: [], [RESUME]: [], [OTHER]: [] })
  const [error, setError] = useState({ missingFields: [] })

  const availableYears = useMemo(() => {
    return isObject(data.section) && data.section.id && isArray(sectionToYearsBySection[data.section.id])
      ? sectionToYearsBySection[data.section.id]
      : []
  }, [data.section])

  const errorClass = useCallback(error.missingFields.reduce((acc, f) => {
    acc[f] = 'error-input-border'

    return acc
  }, {}), [error])

  const handleSubmit = useCallback(() => {
    const errors = isValid(data, INTERNSHIP_REQUEST_VALIDATORS)

    if (errors.keys.length === 0) {
      data.createdAt = moment()
      const filesAsArray = []

      Object.keys(files).forEach(key => {
        files[key].forEach(f => filesAsArray.push({ file: f, type: key }))
      })

      onSubmit(new InternshipRequest(data), filesAsArray)
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [data, files, error, setError, t])

  const handleUpload = useCallback((file, key) => {
    setFiles({ ...files, [key]: [...files[key], file] })

    return false
  }, [files, setFiles])

  const handleUploadCv = useCallback(file => handleUpload(file, RESUME), [handleUpload])
  const handleUploadMotivationLetter = useCallback(file => handleUpload(file, COVER_LETTER), [handleUpload])
  const handleUploadOther = useCallback(file => handleUpload(file, OTHER), [handleUpload])

  const handleRemove = useCallback((file, key) => {
    setFiles({ ...files, [key]: files[key].filter(f => f.uid !== file.uid) })
  }, [files, setFiles])

  const handleRemoveCv = useCallback(file => handleRemove(file, RESUME), [handleRemove])
  const handleRemoveMotivationLetter = useCallback(file => handleRemove(file, COVER_LETTER), [handleRemove])
  const handleRemoveOther = useCallback(file => handleRemove(file, OTHER), [handleRemove])
  const filterOption = useCallback((input, option) => (option.props.label ?? '').toLowerCase().includes(input.toLowerCase()), [])

  return (
    <>
      <div className='flex-row'>
        <div className='w-50'>
          <h3> {t('indidivual_internship_request_from.titles.internship')} </h3>
          <FormItem icon={faSchool} label='form.label.school' required>
            <Select
              className={errorClass[VALIDATION_FIELDS.SCHOOL]}
              style={{ width: '100%' }}
              value={data.school?.id ?? null}
              onChange={value => setData({ ...data, school: { id: value } })}
              filterOption={filterOption}
              showSearch
            >
              {schools.map(s => <Select.Option key={s.id} value={s.id} label={s.name}> {s.name} </Select.Option>)}
            </Select>
          </FormItem>
          <FormItem icon={faBox} label='form.label.request_origin'>
            <Input value={t('request_origin.' + (data.requestOrigin ?? '').toLowerCase())} disabled />
          </FormItem>
          <div className='flex-row w-100'>
            <FormItem className='w-50 pdr-4' icon={faGraduationCap} label='form.label.section' required>
              <Select
                className={errorClass[VALIDATION_FIELDS.SECTION]}
                style={{ width: '100%' }}
                value={data.section?.id ?? null}
                onChange={value => setData({ ...data, section: { id: value }, sectionToYear: null })}
                filterOption={filterOption}
                showSearch
              >
                {sections.map(s => <Select.Option key={s.id} value={s.id} title={s.name} label={s.abbreviation}> {s.abbreviation} </Select.Option>)}
              </Select>
            </FormItem>
            <FormItem className='w-50 pdl-4' icon={faSchool} label='form.label.year' required>
              <NullableSelect
                className={errorClass[VALIDATION_FIELDS.SECTION_TO_YEAR]}
                style={{ width: '100%' }}
                value={data.sectionToYear?.id ?? null}
                onChange={value => setData({ ...data, sectionToYear: { id: value } })}
                disabled={!data.section?.id}
              >
                {availableYears.map(sty => <Select.Option key={sty.id} value={sty.id} label={sty.schoolYearName}> {sty.schoolYearName} </Select.Option>)}
              </NullableSelect>
            </FormItem>
          </div>
          <FormItem icon={faClinicMedical} label='form.label.place' required>
            <Select
              className={errorClass[VALIDATION_FIELDS.INSTITUTION]}
              style={{ width: '100%' }}
              value={data.institution?.id ?? null}
              onChange={value => setData({ ...data, institution: { id: value } })}
              disabled={user.roles.includes(ROLE_HOSPITAL_ADMIN)}
              filterOption={filterOption}
              showSearch
            >
              {institutions.map(i => <Select.Option key={i.id} value={i.id} title={i.name} label={i.acronym ?? t('form.label.without_value')}> {i.acronym ?? t('form.label.without_value')} </Select.Option>)}
            </Select>
          </FormItem>
          <FormItem icon={faStethoscope} label='form.label.sector' required>
            <Select
              className={errorClass[VALIDATION_FIELDS.SECTOR]}
              style={{ width: '100%' }}
              value={data.sector?.id ?? null}
              onChange={value => setData({ ...data, sector: { id: value } })}
              filterOption={filterOption}
              showSearch
            >
              {sectors.map(s => <Select.Option key={s.id} value={s.id} label={s.name}> {s.name} </Select.Option>)}
            </Select>
          </FormItem>
          <FormItem icon={faCalendar} label='form.label.periods' required>
            <MultiPeriodsPicker
              className={errorClass[VALIDATION_FIELDS.REQUEST_PERIODS]}
              periods={data.requestPeriods}
              onChange={requestPeriods => setData({ ...data, requestPeriods })}
            />
          </FormItem>
          <FormItem icon={faEnvelopeOpenText} label='form.label.comment'>
            <Input.TextArea
              maxLength={500}
              rows={4}
              value={data.comment}
              onChange={e => setData({ ...data, comment: e.target.value })}
            />
          </FormItem>
          {data.id > 0 && <FormItem icon={faIdBadge} label='form.label.id'> <Input value={data.id} disabled /> </FormItem>}
        </div>
        <div className='w-50 ml-20'>
          <h3> {t('indidivual_internship_request_from.titles.student')} </h3>
          <FormItem icon={faGenderless} label='form.label.gender'>
            <Radio.Group value={data.gender} buttonStyle='solid' onChange={e => setData({ ...data, gender: e.target.value })}>
              {Object.keys(GENDERS).map(k => (
                <Radio.Button key={k} value={GENDERS[k]}> {t('genders.' + k.toLowerCase())} </Radio.Button>
              ))}
            </Radio.Group>
          </FormItem>
          <div className='flex-row w-100'>
            <FormItem className='w-50 pdr-4' icon={faUser} label='form.label.firstname' required>
              <Input
                className={errorClass[VALIDATION_FIELDS.FIRSTNAME]}
                value={data.firstName}
                onChange={e => setData({ ...data, firstName: e.target.value })}
              />
            </FormItem>
            <FormItem className='w-50 pdl-4' icon={faUser} label='form.label.lastname' required>
              <Input
                className={errorClass[VALIDATION_FIELDS.LASTNAME]}
                value={data.lastName}
                onChange={e => setData({ ...data, lastName: e.target.value })}
              />
            </FormItem>
          </div>
          <FormItem icon={faAt} label='form.label.email' required>
            <Input
              className={errorClass[VALIDATION_FIELDS.EMAIL]}
              value={data.email}
              onChange={e => setData({ ...data, email: e.target.value })}
            />
          </FormItem>
          <div className='flex-row w-100'>
            <FormItem className='w-50 pdr-4' icon={faLanguage} label='form.label.language' info={t('indidivual_internship_request_from.info.language')} required>
              <Select
                className={errorClass[VALIDATION_FIELDS.LANGUAGE]}
                style={{ width: '100%' }}
                value={data.languageLocale.languageTag}
                onChange={value => setData({ ...data, languageLocale: { languageTag: value } })}
              >
                {SUPPORTED_LANGUAGE_LOCALES.map(l => <Select.Option key={l} value={l}> {t('languages.' + l)} </Select.Option>)}
              </Select>
            </FormItem>
            <FormItem className='w-50 pdl-4' icon={faPhone} label='form.label.phone_number'>
              <Input value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} />
            </FormItem>
          </div>
          <FormItem icon={faBirthdayCake} label='form.label.birthdate'>
            <BirthdatePicker value={data.birthday?.format(EUROPEAN_DATE)} onChange={birthday => setData({ ...data, birthday })} />
          </FormItem>
          {data.id === -1 && (
            <>
              <FormItem icon={faFile} label='form.label.cv'>
                <FilesUpload
                  files={files[RESUME]}
                  onUpload={handleUploadCv}
                  onRemove={handleRemoveCv}
                />
              </FormItem>
              <FormItem icon={faFile} label='form.label.motivation_letter'>
                <FilesUpload
                  files={files[COVER_LETTER]}
                  onUpload={handleUploadMotivationLetter}
                  onRemove={handleRemoveMotivationLetter}
                />
              </FormItem>
              <FormItem icon={faFile} label='form.label.other_files'>
                <FilesUpload
                  files={files[OTHER]}
                  onUpload={handleUploadOther}
                  onRemove={handleRemoveOther}
                />
              </FormItem>
            </>
          )}
        </div>
      </div>
      <div className='flex-row w-100 j-ctn-e'>
        <IconButton type='primary' icon={faSave} onClick={handleSubmit} text={t('actions.save')} />
      </div>
    </>
  )
}

export default connect(mapStateToProps)(IndividualInternshipRequestForm)
