import React, { useMemo } from 'react'

import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const TableFooter = ({ className, deprecatedPage, metadata: { last, totalPages, perPage, total, pageSize, page }, style, onPageChange, t }) => {
  const entriesText = useMemo(() => {
    const currentPage = deprecatedPage ?? page
    const firstEntry = (currentPage - 1) * perPage + 1
    let lastEntry = currentPage * perPage

    if (lastEntry > total) {
      lastEntry = total
    }

    const text = total > 1
      ? t('table_footer.part_1') + firstEntry + t('table_footer.part_2') + lastEntry + t('table_footer.part_3') + total + t('table_footer.part_4')
      : t('Showing of the only entry')

    return text
  }, [t, deprecatedPage, page, perPage, pageSize, total])

  return (
    <div className={`flex-row footer hidden-sigleton-data ${className}`} style={style ?? {}}>
      <div>{entriesText}</div>
      <div className='flex-fill' />
      <div className='flex-row'>
        <Tooltip placement='top' title={t('First page')}>
          <Button
            type='default'
            size='small'
            disabled={last === 1 || page === 1}
            onClick={() => onPageChange(1)}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Previous page')}>
          <Button
            type='default'
            size='small'
            disabled={page === 1}
            onClick={() => onPageChange(page - 1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <div className='page-index'>
          {page}/{totalPages}
        </div>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Next page')}>
          <Button
            type='default'
            size='small'
            disabled={totalPages <= 1 || page === totalPages}
            onClick={() => onPageChange(page + 1)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Last page')}>
          <Button
            type='default'
            size='small'
            disabled={totalPages <= 1 || page === totalPages}
            onClick={() => onPageChange(totalPages)}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TableFooter)
