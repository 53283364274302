import React, { useCallback, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { ACTIONS } from '../../Components/shared/DataTable/utils/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClinicMedical, faComment, faCompass, faIdCard } from '@fortawesome/pro-solid-svg-icons'
import ErrorMessage from '../../Components/shared/Messages/ErrorMessage'
import { VALIDATION_FIELDS, isValid } from '../../utils/validators'
import { Input, Modal, Select } from 'antd'
import { onError, onSuccess } from '../../utils/apiHelper'
import { SECTOR_VALIDATORS, Sector } from '../../utils/entities/sector'
import { createSector, updateSector } from '../../utils/api/sector'

const DEFAULT_ERROR = { details: {}, title: '', visible: false, style: { borderColor: 'red' }, missingFields: [] }
const DEFAULT_STYLE = { borderColor: 'lightgray' }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SectorFormModal = ({ children, institutions = [], user, useSelector, onTriggerAction, onSave, t }) => {
  const [error, setError] = useState(DEFAULT_ERROR)
  const [selectedSector, setSelectedSector] = useState(null)
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(() => {
    const errors = isValid(selectedSector, SECTOR_VALIDATORS)

    if (errors.keys.length === 0) {
      const parameters = new Sector(selectedSector).toRequestBody()
      const promise = selectedSector.id === -1 ? createSector(user, parameters) : updateSector(user, parameters)
      const action = selectedSector.id === -1 ? ACTIONS.CREATE : ACTIONS.EDIT

      promise.then(json => {
        if (json?.data) {
          onSuccess(t(`sector_form_modal.${action}.success`))
          setVisible(false)

          if (typeof onSave === 'function') {
            onSave(json.data, action)
          }
        }
      }).catch(e => {
        onError(t(`sector_form_modal.${action}.error`))
      })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [t, user, selectedSector, onSave, onError, onSuccess, setVisible, createSector, updateSector])

  const handleActionTrigger = useCallback((entity, action) => {
    if (action === ACTIONS.CREATE_SECTOR || action === ACTIONS.EDIT_SECTOR) {
      setSelectedSector(entity)
      setVisible(true)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [setSelectedSector, setSelectedSector, onTriggerAction])

  return (
    <>
      {React.cloneElement(children, { onTriggerAction: handleActionTrigger })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
        title={t('sector_form_modal.title')}
        cancelText={t('modal.cancel_button')}
        okText={t(selectedSector && selectedSector.id === -1 ? 'modal.ok_button.create' : 'modal.ok_button.save')}
        okButtonProps={{ disabled: selectedSector === null }}
        afterClose={() => {
          setError(DEFAULT_ERROR)
          setSelectedSector(null)
        }}
      >
        {selectedSector !== null && (
          <div className='user-form'>
            {error.visible && (
              <ErrorMessage title={error.title} details={error.details} />
            )}
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdCard} /> {t('sector_form_modal.label.name')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.NAME) ? error.style : DEFAULT_STYLE}
                value={selectedSector.name}
                onChange={e => setSelectedSector({ ...selectedSector, name: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faComment} /> {t('sector_form_modal.label.comment')} </label>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.ACRONYM) ? error.style : DEFAULT_STYLE}
                value={selectedSector.comment}
                onChange={e => setSelectedSector({ ...selectedSector, comment: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faCompass} /> {t('sector_form_modal.label.emplacement')} </label>
              </div>
              <Input
                value={selectedSector.emplacement}
                onChange={e => setSelectedSector({ ...selectedSector, emplacement: e.target.value })}
              />
            </div>
            <div className='form-item form-select'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faClinicMedical} /> {t('user_form_modal.label.institution')}</label>
                <span> {t('form.label.required')} </span>
              </div>
              <Select
                className={error.missingFields.includes(VALIDATION_FIELDS.INSTITUTIONS) ? 'error-input-border' : ''}
                value={selectedSector.institution?.id ?? null}
                onChange={value => setSelectedSector({ ...selectedSector, institution: { id: value } })}
                disabled={!useSelector}
              >
                {institutions.map(i => {
                  return (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(SectorFormModal)
