import React, { useCallback, useState } from 'react'

import { connect } from 'react-redux'
import { getLanguages, getTranslate } from 'react-localize-redux'
import { Input, Modal, Select } from 'antd'
import { isValid, VALIDATION_FIELDS } from '../../utils/validators'
import ErrorMessage from '../../Components/shared/Messages/ErrorMessage'
import { EXTERNAL_CONTACT_VALIDATORS, ExternalContact } from '../../utils/entities/ExternalContact'
import { addExternalContact, updateExternalContact } from '../../utils/api/externalContact'
import { onError, onSuccess } from '../../utils/apiHelper'
import { getUser } from '../../reducers/UserReducer'
import { ACTIONS } from '../../Components/shared/DataTable/utils/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faIdCard, faLanguage } from '@fortawesome/pro-solid-svg-icons'

import '../../assets/form.scss'
import '../../assets/school-permission-form.scss'

const DEFAULT_ERROR = { details: {}, title: '', visible: false, style: { borderColor: 'red' }, missingFields: [] }
const DEFAULT_STYLE = { borderColor: 'lightgray' }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    languages: getLanguages(state.locale)
  }
}

const ExternalContactFormModal = ({ children, user, useSelector, onTriggerAction, onSave, t, languages }) => {
  const [error, setError] = useState(DEFAULT_ERROR)
  const [item, setItem] = useState(null)
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(() => {
    const errors = isValid(item, EXTERNAL_CONTACT_VALIDATORS)

    if (errors.keys.length === 0) {
      const parameters = new ExternalContact(item)
      const promise = item.id === -1 ? addExternalContact(user, parameters) : updateExternalContact(user, parameters)
      const action = item.id === -1 ? ACTIONS.CREATE_EC : ACTIONS.EDIT_EC

      promise.then(json => {
        if (json?.data) {
          onSuccess(t(`external_contact_form_modal.${action}.success`))
          setVisible(false)

          if (typeof onSave === 'function') {
            onSave(json.data, action)
          }
        }
      }).catch(e => {
        onError(t(`external_contact_form_modal.${action}.error`))
      })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [t, user, item, onSave, onError, onSuccess, setVisible, addExternalContact, updateExternalContact])

  const handleActionTrigger = useCallback((entity, action) => {
    if (action === ACTIONS.CREATE_EC || action === ACTIONS.EDIT_EC) {
      setItem({ ...entity, institution: user.institutions[0] })
      setVisible(true)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [setItem, onTriggerAction])

  return (
    <>
      {React.cloneElement(children, { onTriggerAction: handleActionTrigger })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
        title={t(item && item.id === -1 ? 'Add a new external contact' : 'Update this external contact')}
        cancelText={t('modal.cancel_button')}
        okText={t(item && item.id === -1 ? 'modal.ok_button.create' : 'modal.ok_button.save')}
        okButtonProps={{ disabled: item === null }}
        afterClose={() => {
          setError(DEFAULT_ERROR)
          setItem(null)
        }}
      >
        {item !== null && (
          <div className='user-form'>
            {error.visible && (
              <ErrorMessage title={error.title} details={error.details} />
            )}
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdCard} /> {t('Lastname')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.LASTNAME) ? error.style : DEFAULT_STYLE}
                value={item.lastname ?? null}
                onChange={e => setItem({ ...item, lastname: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdCard} /> {t('Firstname')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.FIRSTNAME) ? error.style : DEFAULT_STYLE}
                value={item.firstname ?? null}
                onChange={e => setItem({ ...item, firstname: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faAt} /> {t('Email')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.EMAIL) ? error.style : DEFAULT_STYLE}
                value={item.email ?? null}
                onChange={e => setItem({ ...item, email: e.target.value })}
              />
            </div>
            <div className='form-item form-select'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faLanguage} /> {t('Language')}</label>
                <span> {t('form.label.required')} </span>
              </div>
              <Select
                value={item.languageLocale?.languageTag ?? null}
                onChange={value => setItem({ ...item, languageLocale: { languageTag: value } })}
                disabled={!useSelector}
              >
                {languages.map(l => {
                  return (
                    <Select.Option key={l.code} value={l.code}>
                      {t(l.name)}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(ExternalContactFormModal)
