import React, { useContext, useMemo, useRef, useState } from 'react'

import InformationDisplay, { ELEMENT_TYPES } from '../shared/InformationDisplay'
import Linkify from 'react-linkify'
import moment from 'moment'
import { Collapse, Drawer } from 'antd'
import {
  faBookmark, faCar, faRocket, faInfoCircle, faComment, faFileAlt, faCalendarPlus, faPlus, faDownload, faPaperclip, faRedo
} from '@fortawesome/pro-solid-svg-icons'
import EyeBan from '../shared/EyeBan'
import { CARPOOL_OPTIN_TEXTS } from '../../utils/entities/optin'
import CarpoolingDrawer from './CarpoolingDrawer'
import UserCarpoolModal from '../shared/UserCarpoolModal'
import { faSmile } from '@fortawesome/pro-regular-svg-icons'
import StudentFeedback from './StudentFeedback'
import { EvaluationsContext } from '../../Providers/EvaluationsProvider'
import { SCHOOL_OPTIONS, internshipStates } from '../../utils/constants'
import { GlobalContext } from '../../Providers/GlobalProvider'
import ResizeDrawer from '../../HOC/ResizeDrawer'
import { SCHEDULE_VALIDATED } from '../../utils/entities/internship'
import { isObject, isArray } from 'lodash'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import { ACCEPTED_DOCUMENT_META, TYPES } from '../../utils/entities/internshipDocument'
import { InternshipDocumentsDrawerContext } from '../../Providers/Drawer/InternshipDocumentsDrawer'
import { onError, onSuccess } from '../../utils/apiHelper'
import { uploadInternshipConvention } from '../../utils/api/internship'
import { downloadInternshipDocument } from '../../utils/api/internshipDocument'
import IconButton from '../antd/Buttons/IconButton'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import SectorIdCardContainer from '../shared/IdCards/SectorIdCardContainer'
import TextTitle from './TextTitle'
import InternshipContacts from './InternshipContacts'
import InternshipFiles from './InternshipFiles'

const { Panel } = Collapse

const { INTERNSHIPS_CARPOOLING } = SCHOOL_OPTIONS

const mapStateToProps = state => ({
  activeLanguage: getActiveLanguage(state.locale).code,
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipItem = ({ activeLanguage, internship, schoolOptions, studentFreeFields, user, onInternshipSelect, onUpdate, t }) => {
  const { attractivityStateByInstitution, setInternshipsToEvaluate, internshipsToEvaluate } = useContext(EvaluationsContext)
  const { optins } = useContext(GlobalContext)
  const { setSelectedInternship } = useContext(InternshipDocumentsDrawerContext)

  const [uploading, setUploading] = useState(false)
  const [visibleCarpoolDrawer, setVisibleCarpoolDrawer] = useState(false)
  const [carpoolModalVisible, setCarpoolModalVisible] = useState(false)
  const [internshipFeedback, setInternshipFeedback] = useState(null)

  const conventionRef = useRef(null)
  const isActsMandatory = useMemo(() => internship.section && internship.section.actsMandatory, [internship.section])
  const validationDocument = useMemo(() => {
    let document = null

    if (internship.studentValidationDocumentId) {
      document = internship.documents.find(item => item.id === internship.studentValidationDocumentId)
    }

    return document
  }, [internship.studentValidationDocumentId, internship.documents])
  const convention = useMemo(() => {
    let convention = null

    if (internship && isArray(internship.documents)) {
      convention = internship.documents.find(d => d.type === TYPES.CONVENTION) ?? null
    }

    return convention
  }, [internship.documents])
  const conventionName = useMemo(() => {
    let conventionName = t('None')

    if (convention) {
      const date = moment(convention.updatedAt)

      conventionName = `${convention.originalName} - ${date.format('DD/MM/YYYY HH:mm')}`
    }

    return conventionName
  }, [convention])
  const studentUseCarpool = useMemo(() => CARPOOL_OPTIN_TEXTS.some(t => optins.some(o => o.text === t))?.accepted ?? false, [optins])
  const schoolUseCarpool = useMemo(() => schoolOptions[internship.school.id] && schoolOptions[internship.school.id][INTERNSHIPS_CARPOOLING], [schoolOptions, internship.school])

  const handleDocumentUpload = e => {
    const file = e.target.files[0]

    if (file && ACCEPTED_DOCUMENT_META.includes(file.type)) {
      setUploading(true)

      uploadInternshipConvention(internship, file, user).then(json => {
        if (json.data) {
          onSuccess(t('Convention de stage envoyée !'))
          onUpdate({ ...internship, documents: [...internship.documents.filter(d => d.type !== TYPES.CONVENTION), { ...json.data, updatedAt: json.data.updatedAt.date }] })
        }
      }).finally(() => setUploading(false))
    } else {
      onError(t('Only PDF, Word, Excel and CSV file types are supported.'))
    }
  }

  const renderInternshipConvention = () => {
    const config = convention === null ? {
      icon: faPlus,
      text: 'common.add',
      handleClick: () => conventionRef.current.click()
    } : {
      icon: faDownload,
      text: 'common.download',
      handleClick: () => downloadInternshipDocument(user, convention)
    }

    return (
      <div className='flex-row'>
        <IconButton icon={config.icon} loading={uploading} size='small' text={t(config.text)} onClick={config.handleClick} />
        {convention && <div className='h-spacing' />}
        {convention && convention.locked && <div>{t('Your internship convention has been accepted.')}</div>}
        {convention && !convention.locked && (
          <IconButton icon={faRedo} loading={uploading} size='small' text={t('common.update')} type='primary' onClick={() => conventionRef.current.click()} />
        )}
      </div>
    )
  }

  const renderStudentFreeFields = (listFreeFields, institutionId) => {
    const studentFreeFields = listFreeFields[institutionId] ?? []

    return (
      <div>
        {studentFreeFields.length === 0 && t('common.none')}
        {studentFreeFields.map((sff, index) => {
          const typeLabel = sff.fieldType.fieldTypelabels.find(ftl => ftl.language.languageLocales.find(llc => llc.languageTag === activeLanguage))
          const label = isObject(typeLabel) ? typeLabel.label : t('common.without_label')

          return (
            <div className='flex-column' key={label + index}>
              <span style={{ display: 'inlineBlock' }}>
                <span style={{ paddingRight: '10px' }}> {label}: </span>
                {sff.fieldType.isEncryption && <EyeBan value={sff.valueDecrypt} hidden={sff.fieldType.isEncryption} />}
                {!sff.fieldType.isEncryption && (sff.fieldValue)}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  const renderComments = () => {
    const sectionComment = internship.section !== null && internship.section.comment !== null ? internship.section.comment : ''

    return (
      <div className='flex-column'>
        {!sectionComment && t('common.none')}
        {sectionComment && <div>{sectionComment}</div>}
      </div>
    )
  }

  const renderInstitutionDescription = () => {
    return (
      <div>
        {!internship.institution.description && t('None')}
        {internship.institution.description && (
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
                {t('Click here')}.<br />
              </a>
            )}
          >
            {internship.institution.description}
          </Linkify>
        )}
      </div>
    )
  }

  const internshipInfo = useMemo(() => {
    let infos = []

    if (isObject(internship)) {
      infos = [
        { icon: faComment, title: t('common.comments'), caption: renderComments() },
        { icon: faInfoCircle, title: t('internship_item.institution_description'), caption: renderInstitutionDescription() },
        { icon: faBookmark, title: t('internship_item.institution_free_fields'), caption: renderStudentFreeFields(studentFreeFields, internship.institution.id) },
        { type: ELEMENT_TYPES.DIVIDER },
        {
          type: ELEMENT_TYPES.COLLAPSE,
          caption: (
            <Collapse bordered={false}>
              <Panel header={<TextTitle title={t('common.sector.dp')} text={internship.sector?.name ?? t('common.none')} key='1' />}>
                <SectorIdCardContainer sectorId={internship.sector?.id} />
              </Panel>
            </Collapse>
          )
        },
        {
          type: ELEMENT_TYPES.COLLAPSE,
          caption: (
            (
              <Collapse bordered={false}>
                <Panel header={<TextTitle title={t('common.contact_persons')} key='1' />}>
                  <InternshipContacts internship={internship} />
                </Panel>
              </Collapse>
            )
          )
        },
        {
          type: ELEMENT_TYPES.COLLAPSE,
          caption: (
            (
              <Collapse bordered={false}>
                <Panel header={<TextTitle title={t('common.files')} key='1' />}>
                  <InternshipFiles internship={internship} />
                </Panel>
              </Collapse>
            )
          )
        },
        { type: ELEMENT_TYPES.SPACE },
        {
          icon: faFileAlt,
          title: t('internship_item.convention'),
          caption: (
            <>
              <div style={{ color: 'grey', fontSize: '90%', padding: '3px', fontStyle: 'italic' }}> {conventionName} </div>
              <div>{renderInternshipConvention()}</div>
            </>
          )
        },
        {
          icon: faCalendarPlus,
          title: t(isActsMandatory ? 'internship_item.schedule_and_acts' : 'common.schedule'),
          caption: (
            <IconButton
              icon={faCalendarPlus}
              size='small'
              text={t('internship_item.schedule_and_acts_button')}
              type='primary'
              onClick={() => onInternshipSelect(internship.id)}
            />
          )
        },
        {
          icon: faFileAlt,
          title: t('internship_item.internship_files'),
          caption: (
            <IconButton
              icon={faPaperclip}
              size='small'
              text={t('internship_item.internship_files_button')}
              type='primary'
              onClick={() => setSelectedInternship({ data: internship, readOnly: validationDocument !== null || internship.state === SCHEDULE_VALIDATED })}
            />
          )
        }
      ]

      if (internship.state !== SCHEDULE_VALIDATED && schoolUseCarpool && getTimezoneLessMoment(internship.endDate) > moment() && studentUseCarpool) {
        infos.push({
          icon: faCar,
          title: t('internship_item.car_pooling'),
          caption: (
            <IconButton
              icon={faCalendarPlus}
              size='small'
              text={t('internship_item.car_pooling_button')}
              type='primary'
              onClick={() => studentUseCarpool ? setVisibleCarpoolDrawer(true) : setCarpoolModalVisible(true)}
            />
          )
        })
      }

      if (internship.state === internshipStates.SCHEDULE_VALIDATED && isObject(internship.institution) && attractivityStateByInstitution[internship.institution.id]) {
        infos.push({
          icon: faRocket,
          title: t('misc.feedback'),
          caption: (
            <IconButton
              size='small'
              type='primary'
              onClick={() => setInternshipFeedback(internship)}
              text={t('Giving feedback')}
              icon={faSmile}
            />
          )
        })
      }
    }

    return infos
  }, [renderComments, renderInternshipConvention, renderStudentFreeFields, internship, studentFreeFields, t])

  return (
    <div>
      <div>
        <InformationDisplay information={internshipInfo} />
        <input type='file' ref={conventionRef} onChange={handleDocumentUpload} accept={ACCEPTED_DOCUMENT_META.join(',')} style={{ display: 'none' }} />
      </div>
      <ResizeDrawer visible={internshipFeedback !== null}>
        <Drawer
          title={t('common.internship_feedback')}
          onClose={() => setInternshipFeedback(null)}
        >
          <StudentFeedback
            internship={internshipFeedback}
            onSave={() => setInternshipsToEvaluate(internshipsToEvaluate.filter(i => i.id !== internship.id))}
          />
        </Drawer>
      </ResizeDrawer>
      <CarpoolingDrawer
        internship={internship}
        visible={visibleCarpoolDrawer}
        onClose={() => setVisibleCarpoolDrawer(false)}
        width='640px'
      />
      <UserCarpoolModal
        visible={carpoolModalVisible}
        onCancel={() => setCarpoolModalVisible(false)}
        onSubmit={error => {
          setVisibleCarpoolDrawer(!error)
          setCarpoolModalVisible(false)
        }}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipItem)
