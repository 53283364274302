import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

export const getSectorContactPersons = (user, { sector }) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/contact-persons`,
    GET,
    null,
    user
  )
}

export const getSectionContactPersons = (user, { section }) => {
  return requestWithPromise(
    `/api/school-sections/${section.id}/contact-persons`,
    GET,
    null,
    user
  )
}
