import React, { useState, useEffect } from 'react'

import StandardTableBody from './StandardTableBody'
import StandardTableHeader from './StandardTableHeader'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import IconButton from '../../antd/Buttons/IconButton'

import './assets/standard-table.scss'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const mapDispatchToProps = dispatch => ({})

const ListTable = props => {
  const [actions, setActions] = useState(['delete'])
  const [newData, setNewData] = useState(null)

  useEffect(() => {
    if (props.actions) {
      setActions(props.actions)
    }
  }, [props.actions])

  const handleDataAdd = () => {
    setNewData(props.dataFactory())
  }

  const handleDataSave = data => {
    if (!data.id) {
      props.onDataSave(data, () => setNewData(null))
    } else {
      props.onDataSave(data)
    }
  }

  const handleDataDelete = data => {
    if (!data.id) {
      setNewData(null)

      return
    }

    props.onDataDelete(data)
  }

  const renderInnerContaint = () => {
    if (props.loading) {
      return (
        <div className='loading-icon black' />
      )
    }

    return [
      renderTableIfData(),
      <div key='adding button' className='adding-button-div'>
        <IconButton
          text={props.t('common.add')}
          icon={faPlus}
          disabled={props.disableAddButton || !props.dataFactory}
          onClick={handleDataAdd}
        />
      </div>
    ]
  }

  const renderTableIfData = () => {
    if (props.data && props.data.length === 0 && newData === null) {
      return (
        <div key='empty_table_message' className='empty-list-table-message'>
          {props.t(props.noDataText)}
        </div>
      )
    }

    return (
      <div key='table' className='table-div'>
        <table className='standard-line-table'>
          <StandardTableHeader
            columns={props.columns}
            actions={actions}
          />
          <StandardTableBody
            columns={props.columns}
            data={props.data}
            newData={newData}
            onSave={handleDataSave}
            onDelete={handleDataDelete}
          />
        </table>
      </div>
    )
  }

  return (
    <div className='list-table-div'>
      {renderInnerContaint()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTable)
