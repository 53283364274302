import React, { useMemo } from 'react'

import { DatePicker } from 'antd'
import { EUROPEAN_DATE } from '../../../utils/constants'
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import IconButton from '../Buttons/IconButton'

const { RangePicker } = DatePicker

const MultiPeriodsPicker = ({ className, periods = [], onChange }) => {
  const localClassName = useMemo(() => (className ?? '') + ' flex-col bdr-1 bdr-rds-4 pd-16', [className])

  const localPeriods = useMemo(() => periods.map(p => [p.startDate, p.endDate]), [periods])

  return (
    <div className={localClassName}>
      {localPeriods.map((dates, index) => (
        <div key={'picker_' + index} className='flex-row mb-4 aln-itm-ctr'>
          <RangePicker
            format={EUROPEAN_DATE}
            className='w-100'
            value={dates}
            onChange={dates => onChange(periods.map((p, i) => i === index ? { ...p, startDate: dates[0], endDate: dates[1] } : p))}
          />
          <IconButton className='ml-4' icon={faTrash} size='small' type='danger' onClick={() => onChange(periods.filter((d, i) => i !== index))} />
        </div>
      ))}
      <IconButton className='mt-4' icon={faPlus} onClick={() => onChange([...periods, { startDate: moment(), endDate: moment() }])} />
    </div>
  )
}

export default MultiPeriodsPicker
