import React, { useContext, useEffect, useMemo, useState } from 'react'

import { connect } from 'react-redux'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getUser } from '../../reducers/UserReducer'
import { EvaluationsContext } from '../../Providers/EvaluationsProvider'
import Loading from '../shared/Loading'
import { isObject } from '../../utils'
import { faFrown, faMeh, faSmile } from '@fortawesome/pro-regular-svg-icons'
import { Button, Popconfirm } from 'antd'
import { saveEvaluationByStudent } from '../../utils/api/evaluation'
import { onError, onSuccess } from '../../utils/apiHelper'

import '../../assets/recruitment-module.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    activeLanguage: getActiveLanguage(state.locale).code
  }
}

const NEGATIVE_ANSWER = 'RED_FACE'
const NEUTRAL_ANSWER = 'ORANGE_FACE'
const POSITIVE_ANSWER = 'GREEN_FACE'

const StudentFeedback = ({ activeLanguage, internship, user, onSave, t }) => {
  const { getQuestions, getEvaluations, deleteEvaluations } = useContext(EvaluationsContext)

  const [loading, setLoading] = useState(true)
  const [parsedQuestions, setParsedQuestions] = useState([])
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const [comment, setComment] = useState('')
  const [submitError, setSubmitError] = useState(false)
  const [displayConfirmationMessage, setDisplayConfirmationMessage] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [studentAccessToken, setStudentAccessToken] = useState('')

  const language = useMemo(() => activeLanguage.split('_')[0], [activeLanguage])

  useEffect(() => {
    if (isObject(internship)) {
      setLoading(true)

      getQuestions(internship.institution).then(parseQuestions)
    }
  }, [internship])

  const parseQuestions = questions => {
    const institutionQuestionTypes = []

    questions.forEach(item => {
      if (item.institution !== null) {
        institutionQuestionTypes.push(item.questionType)
      }
    })

    const parsedQuestions = questions.filter(item =>
      item.institution !== null ||
      !institutionQuestionTypes.includes(item.questionType)
    )
      .map(item => {
        const translationText = {}

        item.questions.forEach(questionText => {
          translationText[questionText.language] = questionText.value
        })

        return {
          id: item.id,
          questionType: item.questionType,
          isDefault: item.isDefault,
          texts: translationText,
          answer: null,
          invalid: false
        }
      })

    setParsedQuestions(parsedQuestions)
    loadEvaluation(parsedQuestions)
  }

  const loadEvaluation = (parsedQuestions) => {
    getEvaluations(internship).then(data => {
      if (data) {
        setStudentAccessToken(data.studentAccessToken)

        if (data.institutionEvaluationComment && data.institutionEvaluationComment.length) {
          setComment(data.institutionEvaluationComment)
        }

        if (data.institutionEvaluationAnswers && data.institutionEvaluationAnswers.length) {
          const questionsUpdatedAnswers = parsedQuestions.map(question => {
            const answerForQuestion = data.institutionEvaluationAnswers.find(item => item.question.questionType === question.questionType)
            if (answerForQuestion) {
              question.answer = answerForQuestion.answer
            }

            return question
          })

          setParsedQuestions(questionsUpdatedAnswers)
        }

        if (data.didStudentEvaluate) {
          setReadOnlyMode(true)
        }
      }

      setLoading(false)
    })
  }

  const handleAnswerSelection = (questionId, answer) => {
    const questionsUpdatedAnswers = parsedQuestions.map(question => {
      if (question.id === questionId) {
        question.answer = answer
        question.invalid = false
      }

      return question
    })

    setParsedQuestions(questionsUpdatedAnswers)

    if (submitError) {
      validateAnswers()
    }
  }

  const validateAnswers = () => {
    let isValid = true

    const checkedQuestions = parsedQuestions.map(question => {
      if (!question.answer) {
        question.invalid = true
        isValid = false
      }

      return question
    })

    if (!isValid) {
      setParsedQuestions(checkedQuestions)
      setSubmitError(true)
    } else if (submitError) {
      setSubmitError(false)
    }

    return isValid
  }

  const submitFeedback = () => {
    if (!validateAnswers()) {
      return
    }

    const dataToSend = {
      internshipId: internship.id,
      answers: parsedQuestions.map(item => ({ questionId: item.id, questionType: item.questionType, answer: item.answer })),
      comment: comment.trim().length ? comment : null,
      studentAccessToken: studentAccessToken
    }

    setSubmitting(true)

    saveEvaluationByStudent(user, dataToSend).then(data => {
      if (data) {
        onSuccess(t('The evaluation has been sucessfully created'))
        deleteEvaluations(internship)
        setSubmitting(false)
        setReadOnlyMode(true)
        setDisplayConfirmationMessage(true)

        if (typeof onSave === 'function') {
          onSave()
        }
      } else {
        onError(t('An error has occurred during the evaluation creation'))
      }
    })
  }

  return (
    <>
      {!loading && (
        <div className='recruitment-module-wrapper'>
          <div className='info-text'>{t('student.feedback')} {internship?.sector.name} ?</div>
          <label>{t('Questions')}</label>
          <div className='questions-list'>
            {parsedQuestions.map(item => (
              <div key={item.id} className={`question-item ${item.invalid ? 'invalid' : ''}`}>
                <div className='question-text'>{item.texts[language]}</div>
                <div className='question-options'>
                  <button
                    disabled={readOnlyMode}
                    type='button'
                    className={item.answer === NEGATIVE_ANSWER ? 'negative-answer-selected' : ''}
                    onClick={() => { handleAnswerSelection(item.id, NEGATIVE_ANSWER) }}
                  >
                    <FontAwesomeIcon icon={faFrown} />
                  </button>
                  <button
                    disabled={readOnlyMode}
                    type='button'
                    className={item.answer === NEUTRAL_ANSWER ? 'medium-answer-selected' : ''}
                    onClick={() => { handleAnswerSelection(item.id, NEUTRAL_ANSWER) }}
                  >
                    <FontAwesomeIcon icon={faMeh} />
                  </button>
                  <button
                    disabled={readOnlyMode}
                    type='button'
                    className={item.answer === POSITIVE_ANSWER ? 'positive-answer-selected' : ''}
                    onClick={() => { handleAnswerSelection(item.id, POSITIVE_ANSWER) }}
                  >
                    <FontAwesomeIcon icon={faSmile} />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='comments-wrapper'>
            <label>{t('common.comments')}</label>
            <textarea
              disabled={readOnlyMode}
              value={comment}
              onChange={(e) => { setComment(e.target.value) }}
              placeholder={t("Comments about student's internship")}
            />
          </div>
          <div className={`submit-feedback-wrapper ${submitError ? 'space-between' : ''}`}>
            {submitError && <span className='submit-error'>{t('Please answer all questions')}</span>}
            {readOnlyMode && <span className='previously-evaluated'>{displayConfirmationMessage ? t('Your feedback was recorded, thank you!') : t('This internship has already received feedback')}</span>}
            {!readOnlyMode && (
              comment.length === 0
                ? (
                  <Popconfirm
                    placement='top'
                    okType='danger'
                    title={t("You won't be able to add a comment after submitting the evaluation. Do you want to continue?")}
                    okText={t('Yes')}
                    cancelText={t('Cancel')}
                    onConfirm={submitFeedback}
                    overlayClassName='evaluation-popconfirm'
                  >
                    <Button
                      type='primary'
                      htmlType='button'
                      loading={submitting}
                    >
                      <FontAwesomeIcon icon='check' />&nbsp;
                      {t('Confirm')}
                    </Button>
                  </Popconfirm>
                )
                : (
                  <Button
                    type='primary'
                    htmlType='button'
                    loading={submitting}
                    onClick={submitFeedback}
                  >
                    <FontAwesomeIcon icon='check' />&nbsp;
                    {t('Confirm')}
                  </Button>
                )
            )}
          </div>
        </div>
      )}
      {(loading) &&
        <Loading size='4x' />}
    </>
  )
}
export default connect(mapStateToProps)(StudentFeedback)
