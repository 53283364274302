import { Button, Modal, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { getUser } from '../../reducers/UserReducer'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import Loading from '../../Components/shared/Loading'
import { connect } from 'react-redux'
import { getChildren } from '../../utils/api/institutionOption'
import { INTERNSHIPS_AUTO_ACCEPTATION, AUTO_ACCEPTATION_DAYS, SCHEDULE_EDITION_BY_SCHOOLS } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/pro-solid-svg-icons'
import { updateInstitutionOption } from '../../utils/api/institution'
import { AUTOMATIC_ACCEPTANCE_PERMISSION, SCHEDULE_EDITION_PERMISSION } from '../../utils/entities/SchoolPermission'
import SchoolPermissionTable from '../../Components/institution/Modules/SchoolPermissionTable'
import ModulesList from './ModulesList'

import '../../assets/school-modules-settings.scss'
import { onSuccess, resolvePromises } from '../../utils/apiHelper'

const TabPane = Tabs.TabPane

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SchoolModulesSettings = ({ activeLanguage, user, t, moduleOption }) => {
  const [options, setOptions] = useState([])
  const [availableOptions, setAvailableOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [displayModulesSetting, setDisplayModulesSetting] = useState(false)

  useEffect(() => {
    if (user.id && moduleOption) {
      setLoading(true)
      loadChildren(moduleOption, user)
    }
  }, [user.id, moduleOption])

  const loadChildren = (moduleOption, user) => {
    getChildren(moduleOption, user).then(json => {
      if (json && json.data) {
        const newAvailableOptions = json.data.filter(o => o.optionType.type !== AUTO_ACCEPTATION_DAYS && !o.optionType.isHidden)

        setAvailableOptions(newAvailableOptions)
        setOptions(newAvailableOptions.filter(o => o.optionEnabled))
      }

      setLoading(false)
    })
  }

  const enableModules = enableModules => {
    const promiseStack = []

    setLoading(true)

    availableOptions.forEach(o => {
      promiseStack.push(
        updateInstitutionOption(user.institutions[0], o, !!enableModules.find(id => id === o.id), user)
      )
    })

    resolvePromises(promiseStack).then(() => {
      loadChildren(moduleOption, user)
      onSuccess(t('The modules has been successfully updated'))
    })
  }

  const getPane = type => {
    let pane = null

    if (type === INTERNSHIPS_AUTO_ACCEPTATION) {
      pane = {
        Component: SchoolPermissionTable,
        props: { type: AUTOMATIC_ACCEPTANCE_PERMISSION }
      }
    } else if (type === SCHEDULE_EDITION_BY_SCHOOLS) {
      pane = {
        Component: SchoolPermissionTable,
        props: { type: SCHEDULE_EDITION_PERMISSION }
      }
    }

    return pane
  }

  return (
    <div className={!loading && options.length === 0 ? 'no-modules-settings' : 'school-modules-settings'}>
      {loading && (
        <Loading size='10x' />
      )}
      {!loading && options.length === 0 && (
        <div className='no-modules-container'>
          <FontAwesomeIcon icon={faCogs} size='10x' color='#4F7CAC' />
          <ModulesList modules={availableOptions} onSave={enableModules} />
        </div>
      )}
      {!loading && options.length > 0 && (
        <>
          <Button onClick={() => setDisplayModulesSetting(true)}>
            <FontAwesomeIcon icon={faCogs} color='#4F7CAC' />
          </Button>
          <Tabs defaultActiveKey='0' animated={false}>
            {options.map((o, index) => {
              const Pane = getPane(o.optionType.type)

              return (
                <TabPane
                  key={index}
                  tab={t(o.optionType.type + '.tab_title')}
                >
                  {Pane === null && <span> UNKNOWN MODULE </span>}
                  {Pane !== null && <Pane.Component {...Pane.props} />}
                </TabPane>
              )
            })}
          </Tabs>
          <Modal visible={displayModulesSetting} footer={false} onCancel={() => setDisplayModulesSetting(false)}>
            <ModulesList
              emptySave
              modules={availableOptions}
              title='Select the modules to activate:'
              onSave={m => {
                setDisplayModulesSetting(false)
                enableModules(m)
              }}
            />
          </Modal>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(SchoolModulesSettings)
