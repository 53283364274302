import React from 'react'

import SmileyButton from './SmileyButton'
import { faMeh, faSmile, faFrown } from '@fortawesome/pro-regular-svg-icons'

const NEGATIVE_ANSWER = 'RED_FACE'
const NEUTRAL_ANSWER = 'ORANGE_FACE'
const POSITIVE_ANSWER = 'GREEN_FACE'

const QuestionCard = ({ maxWidth, question, readOnlyMode, onAnswerSelected }) => {
  return (
    <div key={question.id} className={`question-item ${question.invalid ? 'invalid' : ''}`} style={{ maxWidth: '200px' }}>
      <div className='question-text'>{question.label}</div>
      <div className='question-options'>
        <SmileyButton
          disabled={readOnlyMode}
          className={question.answer === NEGATIVE_ANSWER ? 'negative-answer-selected' : ''}
          icon={faFrown}
          onClick={() => { onAnswerSelected(question.id, NEGATIVE_ANSWER) }}
        />
        <SmileyButton
          disabled={readOnlyMode}
          className={question.answer === NEUTRAL_ANSWER ? 'medium-answer-selected' : ''}
          icon={faMeh}
          onClick={() => { onAnswerSelected(question.id, NEUTRAL_ANSWER) }}
        />
        <SmileyButton
          disabled={readOnlyMode}
          className={question.answer === POSITIVE_ANSWER ? 'positive-answer-selected' : ''}
          icon={faSmile}
          onClick={() => { onAnswerSelected(question.id, POSITIVE_ANSWER) }}
        />
      </div>
    </div>
  )
}

export default QuestionCard
