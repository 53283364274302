import React from 'react'

import { Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { faPaste } from '@fortawesome/pro-solid-svg-icons'
import ClickableIcon from '../antd/Buttons/ClickableIcon'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const Mailing = ({ email, t }) => {
  return (
    <div className='flex-row aln-itm-ctr'>
      <Tooltip title={t('mailing_link.title')}>
        <Link to='#' onClick={() => { window.location = 'mailto:' + email }}>
          {email}
        </Link>
      </Tooltip>
      <div className='ml-4'>
        <ClickableIcon icon={faPaste} title={t('mailing_link.copy_into_clipboard')} onClick={() => navigator.clipboard.writeText(email)} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Mailing)
