import React, { useState, useEffect } from 'react'

import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const TableFooter = props => {
  const [entriesText, setEntriesText] = useState('')
  const [pageIndexText, setPageIndexText] = useState('')

  useEffect(() => {
    if (typeof props.t === 'function') {
      const lines = props.linesCount > 1 ? props.t('lines') : props.t('line')
      let entries = `${props.linesCount} ${lines}`

      if (props.dataLength !== props.displayQuantity) {
        const results = props.displayQuantity > 1 ? props.t('results') : props.t('result')

        entries = `${entries} - ${props.displayQuantity} ${results}`
      }

      setEntriesText(entries)
    }
  }, [props.linesCount, props.t, props.displayQuantity, props.dataLength])

  useEffect(() => {
    setPageIndexText(`${props.pageIndex}/${props.maxPageIndex}`)
  }, [props.pageIndex, props.maxPageIndex])

  return (
    <div className='flex-row footer hidden-sigleton-data'>
      <div>{entriesText}</div>
      <div className='flex-fill' />
      <div className='flex-row'>
        <Tooltip placement='top' title={props.t('First page')}>
          <Button
            type='default'
            size='small'
            disabled={props.maxPageIndex === 1 || props.pageIndex === 1}
            onClick={() => props.onIndexChange(-props.maxPageIndex + 1)}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t('Previous page')}>
          <Button
            type='default'
            size='small'
            disabled={props.maxPageIndex === 1 || props.pageIndex === 1}
            onClick={() => props.onIndexChange(-1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <div className='page-index'>
          {pageIndexText}
        </div>
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t('Next page')}>
          <Button
            type='default'
            size='small'
            disabled={
              props.maxPageIndex === 1 || props.pageIndex === props.maxPageIndex
            }
            onClick={() => props.onIndexChange(1)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t('Last page')}>
          <Button
            type='default'
            size='small'
            disabled={
              props.maxPageIndex === 1 || props.pageIndex === props.maxPageIndex
            }
            onClick={() => props.onIndexChange(props.maxPageIndex - 1)}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFooter)
