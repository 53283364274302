import React, { useCallback, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import FormItem from './FormItem'
import { Select } from 'antd'
import { faAtom, faFile, faSave } from '@fortawesome/pro-solid-svg-icons'
import { INTERNSHIP_FILE_TYPES, INTERNSHIP_REQUEST_FILE_VALIDATORS, InternshipRequestFile } from '../../../utils/entities/internshipRequestFile'
import { VALIDATION_FIELDS, isValid } from '../../../utils/validators'
import Dragger from 'antd/lib/upload/Dragger'
import IconButton from '../../antd/Buttons/IconButton'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InternshipRequestFileForm = ({ item, t, onSubmit }) => {
  const [error, setError] = useState({ missingFields: [] })
  const [data, setData] = useState({ ...item })
  const [fileList, setFilesList] = useState([])

  const errorClass = useCallback(error.missingFields.reduce((acc, f) => {
    acc[f] = 'error-input-border'

    return acc
  }, {}), [error])

  const handleSubmit = useCallback(() => {
    const file = { ...data, originalName: fileList[0]?.name ?? null }
    const errors = isValid(file, INTERNSHIP_REQUEST_FILE_VALIDATORS)

    if (errors.keys.length === 0) {
      onSubmit({ file: fileList[0], data: new InternshipRequestFile(data) })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [data, error, setError, fileList, t])

  return (
    <div className='flex-col'>
      <FormItem icon={faAtom} label='form.label.type' required>
        <Select
          className={errorClass[VALIDATION_FIELDS.TYPE]}
          style={{ width: '100%' }}
          value={data.type ?? null}
          onChange={type => setData({ ...data, type })}
        >
          {Object.values(INTERNSHIP_FILE_TYPES).map(i => <Select.Option key={i} value={i}> {t('indidivual_internship_request_file.types.' + i.toLowerCase())} </Select.Option>)}
        </Select>
      </FormItem>
      <FormItem className={errorClass[VALIDATION_FIELDS.ORIGINAL_NAME]} icon={faFile} label='form.label.file' required>
        <Dragger
          fileList={fileList}
          name='file'
          beforeUpload={(file) => {
            setFilesList([file])

            return false
          }}
          onRemove={() => setFilesList([])}
        >
          <p className='ant-upload-text'>{t('import_quotas_button.upload_text')} </p>
        </Dragger>
      </FormItem>
      <IconButton type='primary' icon={faSave} onClick={handleSubmit} text={t('actions.save')} />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipRequestFileForm)
