import React, { useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import '../../assets/score-summary-card.scss'
import { Progress } from 'antd'
import { faMeh, faSmile, faFrown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FA_2X } from './Quotas/utils/Constants'
import Loading from '../shared/Loading'
import LiquidFillGauge from 'react-liquid-gauge'
import { color } from 'd3-color'
import { interpolateRgb } from 'd3-interpolate'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const INTERPOLATE = interpolateRgb('#108ee9', '#108ee9')
const FILL_COLOR = INTERPOLATE(50 / 100)
const GRADIENT_STOPS = [
  {
    key: '0%',
    stopColor: color(FILL_COLOR).darker(0.5).toString(),
    stopOpacity: 1,
    offset: '0%'
  },
  {
    key: '50%',
    stopColor: FILL_COLOR,
    stopOpacity: 0.75,
    offset: '50%'
  },
  {
    key: '100%',
    stopColor: color(FILL_COLOR).brighter(0.5).toString(),
    stopOpacity: 0.5,
    offset: '100%'
  }
]

const ScoreSummaryCard = ({ t, title, size = 'small', request, entityId, user, end, start, score, isInstitution, onChange }) => {
  const [loading, setLoading] = useState(true)
  const [entityData, setEntityData] = useState({})
  const [colorCircle, setColorCircle] = useState('rgba(243,243,243,255)')

  useEffect(() => {
    if (!!entityId && user !== null) {
      const fetchingId = isInstitution ? -1 : entityId

      if (score) {
        setEntityData(score)
      } else {
        setLoading(true)

        request(user, { id: entityId }, end.format('YYYY-MM-DD'), start.format('YYYY-MM-DD'), true).then(json => {
          if (json) {
            setEntityData(json)
            onChange(fetchingId, json)
          }

          setLoading(false)
        })
      }
    }
  }, [start, end, user, entityId])

  useEffect(() => {
    if (entityData !== null && entityData.responseRate > 0) {
      setColorCircle(FILL_COLOR)
    } else {
      setColorCircle('rgba(243,243,243,255)')
    }
  }, [entityData?.responseRate])

  return (
    <>
      {!loading && (
        <div className={size === 'large' ? 'score-summary-card-large' : 'score-summary-card-small'}>
          <div className='title-score-summary-card'>
            {title}
          </div>
          <div className='charts-score-summary-card'>
            <div className='chart-score-summary-card'>
              <p style={{ fontSize: '20px', marginLeft: '-20px', marginRight: '-20px' }}>{t('Score')}</p>
              <Progress
                type='circle'
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068'
                }}
                percent={entityData.feedbackSum}
                format={(percent) => (<span style={{ color: '#5b5b5b' }}>{percent}<span style={{ fontSize: '14px' }}>%</span></span>)}
              />
            </div>
            <div className='chart-score-summary-card'>
              <p style={{ fontSize: '20px', marginLeft: '-20px', marginRight: '-20px' }}>{t('Response rate')}</p>
              <LiquidFillGauge
                style={{ margin: '0 auto' }}
                width={60 * 2}
                height={60 * 2}
                value={entityData.responseRate}
                percent='%'
                textSize={0.8}
                textOffsetX={0}
                textOffsetY={10}
                waveAnimation
                waveFrequency={2}
                waveAmplitude={1}
                gradient
                gradientStops={GRADIENT_STOPS}
                circleStyle={{
                  fill: colorCircle
                }}
                waveStyle={{
                  fill: FILL_COLOR
                }}
                textStyle={{
                  fill: color('#5b5b5b').toString()
                }}
                waveTextStyle={{
                  fill: color('#fff').toString(),
                  fontFamily: 'Arial'
                }}
              />
            </div>
          </div>
          <p className='count-score-summary-card'>{t('Number of internships evaluated')} {entityData.feedbackCount} {t('on')} {entityData.countAllEvaluation}</p>
          <div className='evaluations-score-summary-card'>
            <div className='evaluation-score-summary-card'>
              <span>{entityData.negativeEvaluation}</span> <FontAwesomeIcon icon={faFrown} size={FA_2X} color='#cf1322' />
            </div>
            <div className='evaluation-score-summary-card'>
              <span>{entityData.neutralEvaluation}</span> <FontAwesomeIcon icon={faMeh} size={FA_2X} color='#faad14' />
            </div>
            <div className='evaluation-score-summary-card'>
              <span>{entityData.positiveEvaluation}</span> <FontAwesomeIcon icon={faSmile} size={FA_2X} color='#389e0d' />
            </div>
          </div>
        </div>
      )}
      {(loading) &&
        <div className={size === 'large' ? 'score-summary-card-large' : 'score-summary-card-small'}>
          <div className='title-score-summary-card'>
            {title}
          </div>
          <Loading size='4x' />
        </div>}
    </>
  )
}

export default connect(mapStateToProps)(ScoreSummaryCard)
