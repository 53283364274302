import React, { useCallback, createContext, useEffect, useState } from 'react'

import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getHospitalDepartmentUsers } from '../../../../utils/api/hospitalDepartment'
import HospitalDepartmentUsersList from './HospitalDepartmentUsersList'
import Loading from '../../../../HOC/Loading'

export const HospitalDepartmentDrawerContext = createContext()

export const DEFAULT_STATE = { data: null, callback: null }

const mapsStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const HospitalDepartmentDrawer = ({ children, t, user }) => {
  const [loading, setLoading] = useState(true)
  const [selectedHospitalDepartment, setSelectedHospitalDepartment] = useState(DEFAULT_STATE)
  const [users, setUsers] = useState([])

  const fetchUsers = useCallback(() => {
    if (selectedHospitalDepartment.data && selectedHospitalDepartment.data.id) {
      getHospitalDepartmentUsers(user, selectedHospitalDepartment.data).then(({ data }) => {
        setLoading(false)
        setUsers(data)
      })
    }
  }, [selectedHospitalDepartment.data, user])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <>
      <HospitalDepartmentDrawerContext.Provider value={{ setSelectedHospitalDepartment }}>
        {children}
        <Drawer
          title={t('hospital_department_link_users_drawer.title') + ' ' + selectedHospitalDepartment.data?.name}
          visible={!!selectedHospitalDepartment.data}
          onClose={() => setSelectedHospitalDepartment({ ...DEFAULT_STATE })}
          width={600}
          destroyOnClose
        >
          <Loading loading={loading} size='2x'>
            {selectedHospitalDepartment.data &&
              <HospitalDepartmentUsersList users={users} institutionDepartment={selectedHospitalDepartment} onSave={fetchUsers} />}
          </Loading>
        </Drawer>
      </HospitalDepartmentDrawerContext.Provider>
    </>
  )
}

export default connect(mapsStateToProps)(HospitalDepartmentDrawer)
