
import React, { useEffect, useMemo, useState } from 'react'

import { Button, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { connect } from 'react-redux'
import moment from 'moment'
import { getTranslate } from 'react-localize-redux'
import { DATE_WITHOUT_TIME, internshipStates } from '../../../../utils/constants'
import { getUser } from '../../../../reducers/UserReducer'
import { getInternshipsQuotas } from '../../../../utils/api/quota'

const { ACCEPTED } = internshipStates

const mapStateToProps = state => ({ user: getUser(state.getUser), t: getTranslate(state.locale) })

const InternshipAcceptationButton = ({ internship, user, size, disabled, style, onClick, t }) => {
  const initialColor = useMemo(() => internship.state === ACCEPTED || disabled ? 'grey' : '#7EB73E', [internship.state])

  const [color, setColor] = useState(initialColor)

  useEffect(() => {
    if (!disabled) {
      getInternshipsQuotas([{ id: internship.id }], moment(internship.startDate).format(DATE_WITHOUT_TIME), moment(internship.endDate).format(DATE_WITHOUT_TIME), user).then(json => {
        if (json?.data) {
          const key = Object.keys(json.data)[0]
          const minValue = (Math.min.apply(Math, json.data[key])) - 1

          if (minValue === 0) {
            setColor('#3c8dbc')
          } else if (minValue > 0) {
            setColor('#7EB73E')
          } else if (minValue < 1) {
            setColor('#F44336')
          }
        }
      })
    } else {
      setColor(initialColor)
    }
  }, [internship.id, internship.startDate, internship.endDate, user.id, disabled, initialColor])

  return (
    <Button size={size} disabled={disabled} style={style} onClick={onClick}>
      <Tooltip title={t('Accept')}>
        <FontAwesomeIcon height='14px' color={color} icon={faCheck} />
      </Tooltip>
    </Button>
  )
}

export default connect(mapStateToProps)(InternshipAcceptationButton)
