import React from 'react'

import { faFilter } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu } from 'antd'

const TableFilters = ({ filters, parameters, onParametersChange }) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {filters.map((f, index) => {
            const extendedProps = {
              ...f.props,
              key: index,
              onChange: (i) => {
                const newFilters = { ...parameters.filters }

                newFilters[f.key] = i

                onParametersChange({ ...parameters, filters: newFilters })

                if (typeof f.props.onChange === 'function') {
                  f.props.onChange(i)
                }
              }
            }

            return (
              <Menu.ItemGroup key={index} title={f.label}>
                <Menu.Item disabled style={{ cursor: 'default' }}><f.component {...extendedProps} /></Menu.Item>
              </Menu.ItemGroup>
            )
          })}
        </Menu>
      }
      overlayClassName='custom-overlay'
      trigger={['click']}
    >
      <Button>
        <FontAwesomeIcon icon={faFilter} />
      </Button>
    </Dropdown>
  )
}

export default TableFilters
