import React, { useState, useEffect } from 'react'

import { Dropdown, Menu, Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../../reducers/Dispatchers'
import { faCog } from '@fortawesome/pro-solid-svg-icons'

const DEFAULT_LINES_SHOWN = [
  { value: 10, caption: 'lines' },
  { value: 20, caption: 'lines' },
  { value: 50, caption: 'lines' },
  { value: 100, caption: 'lines' }
]
const DEFAULT_LINE_SHOWN_INDEX = 0
const DEFAULT_LINES_DENSITY = [
  { value: 32, caption: 'High density' },
  { value: 40, caption: 'Medium density' },
  { value: 48, caption: 'Low density' }
]
const DEFAULT_LINE_DENSITY_INDEX = 0

/**
 * A standart factory to build a Settings menu for table header
 *
 * Column array default format :
 *  [
 *    { name: <column_name>, isVisible: <is_visible_boolean> },
 *    ...
 *  ]
 *
 * Needed props :
 *
 * Optionnal props :
 *  {array} linesShown   array of objects that have to respect standart format. this format can be find
 *                         in the top of the file : DEFAULT_LINES_SHOWN
 *  {array} linesDensity array of objects that have to respect standart format. this format can be find
 *                         in the top of the file : DEFAULT_LINES_DENSITY
 *  {array} columns      array of objects that have to respect standart format. this format can be find
 *                         in the top of the file
 *  {function} onLineShownSelected        callback function use when a line show item is selected
 *  {function} onLineDensitySelected      callback function use when a line density is selected
 *  {function} onColumnVisibilitySelected callback function use when a visibility Item is selected
 *  {boolean}  noShownSelector            set to true in order to have no display of shown lines
 *  {boolean}  noDensitySelector          set to true in order to have no display of density lines
 *  {boolean}  noColumnHider              set to true in order to have no display of columns hider
 *
 * @param {*} props all the additionnal props pass to the component
 * @returns a grappe react
 */
const SettingsFactory = props => {
  const [linesShown, setLinesShown] = useState(DEFAULT_LINES_SHOWN)
  const [lineShownIndex, setLineShownIndex] = useState(DEFAULT_LINE_SHOWN_INDEX)
  const [linesDensity, setLinesDensity] = useState(DEFAULT_LINES_DENSITY)
  const [lineDensityIndex, setLineDensityIndex] = useState(DEFAULT_LINE_DENSITY_INDEX)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (props.linesShown) {
      setLinesShown(props.linesShown)
    }
  }, [props.linesShown])

  useEffect(() => {
    if (props.linesDensity) {
      setLinesDensity(props.linesDensity)
    }
  }, [props.linesDensity])

  useEffect(() => {
    if (props.columns) {
      setLineDensityIndex(props.columns)
    }
  }, [props.columns])

  /**
   * Choose callback function according to the menuKey trigger
   * Call the callback function and pass ke value as parameter
   * Display a message in case of the necessary callback function is missing
   *
   * @param {string} menuKey the selected menu key
   */
  const tableSettingsItemClick = async menuKey => {
    const menuKeyParts = menuKey.split(' ')
    switch (menuKeyParts[1]) {
      case 's' :
        setLineShownIndex(parseInt(menuKeyParts[0]))
        props.onLineShownSelected
          ? props.onLineShownSelected(linesShown[menuKeyParts[0]].value)
          : console.log('You you have not configured yet callback for lineShown')
        break
      case 'd' :
        setLineDensityIndex(parseInt(menuKeyParts[0]))
        props.onLineDensitySelected
          ? props.onLineDensitySelected(linesDensity[menuKeyParts[0]].value)
          : console.log('You you have not configured yet callback for lineDensity')
        break
      case 'c' :
        modifyColumnsVisibility(menuKeyParts)

        props.onColumnVisibilitySelected
          ? props.onColumnVisibilitySelected()
          : console.log('You you have not configured yet callback for columnsVisibility')
        break
      default :
        console.log('unknown menu key')
        break
    }
  }

  const modifyColumnsVisibility = menuKeyParts => {
    const newColumns = [...columns]

    newColumns[menuKeyParts[0]].isVisible = !newColumns[menuKeyParts[0]].isVisible

    setColumns(newColumns)
  }

  /**
   * Render Menu Item Component for the Hide column entries
   *
   * @param {array} columnsArray array of objects that have to respect standart format. this format can be find
   *                               in the top of the file
   * @returns grappe react
   */
  const renderHideColumnsEntries = columnsArray => {
    return columnsArray.map((column, index) => {
      return (
        <Menu.Item key={index + ' c'}>
          <FontAwesomeIcon icon={column.isVisible ? 'eye' : 'eye-slash'} />
          {props.t(column.name)}
        </Menu.Item>
      )
    })
  }

  /**
   * Render Menu Item Component for the lines density entries
   *
   * @param {*} linesDensityArray array of objects that have to respect standart format. this format can be find
   *                               in the top of the file
   * @returns grappe react
   */
  const renderLinesDensityEntries = linesDensityArray => {
    return linesDensityArray.map((line, index) => {
      return (
        <Menu.Item key={index + ' d'}>
          <FontAwesomeIcon
            icon='check'
            style={{ color: index === lineDensityIndex ? 'inherit' : 'transparent' }}
          />
          {props.t(line.caption)}
        </Menu.Item>
      )
    })
  }

  /**
   * Render Menu Item Component for the lines shown entries
   *
   * @param {*} linesDensityArray array of objects that have to respect standart format. this format can be find
   *                               in the top of the file
   * @returns grappe react
   */
  const renderLinesShownEntries = linesShownArray => {
    return linesShownArray.map((line, index) => {
      return (
        <Menu.Item key={index + ' s'}>
          <FontAwesomeIcon
            icon='check'
            style={{ color: index === lineShownIndex ? 'inherit' : 'transparent' }}
          />
          {line.value + ' ' + props.t(line.caption)}
        </Menu.Item>
      )
    })
  }

  /**
   * Render the dropdown menu
   *
   * @param {array} linesShownArray   array of objects that have to respect standart format. this format can be find
   *                                    in the top of the file
   * @param {array} linesDensityArray array of objects that have to respect standart format. this format can be find
   *                                    in the top of the file
   * @param {array} columnsArray      array of objects that have to respect standart format. this format can be find
   *                                    in the top of the file
   * @returns grappe react
   */
  const renderDropDownMenu = (linesShownArray, linesDensityArray, columnsArray) => {
    return (
      <Menu onClick={key => tableSettingsItemClick(key.key)}>
        {!props.noShownSelector ? renderLinesShownEntries(linesShownArray) : undefined}
        {!(props.noShownSelector || props.noDensitySelector) ? <Menu.Divider /> : undefined}
        {!props.noDensitySelector ? renderLinesDensityEntries(linesDensityArray) : undefined}
        {!(props.noColumnHider || (props.noDensitySelector && props.noShownSelector)) && columns.length > 0 ? <Menu.Divider /> : undefined}
        {!props.noColumnHider ? renderHideColumnsEntries(columnsArray) : undefined}
      </Menu>
    )
  }

  return (
    <Dropdown trigger={['click']} overlay={renderDropDownMenu(linesShown, linesDensity, columns)}>
      <Tooltip placement='top' title={props.t('Settings')}>
        <Button>
          <FontAwesomeIcon icon={faCog} />
        </Button>
      </Tooltip>
    </Dropdown>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsFactory)
