import React, { useEffect, useState } from 'react'

import IconLabel from '../icons/IconLabel'
import { faHospital, faSchool, faUser } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { conditionalPromise, resolvePromises } from '../../utils/apiHelper'
import { getSectionContactPersons, getSectorContactPersons } from '../../utils/api/contactPerson'
import ContactPerson from './ContactPerson'
import { isObject } from 'lodash'
import Mailing from '../shared/Mailing'
import { getInternshipSupervisors } from '../../utils/api/internship'
import Loading from '../../HOC/Loading'

const DEFAULT_STATE = { supervisors: [], sectors: [], sections: [] }

const mapStateToProps = (state) => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipContacts = ({ internship: { id = null, institution = null, school = null, sector = null, section = null, coordinator = null }, user, t }) => {
  const [contactPersons, setContactPersons] = useState(DEFAULT_STATE)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const stack = []

    stack.push(getInternshipSupervisors(user, { id }).then((json) => json?.data ?? []))
    stack.push(conditionalPromise(
      () => getSectorContactPersons(user, { sector }),
      sector !== null,
      []
    ).then((json) => json?.data ?? []))
    stack.push(conditionalPromise(
      () => getSectionContactPersons(user, { section }),
      section !== null,
      []
    ).then((json) => json?.data ?? []))

    resolvePromises(stack).then(([supervisors, sectors, sections]) => {
      setContactPersons({ supervisors, sectors, sections })
      setLoading(false)
    })
  }, [user.id, id, institution, school, sector, section])

  return (
    <div>
      <Loading loading={loading}>
        <div>
          <b><IconLabel icon={faUser} text={t('common.coordinator')} padding='pdl-0' /></b>
          <div className='pdl-12'>
            {isObject(coordinator) && <Mailing email={coordinator.email} />}
            {!isObject(coordinator) && <p className='ant-alert-error'>{t('common.none')}</p>}
          </div>
        </div>
        <div>
          <b><IconLabel icon={faUser} text={t('common.supervisors')} padding='pdl-0' /></b>
          <div className='pdl-12'>
            {contactPersons.supervisors.length === 0 && <span>{t('common.none')}</span>}
            {contactPersons.supervisors.map((cp, index) => <ContactPerson key={cp.email + index} contactPerson={cp} icon={faSchool} />)}
          </div>
        </div>
        <div>
          <b><IconLabel icon={faUser} text={t('common.contact_persons')} padding='pdl-0' /></b>
          <div className='pdl-12'>
            {contactPersons.sections.length === 0 && contactPersons.sectors.length === 0 && <span>{t('common.none')}</span>}
            {contactPersons.sections.map((cp, index) => <ContactPerson key={cp.email + index} icon={faSchool} contactPerson={cp} />)}
            {contactPersons.sectors.map((cp, index) => <ContactPerson key={cp.email + index} icon={faHospital} contactPerson={cp} />)}
          </div>
        </div>
      </Loading>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipContacts)
