import React, { useContext, useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import ShiftsCalendar from '../../Components/student/Desiderata/ShiftsCalendar'
import InternshipStatePellet from '../../Components/shared/InternshipStatePellet'
import moment from 'moment'
import { Tabs, Collapse, Tooltip } from 'antd'
import { internshipStates } from '../../utils/constants'
import { getUser } from '../../reducers/UserReducer'
import { EvaluationsContext } from '../../Providers/EvaluationsProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile } from '@fortawesome/pro-regular-svg-icons'
import { getStudentInternships, getStudentSchoolOptions } from '../../utils/api/student'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import { isObject } from '../../utils'
import { StudentContext } from '../../Providers/StudentProvider'
import { resolvePromises } from '../../utils/apiHelper'
import InternshipItem from '../../Components/student/InternshipItem'

import '../../assets/student-internships-view.scss'

const Panel = Collapse.Panel
const TabPane = Tabs.TabPane

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipsView = ({ user, t }) => {
  const { freefields } = useContext(StudentContext)
  const { internshipsToEvaluate } = useContext(EvaluationsContext)

  const [internships, setInternships] = useState([])
  const [selectedInternship, setSelectedInternship] = useState(null)
  const [schoolOptions, setSchoolOptions] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (user.student) {
      setLoading(true)

      const internshipsP = getStudentInternships(
        user,
        user.student,
        { contexts: ['institution', 'sector', 'section', 'institution', 'school', 'documents', 'coordinator'] }
      ).then(json => {
        if (json?.data) {
          setInternships(json.data)
        }
      })
      const optionsP = getStudentSchoolOptions(user, user.student).then(json => {
        if (json?.data) {
          setSchoolOptions(json.data)
        }
      })

      resolvePromises([internshipsP, optionsP]).then(setLoading(false))
    } else {
      setInternships([])
    }
  }, [user.student])

  const renderInternships = (shift) => {
    if (loading) {
      return t('misc.loading')
    }

    let internshipsList = internships.filter(i => {
      if (shift === 'past') {
        return getTimezoneLessMoment(i.endDate).diff(moment()) < 0
      }

      return getTimezoneLessMoment(i.endDate).diff(moment()) >= 0
    })

    if (internshipsList.length === 0) {
      const messagePast = 'You currently do not have any past internship.'
      const messageCurrent = 'You currently have no scheduled internship.'

      return t(shift === 'past' ? messagePast : messageCurrent)
    }

    internshipsList = internshipsList.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))

    return renderInternshipItems(internshipsList)
  }

  const renderInternshipItems = internshipsList => {
    const { ACCEPTED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED, AUTO_ACCEPTED } = internshipStates

    return (
      <Collapse bordered={false} accordion>
        {
          internshipsList.map(i => {
            const start = getTimezoneLessMoment(i.startDate).format('DD/MM/YYYY')
            const end = getTimezoneLessMoment(i.endDate).format('DD/MM/YYYY')
            const institution = isObject(i.institution) ? i.institution.name : t('Undefined institution')
            const disabled = ![ACCEPTED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED, AUTO_ACCEPTED].includes(i.state)

            let title = (
              <div className='flex-column'>
                {institution} <br />
                {t('From') + ' ' + start + ' ' + t('to') + ' ' + end}<br />
                <InternshipStatePellet internship={i} showLabel />
              </div>
            )

            if (internshipsToEvaluate.find(internship => internship.id === i.id)) {
              title = (
                <div className='internship-item-title'>
                  {title}
                  <div className='exclamation-container'>
                    <Tooltip
                      title={t('This internship has to be evaluated')}
                    >
                      <FontAwesomeIcon icon={faSmile} size='2x' />
                    </Tooltip>
                  </div>
                </div>
              )
            }

            return (
              <Panel header={title} key={i.id} disabled={disabled}>
                {!disabled && (
                  <InternshipItem
                    internship={i}
                    schoolOptions={schoolOptions}
                    studentFreeFields={freefields}
                    onInternshipSelect={id => setSelectedInternship(internships.find(i => i.id === id))}
                    onUpdate={internship => setInternships(internships.map(i => i.id === internship.id ? internship : i))}
                  />
                )}
              </Panel>
            )
          })
        }
      </Collapse>
    )
  }

  const updateSelectedInternship = internship => {
    const internshipsMap = internships.map(i => i.id === internship.id ? internship : i)

    setInternships(internshipsMap)
    setSelectedInternship(internship)
  }

  return (
    <div>
      {selectedInternship === null && (
        <Tabs defaultActiveKey='1' animated={false} style={{ padding: '3px' }}>
          <TabPane tab={t('Upcoming internships')} key='1'>{renderInternships('current')}</TabPane>
          <TabPane tab={t('Past internships')} key='2'>{renderInternships('past')}</TabPane>
        </Tabs>
      )}
      {selectedInternship && (
        <ShiftsCalendar
          internship={selectedInternship}
          onBack={() => setSelectedInternship(null)}
          onInternshipUpdate={updateSelectedInternship}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsView)
