import React from 'react'

import { Select } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

const DEFAULT_MONTHS = [
  {
    weeksQuantity: 4,
    type: 'month'
  },
  {
    weeksQuantity: 8,
    type: 'months'
  },
  {
    weeksQuantity: 12,
    type: 'months'
  },
  {
    weeksQuantity: 16,
    type: 'months'
  },
  {
    weeksQuantity: 20,
    type: 'months'
  },
  {
    weeksQuantity: 24,
    type: 'months'
  },
  {
    weeksQuantity: 48,
    type: 'months'
  }
]

const PeriodSelector = props => {
  const handleOptionSelect = (value) => {
    props.onWeeksQuantityChange(value)
  }

  const renderMonthsDisplayed = () => {
    return DEFAULT_MONTHS.map((month, index) => (
        <Select.Option key={`${index}-month`} value={month.weeksQuantity}>
          {`${month.weeksQuantity / 4} ${props.t(month.type)}`}
        </Select.Option>
      ))
  }

  return (
    <Select
      value={props.currentWeeksQuantity}
      onChange={handleOptionSelect}
      style={{ width: 210 }}
    >
      {renderMonthsDisplayed()}
    </Select>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSelector)
