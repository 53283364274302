import React, { useCallback, useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import moment from 'moment'
import { DatePicker, Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBusinessTime, faCalendar } from '@fortawesome/pro-solid-svg-icons'
import { COLORS_PALETTE } from '../../../../config/colors'
import StatesSelector from '../../../../Components/shared/InternshipsManager/StatesSelector'

const RangePicker = DatePicker.RangePicker

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InternshipsFilters = ({ t, onDateChange, dates, states, onStatesChanges }) => {
  const [period, setPeriod] = useState(dates)

  const handlePeriodChange = useCallback(dates => {
    if (dates.length > 0) {
      const startDate = dates[0]
      const endDate = dates[1]
      const startDateString = startDate.format('YYYY-MM-DD')
      const endDateString = endDate.format('YYYY-MM-DD')

      setPeriod({
        startDate: startDateString,
        endDate: endDateString
      })
      onDateChange({
        startDate: startDateString,
        endDate: endDateString
      })
    }
  }, [onDateChange, setPeriod])

  return (
    <Menu>
      <span color={COLORS_PALETTE.FIRST}>
        <FontAwesomeIcon icon={faCalendar} color={COLORS_PALETTE.SECOND} />
        {t('Period')}
      </span>
      <Menu.Item>
        <RangePicker
          style={{ width: '210px' }}
          defaultValue={[moment(period.startDate), moment(period.endDate)]}
          onChange={handlePeriodChange}
          format='YYYY-MM-DD'
        />
      </Menu.Item>
      <br />
      <span color={COLORS_PALETTE.FIRST}>
        <FontAwesomeIcon icon={faBusinessTime} color={COLORS_PALETTE.SECOND} />
        {t('State')}
      </span>
      <Menu.Item disabled style={{ cursor: 'default' }}>
        <StatesSelector
          values={states}
          onCheck={onStatesChanges}
        />
      </Menu.Item>
    </Menu>
  )
}

export default connect(mapStateToProps)(InternshipsFilters)
