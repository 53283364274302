import React, { useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons'

const Link = ({ href, children }) => {
  const [visible, setVisible] = useState(false)

  const aRef = useRef(null)

  return (
    <div className='flex-row jtf-ctt-sbw hover-bg-lightgrey bdr-rd-4 pd-4' onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
      <div className='w-100' onClick={() => aRef.current.click()} style={{ cursor: 'pointer' }}>
        <a href={href} ref={aRef} target='_blank' rel='noopener noreferrer'>
          {children}
        </a>
      </div>
      {visible && (
        <div className='ml-8 hover-blue' style={{ cursor: 'pointer' }} onClick={() => aRef.current.click()}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </div>
      )}
    </div>
  )
}

export default Link
