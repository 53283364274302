import React, { useMemo } from 'react'

import DataTable from '../../../Components/shared/DataTable/DataTable'
import Cookie from 'js-cookie'
import { ACTIONS, Action, DeleteAction } from '../../../Components/shared/DataTable/utils/actions'
import { faGraduationCap, faLink, faUnlink } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { ORDER_BY } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'
import { getAddress, getEmail, getName, getPhoneNumber } from '../../../utils/entities/school'
import { Select, Tag } from 'antd'
import { Filter, buildFilters } from '../../../Components/shared/DataTable/utils/tableFilter'
import { TableParameters } from '../../../utils/entities/tableParameters'

export const GLOBAL_INSTITUTION = 'global_institution'

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const SchoolsTable = ({
  context = null, data, help, loading, metadata, parameters, user,
  onParametersChange, onSelectInstitution, onTriggerAction, t
}) => {
  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '180px' : '139px'})`, [Cookie.get('switch_user_id')])
  const columns = useMemo(() => [
    {
      title: t('schools_table.column_title.name'),
      orderBy: ORDER_BY.NAME,
      key: s => {
        const name = getName(s)

        return name.length > 50 ? `${name.slice(0, 47)}...` : name
      },
      tooltip: getName
    },
    { title: t('schools_table.column_title.phone_number'), orderBy: ORDER_BY.PHONE_NUMBER, key: getPhoneNumber },
    { title: 'Email', orderBy: ORDER_BY.EMAIL, key: getEmail },
    { title: t('schools_table.column_title.address'), orderBy: ORDER_BY.ADDRESS, key: getAddress },
    {
      title: t('schools_table.column_title.linked'),
      key: s => <Tag color={s.linked ? 'green' : 'red'}> {t(s.linked ? 'schools_table.linked' : 'schools_table.not_linked')} </Tag>
    }
  ], [t])
  const actions = useMemo(() => {
    let actions = {
      link: ACTIONS.LINK_INSTITUTION_SCHOOL,
      unlink: ACTIONS.UNLINK_INSTITUTION_SCHOOL
    }

    if (context === GLOBAL_INSTITUTION) {
      actions = {
        link: ACTIONS.LINK_INSTITUTION_GROUP_SCHOOL,
        unlink: ACTIONS.UNLINK_INSTITUTION_GROUP_SCHOOL
      }
    }

    return [
      new Action({
        title: 'data_table.actions.view_school_sections',
        icon: faGraduationCap,
        onClick: s => onTriggerAction(s, ACTIONS.SELECT)
      }),
      new Action({
        title: 'data_table.actions.link',
        icon: faLink,
        onClick: s => onTriggerAction(s, actions.link),
        toRender: s => !s.linked
      }),
      new DeleteAction({
        onDelete: s => onTriggerAction(s, actions.unlink),
        title: 'schools_table.not_linked',
        icon: faUnlink,
        popconfirmTitle: 'delete_action.pop_confirm.unlink',
        toRender: s => s.linked
      })
    ]
  }, [onTriggerAction, onSelectInstitution])
  const filters = useMemo(() => buildFilters([
    new Filter({
      key: 'linked',
      label: t('schools_table.linked'),
      component: ({ data = [], value = null, onChange }) => {
        return (
          <Select onChange={onChange} value={value === null ? null : +value} style={{ width: '200px' }}>
            {data.map(d => {
              return (
                <Select.Option key={d.name} value={d.value}>
                  {d.color && <Tag color={d.color}>{t(d.name)} </Tag>}
                  {!d.color && t(d.name)}
                </Select.Option>
              )
            })}
          </Select>
        )
      },
      props: {
        data: [{ name: t('schools_table.linked'), value: 1, color: 'green' }, { name: t('schools_table.not_linked'), value: 0, color: 'red' }, { name: '/', value: null }],
        value: parameters.filters.linked ?? null,
        onChange: value => onParametersChange(new TableParameters({ ...parameters, filters: { ...parameters.filters, linked: value === null ? null : !!value } }))
      }
    })
  ]), [t, parameters, onParametersChange])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        filters={filters}
        help={help}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(SchoolsTable)
