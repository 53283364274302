import { requestWithPromise } from '..'
import { GET, POST } from '../apiHelper'

export const lookup = (username) => {
  return requestWithPromise(`/auth/lookup?email=${encodeURIComponent(username)}`, GET, null, null, true, true)
}

export const loginSSO = async (authorizationCode, codeVerifier) => {
  return await requestWithPromise('/auth/login-sso', POST, { code: authorizationCode, codeVerifier: codeVerifier })
}
