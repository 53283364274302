import React, { useState, useEffect, useMemo, useContext } from 'react'

import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'
import { Button, Dropdown, Menu, notification, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadFile, generalErrorHandler, request, requestWithPromise } from '../../../utils'
import { internshipStates } from '../../../utils/constants'
import FilesManager, { INTERNSHIP_VALIDATION_TYPE } from '../FilesManager'
import { faCalendar, faChevronDown, faFileAlt, faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import InternshipValidation from './InternshipValidation'
import { getInternshipDocuments } from '../../../utils/api/internship'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { WindowWidthAware } from '../../../HOC/WindowWidthAware'

const { SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED } = internshipStates
const AUTHORIZED_VALIDATION_STATES = [SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED]
const VALIDATION_FILES_LIMIT = 1

const DesiderataHeaderButtonBar = ({ desiderataInfo, internship, loading, shifts, getUser, useDesiderataLimitation, onBack, onInternshipUpdate, t }) => {
  const [printingValidationDocument, setPrintingValidationDocument] = useState(false)
  const [internshipFilesManagerOpen, setInternshipFilesManagerOpen] = useState(false)
  const [canDownloadValidationDoc, setCanDownloadValidationDoc] = useState(false)
  const [canUploadValidationDoc, setCanUploadValidationDoc] = useState(false)
  const [validationDocument, setValidationDocument] = useState(null)
  const [additionalActions, setAdditionalActions] = useState([])
  const [internshipSummaryVisible, setInternshipSummaryVisible] = useState(false)

  const { eventCodes } = useContext(GlobalContext)

  const disableDesiderataLimitation = useMemo(() => internship.school?.managed, [internship.school])
  const availabilityItems = useMemo(() => (
    <Menu>
      <Menu.ItemGroup title={t('desiderata_header.menu_tailor_made_title')}>
        <Menu.Item disabled style={{ color: 'black' }}>
          <div className='flex-column'>
            {t(disableDesiderataLimitation
              ? 'desiderata_header.menu_desideratas_no_limitation'
              : t('desiderata_header.menu_desideratas_title') + desiderataInfo.daysLeft + t('desiderata_header.menu_desideratas_days')
            )}
            <Progress percent={disableDesiderataLimitation ? 100 : desiderataInfo.percentage} showInfo={false} />
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  ), [desiderataInfo.percentage, t, disableDesiderataLimitation])

  const actionItems = <Menu>{additionalActions}</Menu>

  let longPollingIntervalExport

  useEffect(() => {
    if (internship) {
      setCanDownloadValidationDoc(internship.state === SCHEDULE_VALIDATED || shifts.filter(s => s.isDesiderata).length > 0)
      setCanUploadValidationDoc(AUTHORIZED_VALIDATION_STATES.includes(internship.state))
    }
  }, [internship, shifts])

  useEffect(() => {
    if (internship.studentValidationDocumentId) {
      getInternshipDocuments(getUser, internship).then(json => {
        if (json?.data) {
          setValidationDocument(json.data.find(d => d.id === internship.studentValidationDocumentId))
        }
      })
    } else {
      setValidationDocument(null)
    }
  }, [internship.id, internship.studentValidationDocumentId, getUser])

  useEffect(() => {
    displayActionsMenu(canUploadValidationDoc, validationDocument, canDownloadValidationDoc, internship.state)
  }, [canUploadValidationDoc, validationDocument, canDownloadValidationDoc, internship.state])

  const displayActionsMenu = (canUpload, validationDocument, canDownload, state) => {
    const actions = []

    if (canUpload || validationDocument || canDownload) {
      actions.push(
        <Menu.Item
          key='menu-1'
          disabled={printingValidationDocument}
          onClick={() => handleDocumentPrint(validationDocument, state)}
        >
          <FontAwesomeIcon icon={faFileAlt} />&nbsp;
          {t(validationDocument && state === SCHEDULE_VALIDATED ? 'Download signed validation document' : 'Print Validation document')}
        </Menu.Item>
      )
    }

    if (canUpload) {
      actions.push(
        <Menu.Item
          key='menu-2'
          className='desiderata-header-button-upload'
          disabled={printingValidationDocument}
          onClick={handleDocumentUpload}
        >
          <FontAwesomeIcon icon={faPaperclip} />&nbsp;{t('Upload signed validation document')}
        </Menu.Item>
      )
    }

    actions.push(
      <Menu.Item
        key='menu-3'
        onClick={() => setInternshipSummaryVisible(true)}
      >
        <FontAwesomeIcon icon={faCalendar} />&nbsp;{t('View schedule summary')}
      </Menu.Item>
    )

    setAdditionalActions(actions)
  }

  const handleDocumentPrint = (validationDocument, state) => {
    setPrintingValidationDocument(true)

    if (validationDocument && state === SCHEDULE_VALIDATED) {
      // student already uploaded a document, we have to download it
      try {
        downloadFile(`/internship/document/download/${internship.studentValidationDocumentId}`, validationDocument.originalName, getUser)
        setPrintingValidationDocument(false)
      } catch (err) {
        setPrintingValidationDocument(false)
        generalErrorHandler(err)
      }
    } else {
      const body = {
        internship: internship.id,
        eventCodesTypesLabels: {}
      }

      eventCodes.forEach(e => { body.eventCodesTypesLabels[e.type] = t('event.code.label.' + e.type) })

      request(
        '/internship/export-validated-internship',
        'POST',
        body,
        getUser
      ).then(({ job, status }) => {
        if (status === 'success') {
          longPollingIntervalExport = setInterval(() => {
            request('/job-result/' + job, 'GET', null, getUser, {
              catchError: false
            }).then(json => {
              if (
                (typeof json.error === 'undefined' || json.error === false) &&
                typeof json.data !== 'undefined'
              ) {
                clearInterval(longPollingIntervalExport)
                setPrintingValidationDocument(false)
                const fileName = json.data.result
                try {
                  downloadFile(
                    '/validation-docs/download/' + fileName,
                    fileName,
                    getUser,
                    'GET'
                  )
                } catch (err) {
                  generalErrorHandler(err)
                  clearInterval(longPollingIntervalExport)
                  setPrintingValidationDocument(false)
                }
              }
            })
          }, 1500)
        }
      })
    }
  }

  const handleDocumentUpload = () => {
    setInternshipFilesManagerOpen(true)
  }

  const handleValidationFileDeletion = file => {
    requestWithPromise(`/api/internships/${internship.id}/documents/validation`, 'DELETE', {}, getUser)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          const newState = jsonResponse.data[0].state

          onInternshipUpdate({
            ...internship,
            state: newState,
            studentValidationDocumentId: null
          })

          notification.success({ message: t('Validation file deleted successfully'), placement: 'bottomRight' })
        }
      })
  }

  const handleValidationFileUpload = file => {
    onInternshipUpdate({ ...internship, state: SCHEDULE_TO_VALIDATE, studentValidationDocumentId: file.id })
  }

  return (
    <div className='flex-row sticky-student-stages-bar' style={getUser.switchUser ? { top: '41px' } : null}>
      <Button type='primary' onClick={() => { onBack(internship.id, internship.shifts) }}>
        <FontAwesomeIcon icon='chevron-left' />&nbsp;{t('Back')}
      </Button>
      <div className='flex-fill' />
      {loading ? (
        <div className='loading-icon black' style={{ marginRight: '15px' }} />
      ) : null}
      {useDesiderataLimitation && (
        <Dropdown overlay={availabilityItems} trigger={['click']}>
          <Button style={{ marginRight: '5px' }}>
            {t('Available days')}&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronDown} />
          </Button>
        </Dropdown>
      )}
      {additionalActions.length > 0 && (
        <Dropdown overlay={actionItems}>
          <Button>{t('Additional actions')}&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronDown} /></Button>
        </Dropdown>
      )}
      <FilesManager
        width='100%'
        entity={internshipFilesManagerOpen ? internship : null}
        maxFiles={VALIDATION_FILES_LIMIT}
        entityName='Internship'
        onClose={() => setInternshipFilesManagerOpen(false)}
        fileType={INTERNSHIP_VALIDATION_TYPE}
        uploadParams={[{ key: 'validationDocument', value: true }]}
        onFileDelete={handleValidationFileDeletion}
        onUploadsucceeded={handleValidationFileUpload}
      />
      {internshipSummaryVisible && (
        <WindowWidthAware limit={550}>
          <InternshipValidation
            internship={internship}
            onClose={() => setInternshipSummaryVisible(false)}
            validationViewBottom={false}
            titleDrawer={t('Summary of the internship')}
          />
        </WindowWidthAware>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DesiderataHeaderButtonBar)
