import React, { useState, useEffect } from 'react'

import { isObject, objectToArray } from '../../../utils'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import ShiftPresetCard from './ShiftPresetCard'
import { orderPresetsByStartTimeAndEndTime } from '../../../Components/shared/ShiftsManager/helper'
import { connect } from 'react-redux'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { createDeletedSectorShiftPreset, createSectorShiftPreset, deleteShiftPreset, updateShiftPreset } from '../../../utils/api/shiftPreset'
import { ShiftPreset } from '../../../utils/entities/ShiftPreset'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { HTTP_BAD_REQUEST, HTTP_CONFLICT, HTTP_FORBIDDEN, HTTP_INTERNAL_ERROR } from '../../../utils/http'
import IconButton from '../../../Components/antd/Buttons/IconButton'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const SectorShiftPresetsManager = ({ disabled, sector, shiftPresets, user, t }) => {
  const [presets, setPresets] = useState([])
  const [newPreset, setNewPreset] = useState(null)

  useEffect(() => {
    if (isObject(sector) && shiftPresets[sector.id]) {
      setPresets(
        orderPresetsByStartTimeAndEndTime(objectToArray(
          shiftPresets[sector.id]
        ))
      )
    }
  }, [sector?.id, shiftPresets[sector?.id]])

  const handleAddItem = () => {
    setNewPreset(new ShiftPreset({}))
  }

  const handleSave = (preset, index) => {
    preset.sector = sector.id
    preset.institution = user.institutions[0].id
    let promise

    if (preset.id === -1) {
      promise = createSectorShiftPreset(user, sector, new ShiftPreset(preset)).then(json => {
        const newPresets = [...presets, json.data]

        setPresets(orderPresetsByStartTimeAndEndTime(newPresets))
        setNewPreset(null)
        onSuccess(t('sector_shift_preset.creation.success'))
      })
    } else if (preset.isDefault) {
      promise = createSectorShiftPreset(user, sector, new ShiftPreset(preset)).then(json => {
        const newPresets = presets.filter(p => p.periodCode !== json.data.periodCode)

        newPresets.push(json.data)

        setPresets(orderPresetsByStartTimeAndEndTime(newPresets))
        onSuccess(t('sector_shift_preset.modification.success'))
      })
    } else {
      promise = updateShiftPreset(user, new ShiftPreset(preset)).then(json => {
        const newPresets = presets.map(p => {
          if (p.periodCode === json.data.periodCode) {
            return json.data
          }

          return p
        })

        setPresets(orderPresetsByStartTimeAndEndTime(newPresets))
        onSuccess(t('sector_shift_preset.modification.success'))
      })
    }

    errorHandler(promise)
  }

  const handleDelete = (preset, index) => {
    let promise

    if (preset.isDefault) {
      promise = createDeletedSectorShiftPreset(user, sector, new ShiftPreset(preset)).then(json => {
        setPresets(presets.filter(p => p.id !== preset.id))
        onSuccess(t('sector_shift_preset.supression.success'))
      })
    } else {
      promise = deleteShiftPreset(user, new ShiftPreset(preset)).then(json => {
        setPresets(presets.filter(p => p.id !== json.data.id))
        onSuccess(t('sector_shift_preset.supression.success'))
      })
    }

    errorHandler(promise)
  }

  const errorHandler = promise => {
    promise.catch(response => {
      if (response.status === HTTP_BAD_REQUEST) {
        onError(t('sector_shift_preset.error.bad_request'))
      } else if (response.status === HTTP_FORBIDDEN) {
        onError(t('sector_shift_preset.error.forbidden'))
      } else if (response.status === HTTP_CONFLICT) {
        onError(t('sector_shift_preset.error.conflict'))
      } else if (response.status === HTTP_INTERNAL_ERROR) {
        onError(t('sector_shift_preset.error.unknown'))
      }
    })
  }

  return (
    <div className='sector-shift-presets-container'>
      <div className='preset-name'> {sector.name} </div>
      <div className='presets-wrapper'>
        <ul className='presets-codes-list'>
          {presets.map((preset, index) => (
            <li key={`shift_preset_card_${index}`}>
              <ShiftPresetCard
                disabled={disabled}
                usePauses={sector.pausesEnabled}
                preset={preset}
                isValid={preset => !presets.find(p => p.id !== preset.id && p.periodCode === preset.periodCode)}
                onDelete={preset => handleDelete(preset, index)}
                onSave={preset => handleSave(preset, index)}
              />
            </li>
          ))}
          <li>
            {newPreset === null && (
              <IconButton icon={faPlus} text={t('Add preset')} type='default' onClick={handleAddItem} disabled={disabled} />
            )}
            {newPreset !== null && (
              <ShiftPresetCard
                disabled={disabled}
                usePauses={sector.pausesEnabled}
                preset={newPreset}
                onDelete={preset => handleDelete(preset, -1)}
                onSave={preset => handleSave(preset, -1)}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SectorShiftPresetsManager)
