import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import FormItem from './FormItem'
import { Divider, Input, Radio, Select } from 'antd'
import { faAddressBook, faBan, faClock, faComment, faCompass, faIdBadge, faNotesMedical, faPhone, faRecycle, faSave, faUserTie } from '@fortawesome/pro-solid-svg-icons'
import { isValid, VALIDATION_FIELDS } from '../../../utils/validators'
import IconButton from '../../antd/Buttons/IconButton'
import { SCHEDULES, SECTOR_VALIDATORS, TYPES } from '../../../utils/entities/sector'
import { isFunction } from 'lodash'
import { handleFilter } from '../../antd/antdHelper'
import UsersHeap from '../../UsersHeap'
import { ROLE_OBSERVER, ROLE_NURSE } from '../../../utils/constants'
import LabelInputNumber from '../../antd/Inputs/LabelInputNumber'
import StudentPathwaysBuffer from '../StudentPathwaysBuffer'
import { getUser } from '../../../reducers/UserReducer'

const { Option } = Select
const { Group, Button } = Radio

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SectorForm = ({ actTypes = [], cancel, item, t, user, users = [], onCancel, onSubmit }) => {
  const [data, setData] = useState({ ...item, schedule: item.schedule || '', type: item.type || '' })
  const [error, setError] = useState({ missingFields: [] })

  const sectorActTypes = useMemo(() => item.actTypes ? data.actTypes.map(a => a.id) : [], [data.actTypes])
  const observers = useMemo(() => users.filter(u => u.roles.includes(ROLE_OBSERVER)), [users])
  const isNurse = useMemo(() => user.roles.includes(ROLE_NURSE), [user.roles])

  const errorClass = useCallback(error.missingFields.reduce((acc, f) => {
    acc[f] = 'error-input-border'

    return acc
  }, {}), [error])

  useEffect(() => {
    setData({ ...item, schedule: item.schedule || '', type: item.type || '' })
  }, [item, setData])

  const handleSubmit = useCallback(() => {
    const purgeData = { ...data, schedule: data.schedule === '' ? null : data.schedule, type: data.type === '' ? null : data.type }
    purgeData.actTypes = sectorActTypes.map(a => ({ id: a }))

    const errors = isValid(purgeData, SECTOR_VALIDATORS)

    if (errors.keys.length === 0) {
      onSubmit(purgeData)
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [data, error, setError, t])

  const schedules = useMemo(() => [
    <Button key='empty' value=''> / </Button>,
    ...Object.keys(SCHEDULES).map(k => (
      <Button key={k} value={SCHEDULES[k]}> {t(`sector_schedules.${k.toLowerCase()}`)} </Button>
    ))
  ], [t])
  const types = useMemo(() => [
    <Button key='empty' value=''> / </Button>,
    ...Object.keys(TYPES).map(k => (
      <Button key={k} value={TYPES[k]}> {t(`sector_types.${k.toLowerCase()}`)} </Button>
    ))
  ], [t])

  return (
    <div className='flex-col'>
      <div className='flex-row'>
        <div className='flex-col w-50'>
          <FormItem icon={faIdBadge} label='form.label.name' required>
            <Input className={errorClass[VALIDATION_FIELDS.NAME]} value={data.name} disabled={isNurse} onChange={e => setData({ ...data, name: e.target.value })} />
          </FormItem>
          <FormItem icon={faComment} label='form.label.specificity'>
            <Input.TextArea maxLength={500} rows={4} value={data.comment} onChange={e => setData({ ...data, comment: e.target.value })} />
          </FormItem>
          <FormItem icon={faCompass} label='form.label.emplacement'>
            <Input value={data.emplacement} onChange={e => setData({ ...data, emplacement: e.target.value })} />
          </FormItem>
          <FormItem icon={faPhone} label='form.label.phone_number'>
            <Input value={data.phoneNumber} onChange={e => setData({ ...data, phoneNumber: e.target.value })} />
          </FormItem>
          <FormItem icon={faUserTie} label='form.label.service_administrator'>
            <UsersHeap values={data.managers ?? []} users={users} onSelect={managers => setData({ ...data, managers })} />
          </FormItem>
          <FormItem style={{ margin: '0px' }} icon={faUserTie} label='form.label.internship_types'>
            <Select
              filterOption={handleFilter}
              mode='multiple'
              style={{ width: '100%' }}
              value={sectorActTypes}
              onChange={values => setData({ ...data, actTypes: values.map(v => ({ id: v })) })}
            >
              {actTypes.map(a => (<Option key={a.id} value={a.id} title={a.name} filter={a.acronym}> {a.acronym} </Option>))}
            </Select>
          </FormItem>
        </div>
        <Divider style={{ height: 'auto' }} type='vertical' />
        <div className='flex-col w-50'>
          <FormItem icon={faAddressBook} label='form.label.service_tutors'>
            <UsersHeap values={data.tutors ?? []} users={observers} onSelect={tutors => setData({ ...data, tutors })} />
          </FormItem>
          <FormItem icon={faClock} label='form.label.schedule_type'>
            <Group value={data.schedule} onChange={e => setData({ ...data, schedule: data.schedule === e.target.value ? null : e.target.value })} buttonStyle='solid'> {schedules} </Group>
          </FormItem>
          <FormItem icon={faNotesMedical} label='form.label.type'>
            <Group value={data.type} onChange={e => setData({ ...data, type: data.type === e.target.value ? null : e.target.value })} buttonStyle='solid'> {types} </Group>
          </FormItem>
          <FormItem style={{ margin: '0px' }} icon={faRecycle} label='form.label.composition'>
            <div className='flex-row jtf-ctt-sbw'>
              <LabelInputNumber
                className='mr-4'
                label={t('composition.beds')}
                value={data.composition?.beds ?? 0}
                onChange={v => setData({ ...data, composition: { ...data.composition, beds: v } })}
              />
              <LabelInputNumber
                className='mr-4'
                label={t('composition.rooms')}
                value={data.composition?.rooms ?? 0}
                onChange={v => setData({ ...data, composition: { ...data.composition, rooms: v } })}
              />
              <LabelInputNumber
                label={t('composition.boxes')}
                value={data.composition?.boxes ?? 0}
                onChange={v => setData({ ...data, composition: { ...data.composition, boxes: v } })}
              />
            </div>
          </FormItem>
        </div>
      </div>
      <Divider style={{ width: 'auto', margin: '12px 0px 12px 0px' }} type='horizontal' />
      <div className='w-100'>
        <StudentPathwaysBuffer defaultValues={data.studentPathways ?? []} onChange={p => setData({ ...data, studentPathways: p })} />
      </div>
      <Divider style={{ width: 'auto', margin: '12px 0px 0px 0px' }} type='horizontal' />
      <div className='flex-row mt-12'>
        {cancel && isFunction(onCancel) && <IconButton className='w-50' icon={faBan} onClick={onCancel} text={t('actions.cancel')} />}
        <IconButton className={cancel && isFunction(onCancel) ? 'w-50' : 'w-100'} type='primary' icon={faSave} onClick={handleSubmit} text={t('actions.save')} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SectorForm)
