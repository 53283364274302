import React, { useContext, useEffect, useState, useRef, useMemo } from 'react'

import { useDispatch } from 'react-redux'
import { internshipStates, DATE_FORMAT_API, MODES, ROLE_VALIDATOR, BACKEND_DATE_FORMAT, DATE_WITHOUT_TIME } from '../../../utils/constants'
import { MULTI_SELECT_MODE } from './constants'
import TableFooter from './ShiftTableParts/TableFooter'
import ShiftsManagerHeader from './ShiftsManagerHeader/ShiftsManagerHeader'
import InternshipValidation from './InternshipValidation'
import StudentInfo from '../StudentInfo'
import InternshipInfo from '../InternshipInfo'
import InternshipsShiftsEditor from './InternshipsShiftsEditor'
import SelectionCustomTimes from './SelectionCustomTimes'
import { buildBooleanMap, checkFullOverlap, getIndexKey, getIsoTime, getSectorPresets, makeUrlBody } from './helper'
import { isObject } from '../../../utils'
import moment from 'moment'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import rolesTools from '../../../utils/roles'
import { synchroniseStoreShiftsByShifts } from '../../../reducers/ShiftsReducer/ShiftsReducer'
import DesiderataValidationModal from './DesiderataValidationModal'
import ExceedingQuotaModal from './ExceedingQuotaModal'
import { deleteInternshipShifts, deleteInternshipsShifts, getInternshipsByPeriod, updateInternshipDraftProperty, updateInternshipsDraftProperty } from '../../../utils/api/internship'
import { onSuccess } from '../../../utils/apiHelper'
import { Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { SCHEDULE_VALIDATED } from '../../../utils/entities/internship'
import { InternshipMessageContext } from '../../../Providers/InternshipMessageProvider'

import '../../../assets/shifts-manager.scss'

const { SINGLE, COLUMN, ROW } = MULTI_SELECT_MODE
const INTERNSHIPS_PER_PAGE = 10
const TIME_BEFORE_SEARCH = 300
const { INTERNEO, WIDGET } = MODES

const ShiftsManager = props => {
  const internshipsMessages = useContext(InternshipMessageContext)

  const [checkedInternships, setCheckedInternships] = useState([])
  const [warningState, setWarningState] = useState({ display: false, internship: null })
  const [internships, setInternships] = useState([])
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filter, setFilter] = useState(null)
  const [displayDraft, setDisplayDraft] = useState(false)
  const [internshipToValidate, setInternshipToValidate] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedInternship, setSelectedInternship] = useState(null)
  const [selectedDesiderata, setSelectedDesiderata] = useState(null)
  const [selectedShifts, setSelectedShifts] = useState({})
  const [multiSelectionMode, setMultiSelectionMode] = useState(false)
  const [multiSelectionPresets, setMultiSelectionPresets] = useState([])
  const [multiSelectionInternships, setMultiSelectionInternships] = useState({})
  const [selectedColumns, setSelectedColumns] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [metadata, setMetadata] = useState(new Metadata({}))

  const allChecked = useMemo(() => checkFullOverlap(internships.filter(i => i.state !== SCHEDULE_VALIDATED), checkedInternships), [internships, checkedInternships])
  const checkedInternshipsMap = useMemo(() => buildBooleanMap(checkedInternships), [checkedInternships])
  const isNurse = useMemo(() => rolesTools.isNurse(props.getUser), [props.getUser])
  const isObserver = useMemo(() => rolesTools.isObserver(props.getUser), [props.getUser])

  const differateSearchIdRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    setStartDate(moment().startOf('isoWeek'))
    setEndDate(moment().add(3, 'week').endOf('isoWeek'))
  }, [])

  useEffect(() => {
    if (internships.length > 0) {
      downloadMissingPresets(internships)
      downloadInternshipsShifts(internships)
    }
  }, [internships])

  useEffect(() => {
    if (props.start) {
      setStartDate(moment(props.start))
    }
  }, [props.start])

  useEffect(() => {
    if (props.end) {
      setEndDate(moment(props.end))
    }
  }, [props.end])

  useEffect(() => {
    if (startDate && endDate && metadata.page) {
      downloadInternshipsByDatesAndFilter(startDate, endDate, metadata.page, '', props.getSelectedSector, displayDraft, props.getSelectedInstitution)
    }
  }, [startDate, endDate, metadata.page, props.getSelectedSector, displayDraft, props.getSelectedInstitution])

  useEffect(() => {
    if (typeof filter === 'string') {
      differateSearch(filter, props.getSelectedSector)
    }
  }, [filter, props.getSelectedSector])

  useEffect(() => {
    return () => {
      props.setSelectedSector({})
    }
  }, [props.setSelectedSector])

  useEffect(() => {
    if (props.defaultFilter) {
      setFilter(props.defaultFilter)
    }
  }, [props.defaultFilter])

  useEffect(() => {
    if (
      isObject(props.getSelectedSector) &&
      props.getSelectedSector.id > -1 &&
      props.getUser &&
      startDate &&
      endDate &&
      props.fetchPauseQuotasCountsPerSector
    ) {
      const urlParameters = {
        'by-period': true,
        'start-date': startDate.format(DATE_WITHOUT_TIME),
        'end-date': endDate.format(DATE_WITHOUT_TIME),
        interval: '1 day'
      }

      props.fetchPauseQuotasCountsPerSector(props.getSelectedSector.id, urlParameters, props.getUser)
      props.fetchShiftsCountsPerSector(props.getSelectedSector.id, urlParameters, props.getUser)
    }
  }, [
    props.getSelectedSector,
    props.fetchPauseQuotasCountsPerSector,
    startDate,
    endDate,
    props.getUser,
    props.setPauseQuotasLoading,
    props.fetchShiftsCountsPerSector
  ])

  const downloadInternshipsShifts = internships => {
    const internshipsId = internships.map(internship => {
      return internship.id
    })

    props.fetchMultipleInternshipShifts(internshipsId, props.getUser)
  }

  const differateSearch = (newFilter, sector) => {
    if (typeof differateSearchIdRef.current === 'number') {
      clearTimeout(differateSearchIdRef.current)
    }

    differateSearchIdRef.current = setTimeout(() => {
      if (startDate && endDate && metadata.page) {
        downloadInternshipsByDatesAndFilter(startDate, endDate, metadata.page, newFilter, sector, displayDraft, props.getSelectedInstitution)
      }
    }, TIME_BEFORE_SEARCH)
  }

  const downloadInternshipsByDatesAndFilter = (startDate, endDate, page, newFilter, sector, displayDraft = false, institution = null) => {
    setLoading(true)

    const currentFilter = getFilter(newFilter)
    const startDateRequest = startDate.format(DATE_FORMAT_API)
    const endDateRequest = endDate.format(DATE_FORMAT_API)
    const urlParameters = {
      'start-date': startDateRequest,
      'end-date': endDateRequest,
      page,
      'per-page': props.studentsPerPage ?? INTERNSHIPS_PER_PAGE,
      filter: currentFilter,
      'is-draft': displayDraft
    }

    if (sector && sector.id > 0) {
      urlParameters.sector = sector.id
    }

    if (institution && institution.id > 0) {
      urlParameters.institution = institution.id
    }

    if (props.inWidgetMode) {
      addWidgetParametersToUrl(urlParameters)
    }

    getInternshipsByPeriod(props.getUser, urlParameters).then(json => {
      if (json?.data) {
        setInternships(json.data.map(i => {
          i.startDate = i.startDate.split('+')[0]
          i.endDate = i.endDate.split('+')[0]

          return i
        }))

        if (internshipsMessages) {
          internshipsMessages.setTrackedInternships(json.data.map(i => i.id))
        }
      }

      if (json?.meta) {
        setMetadata(retrieveMetadata(json.meta))
      }

      setLoading(false)
    })
  }

  const getFilter = newFilter => {
    if (newFilter !== '' && newFilter !== null) {
      return newFilter
    }

    if (filter !== null) {
      return filter
    }

    return ''
  }

  const addWidgetParametersToUrl = urlParameters => {
    if (props.institution) {
      urlParameters.institution = props.institution
    }

    if (props.sector) {
      urlParameters.sector = props.sector
    }
  }

  const downloadMissingPresets = internships => {
    const missingInstitutionsIds = {}

    internships.forEach(internship => {
      const institutionId = internship.institution?.id ?? null

      if (
        institutionId !== null &&
        !props.getShiftPresets[institutionId] &&
        !missingInstitutionsIds[institutionId]
      ) {
        missingInstitutionsIds[institutionId] = true
      }
    })

    const institutionsIds = Object.keys(missingInstitutionsIds)

    if (institutionsIds.length > 0) {
      props.fetchInstitutionShiftsPresets(institutionsIds, props.getUser, true)
    }
  }

  const handleInternshipValidation = () => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        internship.state = internshipStates.SCHEDULE_VALIDATED
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: internshipStates.SCHEDULE_VALIDATED })
  }

  const handleInternshipInvalidation = newState => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        internship.state = newState
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: newState })
  }

  const handleFilterChange = filterValue => {
    if (filter === filterValue) {
      return
    }

    if (metadata.page !== 1) {
      setMetadata(new Metadata({ ...metadata, page: 1 }))
    }

    setFilter(filterValue)
  }

  const handleShiftsWidgetCoverClick = (e) => {
    e.preventDefault()

    if (typeof props.onWidgetCoverClick === 'function') {
      props.onWidgetCoverClick()
    }
  }

  const multiShiftsAddingHandler = (encapsulatedShifts, mode = SINGLE) => {
    if (encapsulatedShifts.length === 0) {
      return
    }

    const firstShift = encapsulatedShifts[0]

    if (mode === SINGLE) {
      const isAlreadySelected = !!selectedShifts[getIndexKey(firstShift.rowIndex, firstShift.columnIndex)]

      isAlreadySelected
        ? removeMultipleShiftsFromMultiSelection(encapsulatedShifts)
        : addMultipleShiftsToMultiSelection(encapsulatedShifts)

      return
    }

    if (haveToBeRemovePerMode(mode, encapsulatedShifts[0])) {
      removeMultipleShiftsFromMultiSelection(encapsulatedShifts)
      removeIndexFromState(firstShift, mode)

      return
    }

    addMultipleShiftsToMultiSelection(encapsulatedShifts)
    addIndexToState(firstShift, mode)
  }

  const haveToBeRemovePerMode = (mode, encapsulatedShift) => {
    if (mode === COLUMN) {
      return selectedColumns.includes(encapsulatedShift.columnIndex)
    }

    if (mode === ROW) {
      return selectedRows.includes(encapsulatedShift.rowIndex)
    }

    return false
  }

  const addIndexToState = (encapsulatedShift, mode) => {
    if (mode === COLUMN) {
      setSelectedColumns([...selectedColumns, encapsulatedShift.columnIndex])

      return
    }

    if (mode === ROW) {
      setSelectedRows([...selectedRows, encapsulatedShift.rowIndex])
    }
  }

  const removeIndexFromState = (encapsulatedShift, mode) => {
    if (mode === COLUMN) {
      setSelectedColumns(selectedColumns.filter(columnIndex => {
        return columnIndex !== encapsulatedShift.columnIndex
      }))

      return
    }

    if (mode === ROW) {
      setSelectedRows(selectedRows.filter(rowIndex => {
        return rowIndex !== encapsulatedShift.rowIndex
      }))
    }
  }

  const addMultipleShiftsToMultiSelection = encapsulatedShifts => {
    const multiSelectionShifts = { ...selectedShifts }

    encapsulatedShifts.forEach(encapsulation => {
      const { rowIndex, columnIndex, shift } = encapsulation
      const indexKey = getIndexKey(rowIndex, columnIndex)

      if (!multiSelectionShifts[indexKey]) {
        multiSelectionShifts[indexKey] = shift
      }
    })

    manageMultipleInternshipsAndPresetsAdding(encapsulatedShifts)
    setSelectedShifts(multiSelectionShifts)
  }

  const manageMultipleInternshipsAndPresetsAdding = encapsulatedShifts => {
    const newMultiSelectionInternships = { ...multiSelectionInternships }

    encapsulatedShifts.forEach(encapsulation => {
      const { internship } = encapsulation

      if (!multiSelectionInternships[internship.id]) {
        newMultiSelectionInternships[internship.id] = true
      }
    })

    handleMultiSelectionPresets(newMultiSelectionInternships)
    setMultiSelectionInternships(newMultiSelectionInternships)
  }

  const handleMultiSelectionPresets = (currentInternships) => {
    const internshipsKeys = Object.keys(currentInternships)

    if (internshipsKeys.length === 1) {
      const internshipToGetPreset = internships.find(i => i.id === parseInt(internshipsKeys[0]))

      setMultiSelectionPresets(getSectorPresets(
        internshipToGetPreset.institution?.id ?? -1,
        internshipToGetPreset.sector?.id ?? -1,
        props.getShiftPresets)
      )
    } else {
      setMultiSelectionPresets([])
    }
  }

  const removeMultipleShiftsFromMultiSelection = encapsulatedShifts => {
    const multiSelectionShifts = { ...selectedShifts }

    encapsulatedShifts.forEach(encapsulation => {
      const { rowIndex, columnIndex } = encapsulation
      const indexKey = getIndexKey(rowIndex, columnIndex)

      if (multiSelectionShifts[indexKey]) {
        delete multiSelectionShifts[indexKey]
      }
    })

    manageMultipleInternshipsAndPresetsRemoving(encapsulatedShifts, multiSelectionShifts)
    setSelectedShifts(multiSelectionShifts)
  }

  const manageMultipleInternshipsAndPresetsRemoving = (encapsulatedShifts, currentMultiSelectionShifts) => {
    const newMultiSelectionInternships = { ...multiSelectionInternships }

    encapsulatedShifts.forEach(encapsulation => {
      const { internship } = encapsulation
      const relatedShiftsKeys = Object.keys(currentMultiSelectionShifts)
        .filter(shiftKey => currentMultiSelectionShifts[shiftKey].internship === internship.id)

      if (relatedShiftsKeys.length === 0) {
        delete newMultiSelectionInternships[internship.id]
      }
    })

    setMultiSelectionPresetsIfOneRemain(newMultiSelectionInternships)
    setMultiSelectionInternships(newMultiSelectionInternships)
  }

  const setMultiSelectionPresetsIfOneRemain = remainingInternships => {
    const internshipsKeys = Object.keys(remainingInternships)

    if (internshipsKeys.length === 1) {
      const internshipToGetPreset = internships.find(i => i.id === parseInt(internshipsKeys[0]))

      setMultiSelectionPresets(getSectorPresets(
        internshipToGetPreset.institution?.id ?? -1,
        internshipToGetPreset.sector?.id ?? -1,
        props.getShiftPresets)
      )
    }
  }

  const handleSelectedShiftsUpdate = ({ startTime, endTime, periodCode, eventCode, pause }) => {
    synchroniseStoreWithSelectedShiftsModification(startTime, endTime, periodCode, eventCode, pause)

    saveSelectedShiftsIntoBackend(startTime, endTime, periodCode, eventCode, pause)

    resetStatesRelatedToMultiSelection()
  }

  const synchroniseStoreWithSelectedShiftsModification = (startTime, endTime, periodCode, eventCode, pause) => {
    const updatedShifts = []

    Object.keys(selectedShifts).forEach(key => {
      const currentShift = selectedShifts[key]

      updatedShifts.push({
        ...currentShift,
        startDate: currentShift.startDate.format(BACKEND_DATE_FORMAT),
        endDate: currentShift.endDate.format(BACKEND_DATE_FORMAT),
        startTime: startTime,
        endTime: endTime,
        periodCode: periodCode,
        eventCode: eventCode,
        pause: pause
      })
    })

    dispatch(synchroniseStoreShiftsByShifts(updatedShifts))
  }

  const saveSelectedShiftsIntoBackend = (startTime, endTime, periodCode, eventCode, pause) => {
    Object.keys(selectedShifts).forEach(key => {
      const shift = selectedShifts[key]
      const isoStartTime = getIsoTime(startTime)
      const isoEndTime = getIsoTime(endTime)

      const urlBody = makeUrlBody(
        periodCode,
        shift.internship,
        shift,
        eventCode,
        isoStartTime,
        isoEndTime
      )

      if (shift.id === -1) {
        urlBody.startDate = shift.startDate.format(DATE_FORMAT_API)
        props.addShiftFromPreset(urlBody, props.getUser)

        return
      }

      props.updateShiftFromPreset(urlBody, props.getUser)
    })
  }

  const handleSelectedShiftsRemove = () => {
    Object.keys(selectedShifts).forEach(key => {
      const shift = selectedShifts[key]

      if (shift.id > 0) {
        props.removeShift(shift, shift.internship, props.getUser)
      }
    })

    resetStatesRelatedToMultiSelection()
  }

  const displayManagePresets = () => {
    props.displayManagePresets()
  }

  const toggleMultiSelect = () => {
    setMultiSelectionMode(!multiSelectionMode)
    resetStatesRelatedToMultiSelection()
  }

  const resetStatesRelatedToMultiSelection = () => {
    setSelectedShifts({})
    setMultiSelectionInternships({})
    setMultiSelectionPresets([])
    setSelectedColumns([])
    setSelectedRows([])
  }

  const handleDateChange = (date, dateString) => {
    const newStartDate = date.clone().startOf('isoWeek')
    const newEndDate = newStartDate.clone().add(3, 'weeks').endOf('isoWeek')

    setMetadata(new Metadata({ ...metadata, page: 1 }))
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleModalOk = () => {
    props.acceptDesiderataByUser(selectedDesiderata, props.getUser)
    setSelectedDesiderata(null)
  }

  const handleModalDeny = () => {
    props.removeShift(selectedDesiderata, selectedDesiderata.internship, props.getUser)
    setSelectedDesiderata(null)
  }

  const handleModalCancel = () => {
    setSelectedDesiderata(null)
  }

  const handleDraftStateChanged = (internship, isDraft) => {
    updateInternshipDraftProperty(props.getUser, internship, isDraft).then(json => {
      if (json?.data) {
        downloadInternshipsByDatesAndFilter(startDate, endDate, metadata.page, filter, props.getSelectedSector, displayDraft, props.getSelectedInstitution)
        onSuccess(props.t(isDraft ? 'shifts_manager.draft_internship_schedule' : 'shifts_manager.publish_internship_schedule'))
      }
    })
  }

  const handleDraftSchedules = draft => {
    updateInternshipsDraftProperty(props.getUser, checkedInternships, draft).then(json => {
      if (json?.data) {
        setCheckedInternships([])
        setMetadata(new Metadata({ ...metadata, page: 1 }))
        downloadInternshipsByDatesAndFilter(startDate, endDate, metadata.page, filter, props.getSelectedSector, displayDraft, props.getSelectedInstitution)
        onSuccess(props.t(draft ? 'shifts_manager.draft_internships_schedules' : 'shifts_manager.publish_internships_schedules'))
      }
    })
  }

  const handleDeleteSchedule = internship => {
    deleteInternshipShifts(props.getUser, internship).then(json => {
      if (json?.data) {
        props.removeInternshipsShifts([internship.id])
        onSuccess(props.t('shifts_manager.delete_internship_schedule'))
      }
    })
  }

  const handleDeleteSchedules = () => {
    deleteInternshipsShifts(props.getUser, checkedInternships).then(json => {
      if (json?.data) {
        setCheckedInternships([])
        props.removeInternshipsShifts(checkedInternships)
        onSuccess(props.t('shifts_manager.delete_internships_schedules'))
      }
    })
  }

  const renderInternshipsShiftsEditor = () => {
    if (internships.length > 0) {
      return (
        <InternshipsShiftsEditor
          allChecked={allChecked}
          checkedNumber={checkedInternships.length}
          checkedInternships={checkedInternshipsMap}
          internships={internships}
          userIsNurse={isNurse}
          isReadOnly={props.isReadOnly}
          startDate={startDate}
          endDate={endDate}
          multiSelectionMode={multiSelectionMode}
          selectedShifts={selectedShifts}
          limitedActions={props.limitedActions}
          inDraftMode={displayDraft}
          inWidgetMode={props.inWidgetMode}
          setIntershipToValidate={setInternshipToValidate}
          onCheckAll={() => {
            const ids = internships.filter(i => i.state !== SCHEDULE_VALIDATED).map(i => i.id)

            if (allChecked) {
              setCheckedInternships(checkedInternships.filter(id => !ids.includes(id)))
            } else {
              const newCheckedInternships = [...checkedInternships]

              ids.forEach(id => {
                if (!checkedInternships.includes(id)) {
                  newCheckedInternships.push(id)
                }
              })

              setCheckedInternships(newCheckedInternships)
            }
          }}
          onClickedDraft={handleDraftStateChanged}
          onDeleteSchedule={internship => setWarningState({ display: true, internship })}
          onStudentSelected={setSelectedStudent}
          onInternshipChecked={internship => setCheckedInternships(!checkedInternshipsMap[internship.id]
            ? [...checkedInternships, internship.id]
            : checkedInternships.filter(i => i !== internship.id)
          )}
          onInternshipSelected={setSelectedInternship}
          multiSelectionAddingHandler={multiShiftsAddingHandler}
          onShiftsWidgetCoverClick={handleShiftsWidgetCoverClick}
          onDesiderataSelect={shift => {
            if (!isObserver) {
              setSelectedDesiderata(shift)
            }
          }}
          loading={loading || props.getPauseQuotasState || props.getShiftsState || props.getShiftPresetsLoading}
        />
      )
    }

    return (
      <div className={'flex-row ' + (multiSelectionMode ? 'empty-shifts-table-multi-select-mode' : 'empty-shifts-table')}>
        <b>
          {props.t('There is no accepted internship this month.')}
        </b>
      </div>
    )
  }

  const renderContainerFooter = () => {
    return (
      <TableFooter
        entityName='Internship'
        loading={loading}
        entitiesTotal={metadata.total}
        entitiesPerPage={metadata.perPage}
        pageIndex={metadata.page}
        maxPageIndex={metadata.totalPages}
        inWidgetMode={props.inWidgetMode}
        mode={props.inWidgetMode ? WIDGET : INTERNEO}
        onPageIndexChange={page => setMetadata(new Metadata({ ...metadata, page }))}
      />
    )
  }

  return (
    <div className='shifts-manager'>
      <div className={props.inWidgetMode ? 'header-and-body-in-widget' : 'table-and-header'}>
        <div>
          <ShiftsManagerHeader
            checkedInternships={checkedInternships}
            limitedActions={props.limitedActions || props.getUser.roles.includes(ROLE_VALIDATOR)}
            multiSelectionMode={multiSelectionMode}
            showTopTransitions={false}
            exportLoading={false}
            startDate={startDate}
            endDate={endDate}
            useDraft={displayDraft}
            inWidgetMode={props.inWidgetMode}
            isReadOnly={props.isReadOnly}
            onFilterChange={handleFilterChange}
            displayManagePresets={displayManagePresets}
            toggleMultiSelect={toggleMultiSelect}
            onDateChange={handleDateChange}
            onDraftChange={draft => {
              setDisplayDraft(draft)
              setCheckedInternships([])
              setMetadata(new Metadata({ ...metadata, page: 1 }))
            }}
            onDraftSchedules={() => handleDraftSchedules(true)}
            onDeleteSchedules={() => setWarningState({ ...warningState, display: true })}
            onPublishSchedules={() => handleDraftSchedules(false)}
            onResetSelection={() => setCheckedInternships([])}
          />
          {(multiSelectionMode && !props.inWidgetMode) ? (
            <SelectionCustomTimes
              t={props.t}
              availablePresets={multiSelectionPresets}
              onRemove={handleSelectedShiftsRemove}
              onSave={handleSelectedShiftsUpdate}
              hasShiftsSelected={Object.keys(selectedShifts).length > 0}
            />
          ) : (
            <>
              <div className='v-spacing' />
              <div className='v-spacing' />
            </>
          )}
        </div>
        {renderInternshipsShiftsEditor()}
      </div>
      {renderContainerFooter()}
      {internshipToValidate &&
        <InternshipValidation
          internship={internshipToValidate}
          onClose={() => setInternshipToValidate(null)}
          onValidate={handleInternshipValidation}
          onInvalidate={handleInternshipInvalidation}
        />}
      {selectedStudent &&
        <StudentInfo
          student={selectedStudent}
          onClose={() => setSelectedStudent(null)}
        />}
      {selectedInternship &&
        <InternshipInfo
          internship={selectedInternship}
          onClose={() => setSelectedInternship(null)}
        />}
      {selectedDesiderata &&
        <DesiderataValidationModal
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          onDeny={handleModalDeny}
          shift={selectedDesiderata}
          visible={!!selectedDesiderata}
        />}
      <ExceedingQuotaModal />
      <Modal
        visible={warningState.display}
        title={props.t('shifts_manager.warning_modal_title')}
        onOk={() => {
          warningState.internship ? handleDeleteSchedule(warningState.internship) : handleDeleteSchedules()
          setWarningState({ internship: null, display: false })
        }}
        onCancel={() => setWarningState({ ...warningState, display: false })}
        okText={props.t('Ok')}
        cancelText={props.t('Cancel')}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red' }} /> &nbsp;
        {props.t('shift_manager.warning_modal_body')}
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsManager)
