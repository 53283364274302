import React from 'react'

import Italic from './Italic'
import IconLabel from '../icons/IconLabel'
import { faEnvelope, faPhone, faUser } from '@fortawesome/pro-solid-svg-icons'

const UserContactInfo = ({ user }) => {
  const fullName = user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : null

  return (
    <div>
      {fullName !== null && <IconLabel icon={faUser} text={<Italic text={fullName} />} />}
      <IconLabel fontSize='small' icon={faEnvelope} text={user.email} />
      {user.phoneNumber && <IconLabel icon={faPhone} text={user.phoneNumber} />}
    </div>
  )
}

export default UserContactInfo
