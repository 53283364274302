import React, { useMemo } from 'react'

import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { ActType, getCategory, getName, getType } from '../../../../utils/entities/actType'
import { Tag } from 'antd'
import { getEmptyLabelOrValue } from '../../../../utils'
import { ORDER_BY } from '../../../../utils/constants'
import Cookies from 'js-cookie'
import { ACTIONS, Action, DeleteAction, ExternalAction } from '../../../../Components/shared/DataTable/utils/actions'
import { faEdit, faGraduationCap } from '@fortawesome/pro-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const ActTypesTable = ({ data, loading, metadata, parameters, school, user, onParametersChange, onTriggerAction, t }) => {
  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '230px' : '189px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => [
    { title: t('acts_table.column_title.name'), orderBy: ORDER_BY.NAME, key: getName },
    { title: t('acts_table.column_title.category'), orderBy: ORDER_BY.CATEGORY, key: a => a.school ? getEmptyLabelOrValue(getCategory(a), ORDER_BY.CATEGORY, t) : t('act_types.label.global') },
    { title: t('acts_table.column_title.type'), orderBy: ORDER_BY.ACT_TYPE, key: a => <Tag color={getType(a) ? 'blue' : 'cyan'}> {t(getType(a) ? 'common.schedule' : 'Numerical')} </Tag> }
  ], [t])
  const actions = useMemo(() => [
    new Action({ title: 'acts_table.actions.edit', icon: faEdit, onClick: s => onTriggerAction(s, ACTIONS.EDIT_ACT_TYPE), disabled: d => !d.school }),
    new Action({ title: 'acts_table.actions.display_act_type_sections', icon: faGraduationCap, onClick: s => onTriggerAction(s, ACTIONS.DISPLAY_ACT_TYPE_SECTIONS) }),
    new DeleteAction({ title: 'acts_table.actions.delete', onDelete: s => onTriggerAction(s, ACTIONS.DELETE), disabled: d => !d.school })
  ], [onTriggerAction])
  const externalActions = useMemo(() => [
    new ExternalAction({
      key: 'categories',
      title: t('act_types_table.external_actions.display_act_categories'),
      onClick: () => onTriggerAction(null, ACTIONS.DISPLAY_ACT_CATEGORIES)
    })
  ], [t, onTriggerAction])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        externalActions={externalActions}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => onTriggerAction(new ActType({ school }), ACTIONS.CREATE_ACT_TYPE)}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(ActTypesTable)
