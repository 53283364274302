import React from 'react'

import { faMeh, faSmile, faFrown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { mapStateToProps, connect } from '../../reducers/Dispatchers'

import '../../assets/satisfaction-ratio.scss'

const SatisfactionRatio = ({ ratio, forceNegative, customTooltip, count, style = {}, t, data, current }) => {
  const noValueTooltip = customTooltip || t('Not evaluated yet')

  let ratioClass = ''
  let forceNoValue = false

  if (
    ratio >= 0 && ratio <= 39 &&
    (
      (current === 'studentEvaluationSatisfactionRatio' && data.didInstitutionEvaluate) ||
      (current === 'institutionEvaluationSatisfactionRatio' && data.didStudentEvaluate) ||
      (current === 'matchScore' && data.didInstitutionEvaluate && data.didStudentEvaluate) ||
      forceNegative === true
    )
  ) {
    ratioClass = 'negative'
    forceNegative = true
  }

  if (ratio >= 40 && ratio < 79) {
    ratioClass = 'medium'
  } else if (ratio >= 80) {
    ratioClass = 'positive'
  }

  if (current === 'matchScore' && (!data.didInstitutionEvaluate || !data.didStudentEvaluate)) {
    ratioClass = ''
    forceNoValue = true
  }

  return (
    <div className='satisfaction-ratio' style={style}>
      <Tooltip
        placement='top' className='tooltip-wrapper'
        title={(ratio > 0 && !forceNoValue) || forceNegative ? `${ratio} %` : noValueTooltip}
      >
        <span className={ratioClass === 'negative' ? 'negative-highlighted' : ''}>
          <FontAwesomeIcon icon={faFrown} />
        </span>
        <span className={ratioClass === 'medium' ? 'medium-highlighted' : ''}>
          <FontAwesomeIcon icon={faMeh} />
        </span>
        <span className={ratioClass === 'positive' ? 'positive-highlighted' : ''}>
          <FontAwesomeIcon icon={faSmile} />
        </span>
        {count !== null && <span className='count'>{count}</span>}
      </Tooltip>
    </div>
  )
}

export default connect(mapStateToProps)(SatisfactionRatio)
